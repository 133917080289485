import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import { useFocus } from '@kmx/legos-react-utilities';
import styles from './TextArea.module.scss';

export default function TextArea(props) {
    const {
        autoCapitalize = 'none',
        autoComplete,
        className,
        columns = 20,
        disabled = false,
        helperText,
        helperTextIsPersistent = false,
        id: initialId,
        inputProps,
        label,
        maxLength,
        name,
        required = false,
        rows = 3,
        value,
        onBlur,
        onChange,
        onFocus,
        validationStatus
    } = props;

    const [id] = React.useState(initialId || nanoid(8));
    const textAreaId = `text-area-${id}`;
    const helperTextId = `text-area-helper-text-${id}`;

    const textareaRef = React.useRef(null);
    const isFocused = useFocus(textareaRef);

    return (
        <div className={styles.container}>
            <div
                className={classNames(className, 'kmx-text-area', 'mdc-text-field', 'mdc-text-field--textarea', {
                    'mdc-text-field--disabled': disabled,
                    'mdc-text-field--focused': isFocused,
                    'mdc-text-field--invalid': validationStatus === 'invalid',
                })}
            >
                <textarea
                    autoComplete={autoComplete}
                    aria-controls={helperText ? helperTextId : undefined}
                    aria-describedby={helperText ? helperTextId : undefined}
                    autoCapitalize={autoCapitalize}
                    className="kmx-text-area__input mdc-text-field__input"
                    cols={columns}
                    disabled={disabled}
                    id={textAreaId}
                    maxLength={maxLength}
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    onFocus={onFocus}
                    ref={textareaRef}
                    required={required}
                    rows={rows}
                    value={value}
                    {...inputProps}
                />
                <label
                    className={classNames('mdc-floating-label', {
                        'mdc-floating-label--float-above': value || isFocused,
                        [styles.labelError]: validationStatus === 'invalid',
                    })}
                    htmlFor={textAreaId}
                >
                    {label}
                </label>
            </div>
            {maxLength ? (
                <div className="kmx-form-component-helper-line">
                    <div className="kmx-text-area-counter__text">&nbsp;</div>
                    <div className="kmx-text-area-counter__count kmx-text-area-helper-text">
                        {`${value.length}/${maxLength}`}
                    </div>
                </div>
            ) : helperText ? (
                <div
                    className={classNames('mdc-text-field-helper-text', {
                        'kmx-text-area-helper-text--persistent': helperTextIsPersistent,
                        'mdc-text-field-helper-text--persistent': helperTextIsPersistent,
                        'mdc-text-field-helper-text--validation-msg': validationStatus === 'invalid',
                    })}
                    id={helperTextId}
                >
                    {helperText}
                </div>
            ) : null}
        </div>
    );
}

TextArea.propTypes = {
    /**
     * Controls whether and how the text value should be automatically capitalized as it is entered/edited by the user
     *
     */
    autoCapitalize: PropTypes.string,

    autoComplete: PropTypes.string,

    /**
     * Class(es) to add to the form field container
     */
    className: PropTypes.string,

    /**
     * The visible width of the `<textarea>`, in average character widths.
     * If it is specified, it must be a positive integer.
     *
     */
    columns: PropTypes.number,

    /**
     * Boolean attribute indicating if the `<textarea>` should be disabled
     *
     */
    disabled: PropTypes.bool,

    /**
     * Phrase to render as `<textarea>` control "help" message
     */
    helperText: PropTypes.string,

    /**
     * Always show "help" message, even when `<textarea>` is not focused
     *
     */
    helperTextIsPersistent: PropTypes.bool,

    /**
     * ID used for the `<textarea>` and other accessibility concerns
     */
    id: PropTypes.string,

    /**
     * Attributes to pass to the `<textarea>` element
     */
    inputProps: PropTypes.object,

    /**
     * Caption for the `<textarea>`
     */
    label: PropTypes.string.isRequired,

    /**
     * The maximum number of characters that the user can enter in the `<textarea>`
     */
    maxLength: PropTypes.number,

    /**
     * Name of the `<textarea>`
     */
    name: PropTypes.string,

    /**
     * Boolean attribute indicating the `<textarea>` is required
     *
     */
    required: PropTypes.bool,

    /**
     * The number of visible text lines for the `<textarea>`
     *
     */
    rows: PropTypes.number,

    /**
     * Value of the `<textarea>`
     */
    value: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    validationStatus: PropTypes.string
};
