import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from  './VehicleDriveability.module.scss';
import { logFsEvent, buildEventName } from '../../utils/fullstory';
import { fullstoryConstants } from '../../constants/fullstory';
import { RadioButton } from '@kmx/legos-react-radio-button';
import { OfferContext } from '../../context';

export default function VehicleDriveability(props) {
    const {
        label, dataSource,
        driveability, setDriveability,
        setIsValid
    } = props;

    const { setOffer } = useContext(OfferContext);

    useEffect(() => {
        setOffer(prevState => ({...prevState, isVehicleSafeToDrive: driveability}));

        if (driveability != '' && driveability != null && driveability.length != 0)
            setIsValid(true);
        else
            setIsValid(false);

    }, [driveability]);

    const onFocus = () => {
        logFsEvent(buildEventName(fullstoryConstants.vehiclePage.name,
            fullstoryConstants.vehiclePage.events.driveabilityChoice));
    };

    return (
        <fieldset className={"kmx-flex-wrapper"}>
            <legend className={styles.label} id='vehicle-driveability-label'>{label}</legend>
            {dataSource.map((option, index) =>
                <RadioButton
                    key={`driveability-${index}`}
                    id={`driveability-${index}`}
                    checked={driveability === option?.value}
                    label={option?.label}
                    name='vehicle-driveability'
                    onChange={event => setDriveability(event.target.value)}
                    onFocus={() => onFocus()}
                    value={option?.value}/>
            )}
        </fieldset>
    );
}

VehicleDriveability.propTypes = {
    label: PropTypes.string.isRequired,
    dataSource: PropTypes.array.isRequired,
    driveability: PropTypes.string.isRequired,
    setDriveability: PropTypes.func.isRequired,
    setIsValid: PropTypes.func.isRequired
};
