import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styles from './ParkingSpotPageSelector.module.scss';
import { useIntl } from 'react-intl';
import { RadioButton } from '@kmx/legos-react-radio-button';
import { TextField } from '@kmx/legos-react-text-field';
import { Button } from '@kmx/legos-react-button';
import { OfferContext, useNavigation } from '../../context';
import IOSteps from '../../flows/steps/IOSteps';
import { validateRequiredString } from '../../utils/validators';
import parkingSpotOptions from '../../constants/parkingSpots';
import { LICENSE_PLATE_TEXT_INPUT_FIELD_MIN_LENGTH, LICENSE_PLATE_TEXT_INPUT_FIELD_MAX_LENGTH } from '../../constants/inputValidationConstants';

export default function ParkingSpotPageSelector(props) {
    const { formatMessage } = useIntl();

    const {
        title,
        subtitle,
        licensePlateInputLabel,
        licensePlateTextInputFieldMaxLength,
        licensePlateRequiredValidationErrorMessage } = props;

    const { setOffer } = useContext(OfferContext);
    const { setStep } = useNavigation();

    const [ isSpotNumberValid, setIsSpotNumberValid ] = useState(false);
    const [ parkingSpot, setParkingSpot ] = useState('');
    const [ isLicensePlateTextInputVisible, setIsLicensePlateTextInputVisible ] = useState(false);
    const [ licensePlateInput, setLicensePlateInput ] = useState('');
    const [ licensePlateInputWasTouched, setLicensePlateInputWasTouched ] = useState(false);

    const NON_DESIGNATED_PARKING_SPOT = 'N/A';
    const LICENSE_PLATE_REQUIRED_VALIDATION_STRING_NAME = 'License plate';
    const VALID_VALIDATION_STATUS = 'valid';
    const INVALID_VALIDATION_STATUS = 'invalid';

    useEffect(() => {
        setIsSpotNumberValid(false);
    }, []);

    useEffect(() => {
        if (parkingSpot !== null && parkingSpot !== undefined && parkingSpot?.length > 0) {
            setIsSpotNumberValid(true);
        }
    }, [parkingSpot]);

    const handleParkingSpotRadioButtonSelection = (radioButtonSelection) => {
        setParkingSpot(radioButtonSelection);

        if (radioButtonSelection === NON_DESIGNATED_PARKING_SPOT) {
            setIsLicensePlateTextInputVisible(true);
        } else {
            setIsLicensePlateTextInputVisible(false);
        }
    };

    const handleLicensePlateInputChange = (e) => {
        setLicensePlateInput(e.target.value);
    };

    const getValidationError = () => {
        return validateRequiredString(licensePlateInput,
            LICENSE_PLATE_REQUIRED_VALIDATION_STRING_NAME,
            LICENSE_PLATE_TEXT_INPUT_FIELD_MIN_LENGTH,
            LICENSE_PLATE_TEXT_INPUT_FIELD_MAX_LENGTH,
            licensePlateRequiredValidationErrorMessage);
    };

    const licensePlateTextInput = <div className={styles.licensePlate}>
        <TextField
            id="licensePlate"
            onChange={handleLicensePlateInputChange}
            label={licensePlateInputLabel}
            value={licensePlateInput}
            onBlur={() => setLicensePlateInputWasTouched(true)}
            validationStatus={!licensePlateInputWasTouched ? null : getValidationError() ? INVALID_VALIDATION_STATUS : VALID_VALIDATION_STATUS}
            helperText={getValidationError()}
            maxLength={licensePlateTextInputFieldMaxLength}/>
    </div>;

    const isContinueButtonDisabled = () => {
        if (isLicensePlateTextInputVisible === true) {
            return getValidationError() !== null;
        } else {
            return !isSpotNumberValid;
        }
    };

    const onSubmit = () => {
        if (isSpotNumberValid) {
            setOffer(prevState => ({...prevState, customerParkingSpot: parkingSpot, licensePlate: licensePlateInput}));
            setStep(IOSteps.CheckInPage);
        }
    };

    return (
        <>
            <div className={styles.title}>
                <h2 className={"kmx-typography--display-4"}>{title}</h2>
            </div>
            <div>
                <div className={styles.radioButtonsCard}>
                    <div className={styles.subtitle}>
                        <span>{subtitle}</span>
                    </div>
                    <div className={styles.radioButtons}>
                        <fieldset className={"kmx-flex-wrapper"}>
                            {parkingSpotOptions.map((option, index) =>
                                <RadioButton
                                    key={`parkingspot-${index}`}
                                    checked={parkingSpot === option?.value}
                                    label={option?.label}
                                    name='parking-spot-number'
                                    id={`parkingspot-${index}`}
                                    onChange={event => handleParkingSpotRadioButtonSelection(event.target.value)}
                                    value={option?.value}/>)}
                        </fieldset>
                    </div>
                    { isLicensePlateTextInputVisible === true ? licensePlateTextInput : null }
                </div>
            </div>
            <div className={styles.continueButtonContainer}>
                <Button
                    id="continueButton"
                    onClick={() => onSubmit()}
                    disabled={isContinueButtonDisabled()}
                    className={styles.continueButton}
                    level="primary">
                    {formatMessage({id: 'parkingSpotNumberPage.continueButtonLabel'})}
                </Button>
            </div>
        </>
    );
}

ParkingSpotPageSelector.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    licensePlateInputLabel: PropTypes.string.isRequired,
    licensePlateTextInputFieldMaxLength: PropTypes.number.isRequired,
    licensePlateRequiredValidationErrorMessage: PropTypes.string.isRequired
};
