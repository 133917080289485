import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    useNavigation,
    OfferContext,
    useCheckIn,
    useErrorHandling,
    useSpinner,
} from '../../context';
import { buildEventName, logFsEvent } from '../../utils/fullstory';
import { Button } from '@kmx/legos-react-button';
import { Card } from '@kmx/legos-react-card';
import { PHONE_NUMBER_MAX_LENGTH } from '../../constants/inputValidationConstants';
import { TextField } from '@kmx/legos-react-text-field';
import classNames from 'classnames';
import dogImg from '../../assets/ManWithDog.png';
import { findSalesforceAppointmentInformation, sendToSalesforce } from '/src/api/sharedApiCalls';
import { formatPhoneNumber } from '../../utils/index';
import { fullstoryConstants } from '../../constants/fullstory';
import styles from './UnifiedLandingPage.module.scss';
import unifiedSteps from '../../flows/steps/unifiedSteps';
import { useIntl } from 'react-intl';
import { validatePhoneNumber } from '../../utils/validators';
import { useEnterToMoveOn } from '/src/hooks/useEnterToMoveOn';
import { WorkOrderStatus } from '/src/interfaces/WorkOrderStatus';
import { SalesForceVisitReasons } from '/src/interfaces/SalesForceVisitReasons';
import { isKioskUX } from '../../utils/kioskMode';
import GeneralWarning from '../../components/GeneralWarning';
import useInitVisuals from '../../hooks/useInitVisuals';
import trackEvent from '/src/utils/adobe/trackEvent';
import { UnifiedSteps } from '/src/flows/steps';
import { useAdobeTracking, trackStep } from '/src/utils/adobe/analytics';

export default function UnifiedLandingPage() {
    const { formatMessage } = useIntl();
    const { setStep } = useNavigation();
    const [checkInInfo, setCheckInInfo] = useCheckIn();
    const { setOffer } = useContext(OfferContext);
    const [, showSpinner] = useSpinner();
    const { setError } = useErrorHandling();

    const [formIsValid, setFormIsValid] = useState(false);
    const [shouldTryAgain, setShouldTryAgain] = useState(false);
    const isKiosk = isKioskUX();

    const messageKeys = {
        title: isKiosk ? 'titleInKiosk' : 'common.welcome',
        subtitle: isKiosk ? 'subtitleInKiosk' : 'subtitle',
        phoneNumberLabel: 'phoneNumberLabel',
        phoneNumberRequiredErrorMessage: 'phoneNumberRequiredErrorMessage',
        phoneNumberInvalidErrorMessage: 'phoneNumberInvalidErrorMessage',
        tryAgainTitle: 'tryAgainTitle',
        tryAgainDetails: 'tryAgainDetails',
    };
    // note: Proxy helps IDE's autocompletion.
    // TODO: it's awesome, should make it a reusable hook on top of useIntl
    const messages = new Proxy(messageKeys, {
        get(target, prop) {
            const key = messageKeys[prop];
            return formatMessage({ id: key.includes('.') ? key : `unified.landingPage.${key}` });
        },
    });

    useEffect(() => {
        logFsEvent(
            `${fullstoryConstants.unifiedLandingPage.name} - ${fullstoryConstants.unifiedLandingPage.events.landingPage}`,
        );
    }, []);
    useInitVisuals();

    const hasTrackedInvalidNumber = useRef(false); // adobe tracking page InvalidPhoneNumber
    useAdobeTracking(UnifiedSteps.Landing);

    const onClickNext = () => {
        showSpinner(true);

        trackEvent.unified.phoneNumber.click2(shouldTryAgain);

        setCheckInInfo((prevState) => ({ ...prevState, phoneNumber: phoneNumber }));
        setOffer((prevState) => ({ ...prevState, phoneNumber: phoneNumber }));

        findSalesforceAppointmentInformation(phoneNumber, checkInInfo.storeNumber)
            .then((appointmentInfo) => {
                if (isKiosk && !appointmentInfo.appointmentId) {
                    setShouldTryAgain(true);
                    showSpinner(false);
                    if (!hasTrackedInvalidNumber.current) {
                        trackStep(UnifiedSteps.InvalidPhoneNumber);
                        hasTrackedInvalidNumber.current = true;
                    }
                    return;
                }
                Object.assign(checkInInfo, appointmentInfo);
                setCheckInInfo((prevState) => ({ ...prevState, ...checkInInfo }));
                const isEPU = appointmentInfo.appointmentTypes.includes(
                    SalesForceVisitReasons.ExpressPickup,
                );
                setCheckInInfo((prevState) => ({ ...prevState, isEPU: isEPU }));
                if (isEPU) {
                    if (appointmentInfo.workOrderStatus !== WorkOrderStatus.Completed) {
                        setStep(unifiedSteps.UnifiedCompletePage);
                        return sendToSalesforce(checkInInfo);
                    } else {
                        setError.pendingEpu();
                    }
                } else {
                    setStep(unifiedSteps.UnifiedAppointmentPage);
                }
                return Promise.resolve();
            })
            .catch((error) =>
                setError.general(
                    'Failed fetching appointment data or sending EPU check-in to Salesforce',
                    { error },
                ),
            );

        logFsEvent(
            buildEventName(
                fullstoryConstants.unifiedLandingPage.name,
                fullstoryConstants.unifiedLandingPage.events.nextButton,
            ),
        );
    };

    useEffect(() => {
        setFormIsValid(phoneNumberIsValid);
    }, [checkInInfo]);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberWasTouched, setPhoneNumberWasTouched] = useState(
        formatPhoneNumber(phoneNumber) !== '',
    );
    const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(false);
    const [phoneNumberValidationError, setPhoneNumberValidationError] = useState(
        validatePhoneNumber(phoneNumber, messages.phoneNumberRequiredErrorMessage),
    );

    useEffect(() => {
        setCheckInInfo((prevState) => ({ ...prevState, phoneNumber: phoneNumber }));

        const validation = validatePhoneNumber(
            phoneNumber,
            messages.phoneNumberRequiredErrorMessage,
            messages.phoneNumberInvalidErrorMessage,
        );
        setPhoneNumberValidationError(validation);

        if (validation == null) setPhoneNumberIsValid(true);
        else setPhoneNumberIsValid(false);
    }, [phoneNumber]);

    useEnterToMoveOn(
        onClickNext,
        [phoneNumber, checkInInfo, setStep, setCheckInInfo, setOffer, formIsValid],
        () => formIsValid,
    );

    return (
        <div className={classNames(styles.container, { [styles.kioskUX]: isKiosk })}>
            {!isKiosk && <img className={styles.image} src={dogImg} />}
            <h4 className={classNames(styles.header, 'kmx-typography--display-4')}>
                {messages.title}
            </h4>
            <Card flat={true} className={styles.card}>
                <h4 className={classNames(styles.subtitle, 'kmx-typography--display-2')}>
                    {messages.subtitle}
                </h4>
                {shouldTryAgain && (
                    <GeneralWarning
                        title={messages.tryAgainTitle}
                        description={messages.tryAgainDetails}
                    />
                )}
                <fieldset className={styles['form-field']} id="phone-number">
                    <TextField
                        onChange={(event) => setPhoneNumber(formatPhoneNumber(event.target.value))}
                        label={messages.phoneNumberLabel}
                        value={phoneNumber}
                        required={true}
                        maxLength={PHONE_NUMBER_MAX_LENGTH}
                        name="phone"
                        helperTextIsPersistent={true}
                        helperText={!phoneNumberWasTouched ? null : phoneNumberValidationError}
                        onBlur={() => setPhoneNumberWasTouched(true)}
                        validationStatus={
                            !phoneNumberWasTouched ? null : phoneNumberIsValid ? 'valid' : 'invalid'
                        }
                        type="text"
                        inputMode="numeric"
                        inputProps={{ pattern: '[0-9]*' }}
                    />
                </fieldset>
                <p className="kmx-typography--fine-print">
                    {formatMessage({ id: 'unified.landingPage.phoneInstructions' })}
                </p>
            </Card>
            <div className={styles.buttonContainer}>
                {isKiosk && (
                    <>
                        <Button
                            className={styles.button}
                            id="unified-landing-page-back-button"
                            level="secondary"
                            onClick={() => {
                                trackEvent.unified.phoneNumber.click3(
                                    shouldTryAgain,
                                );
                                setStep(unifiedSteps.DoYouHaveAppointment);
                            }}
                        >
                            {formatMessage({ id: 'common.back' })}
                        </Button>
                    </>
                )}
                <Button
                    className={styles.button}
                    id="unified-landing-page-next-button"
                    disabled={!formIsValid}
                    level="primary"
                    onClick={() => onClickNext()}
                >
                    {formatMessage({ id: 'common.next' })}
                </Button>
            </div>
        </div>
    );
}
