import React, { useEffect } from 'react';
import cx from 'classnames';
import useInitVisuals from '/src/hooks/useInitVisuals';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './PCIArrivedPage.module.scss';
import ImageInStorePurchase from '/src/assets/in-store-purchase.svg';
import AppointmentInfo from '/src/components/AppointmentInfo';
import useAppointmentAndStore from '/src/api/useAppointmentAndStore';
import { Button } from '@kmx/legos-react-button';
import { useCheckIn, useNavigation, useSpinner } from '/src/context';
import { sendToSalesforce } from '/src/api/sharedApiCalls';
import unifiedSteps from '/src/flows/steps/unifiedSteps';
import flows from '/src/flows/flows';
import { PreCheckInSteps } from '/src/flows/steps';
import useAdobePci from '../../v2/common/hooks/useAdobePci';
import { useErrorHandling } from '/src/context/errorHandling';
import { setFsDetails } from '/src/utils/fullstory';

export default function PCIArrivedPage() {
    const [, showSpinner] = useSpinner();
    const { formatMessage } = useIntl();
    const { setFlowAndStep, setStep } = useNavigation();
    const { setError } = useErrorHandling();
    const [checkIn, setCheckInInfo] = useCheckIn();

    const { isFetchComplete, isDataInvalid, appointment, store } = useAppointmentAndStore();

    useEffect(() => {
        if (isFetchComplete && !isDataInvalid) {
            setCheckInInfo({
                ...appointment,
                isEPU: true,
                storeFormat: store.storeFormat,
                hadSalesforceAppointment: true,
            });
            setFsDetails({
                acquisitionChannel: 'SMS',
                storeNumber: store?.id,
                appointmentType: 'EPU',
                storeType: store?.storeFormat,
                appointment: 'Yes',
            });
        }
    }, [isFetchComplete, isDataInvalid]);

    // this page is managed by React Router directly, but to be in line
    // with the rest of the project, setting the current step:
    useEffect(() => {
        setStep(PreCheckInSteps.PCIArrived);
    }, []);

    useInitVisuals(isFetchComplete);
    useAdobePci(PreCheckInSteps.PCIArrived);

    const onClickCta = () => {
        showSpinner(true);
        return sendToSalesforce(checkIn)
            .then(() => {
                setFlowAndStep(flows.Unified, unifiedSteps.UnifiedCompletePage);
            })
            .catch((error) => {
                setError.general('Failed submitting pre-check-in to salesforce', {
                    error,
                    additionalInfo: { appointment },
                });
            });
    };

    return (
        isFetchComplete &&
        !isDataInvalid && (
            <div className={styles.container}>
                <div className={styles.imageContainer}>
                    <ImageInStorePurchase />
                </div>
                <div className={styles.title}>
                    <h1 className="kmx-typography--display-4">
                        <FormattedMessage id="common.welcome" />
                    </h1>
                </div>
                <div className={styles.main}>
                    <div className={cx(styles.instructions, 'kmx-typography--body-1')}>
                        <FormattedMessage id="pciArrived.instructions" />
                    </div>
                    {/* important - button's id is used in analytics */}
                    <Button
                        className={styles.cta}
                        id="i-am-here-cta"
                        level="primary"
                        onClick={onClickCta}
                    >
                        {formatMessage({ id: 'pciArrived.cta' })}
                    </Button>
                </div>
                <AppointmentInfo
                    withHeader={true}
                    appointmentDatetime={appointment.appointmentDatetime}
                    store={store}
                />
            </div>
        )
    );
}
