import { DependencyList, EffectCallback, useCallback, useEffect } from 'react';

export const useDebounce = (effect: EffectCallback, dependencies: DependencyList, delay: number) => {
    // store the provided effect in a `useCallback` hook to avoid
    // having the callback function execute on each render
    const callback = useCallback(effect, dependencies);

    // wrap our callback function in a `setTimeout` function
    // and clear the timeout when completed
    useEffect(() => {
        const timeout = setTimeout(callback, delay);
        return () => clearTimeout(timeout);
    }, [callback, delay]);
};
