import {
    HttpClientError,
    HttpServerError,
    RequestCancelledError
} from "../interfaces/errors";
import {DEFAULT_API_RETRIES, DEFAULT_API_RETRY_DELAY_MS} from "../constants/api";
import {fsEvents, logFs} from "../utils/fullstory";

enum HttpMethods {
    get = 'GET',
    post = 'POST',
    put = 'PUT',
    delete = 'DELETE'
}

type ApiCall<BodyType> = <ResultType = any>(url: string, body?: BodyType, numberOfRetries?: number) => Promise<ResultType>;

const apiCall = <T = unknown>(method: HttpMethods, defaultNumberOfRetries, url, body, numberOfRetries): Promise<T> => {
    const requestOptions = {
        method,
        headers: { 'Content-Type': 'application/json' },
        ...(body ? { body: JSON.stringify(body) } : {}),
    };
    return fetchWithRetry(url, requestOptions, numberOfRetries ?? defaultNumberOfRetries);
}

export const apiWrapper = {
    GET: apiCall.bind(null, HttpMethods.get, DEFAULT_API_RETRIES) as ApiCall<null>,
    // note: most POST requests are not idempotent and should NOT be retried by default
    // (exceptions: calls that behave more like GETs, e.g. search requests)
    POST: apiCall.bind(null, HttpMethods.post, 0) as ApiCall<object>,
    PUT: apiCall.bind(null, HttpMethods.put, DEFAULT_API_RETRIES) as ApiCall<object>,
    DELETE: apiCall.bind(null, HttpMethods.delete, DEFAULT_API_RETRIES) as ApiCall<null>,
};

const fetchWithRetry = async (url, options, retries, retryDelayInMs = DEFAULT_API_RETRY_DELAY_MS, attempt = 1) => {
    try {
        const result = await fetch(url, options);
        const { status } = result;
        if (status < 400) {
            const text = await result.text(); // Parse it as text
            return text ? JSON.parse(text) : null; // Try to parse it as JSON
        }
        const errorText = `[${status}] ${await result.text()}`;
        if (status < 500) {
            throw new HttpClientError(status, errorText);
        }
        throw new HttpServerError(status, errorText);
    } catch (error) {
        if (error instanceof HttpClientError) {
            throw error; // unfixable
        }
        // at this point we're dealing with servers-side or fetch-related errors
        if (attempt > retries) {
            logFs(fsEvents.info.requestRetriesExhausted, {
                requestUrl: url,
                requestOptions: options,
                error,
                totalRetries: retries
            });
            // Trying to detect cancelled errors. TypeError occurs when fetch simply fails.
            // (invalid request URL, CORS, server went down since the app loaded, request was cancelled by user)
            // Error messages vary by browser and can change
            // (e.g. Failed to fetch, cancelled, Load failed, NetworkError when attempting to fetch resource)
            // In the context of our app, all occurrences should be cancelled requests (user hit refresh/stop).
            // A possible (but improbable) false positive is when server breaks suddenly; see errorHandling.tsx
            if (error.name === 'TypeError') {
                throw new RequestCancelledError(error.message);
            }
            throw error;
        }
        await new Promise(resolve => setTimeout(resolve, retryDelayInMs));
        logFs(fsEvents.info.requestRetried, {
            requestUrl: url,
            requestOptions: options,
            error,
            retryAttempt: attempt,
            totalRetries: retries
        });
        return await fetchWithRetry(url, options, retries, retryDelayInMs, attempt + 1);
    }
};
