export enum ErrorTypes {
    general = 'general',
    checkInDisabled = 'checkInDisabled',
    duplicateSubmission = 'duplicateSubmission',
    fatal = 'fatal', // uncaught error that would lead to React unmounting everything
    pendingEpu = 'pendingEpu',
    notFound = 'notFound',
    missingStoreId = 'missingStoreId'
}

// identifiers for error pages, based on error types, e.g. 'Error: general'
export const ErrorPages = new Proxy(ErrorTypes, {
    get(_, errorType: string) {
        return `Error: ${errorType};`;
    },
});

export class RequestCancelledError extends Error {
    constructor(message) {
        super(message);
        this.name = "RequestCancelled";
    }
}

export class HttpError extends Error {
    statusCode: number;
    constructor(statusCode: number, message: string) {
        super(message);
        this.name = "HttpError";
        this.statusCode = statusCode;
    }
}
export class HttpClientError extends HttpError {
    constructor(statusCode: number, message: string) {
        super(statusCode, message);
        this.name = "HttpClientError";
    }
}
export class HttpServerError extends HttpError {
    constructor(statusCode: number, message: string) {
        super(statusCode, message);
        this.name = "HttpServerError";
    }
}
