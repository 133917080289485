import React, { Component, ErrorInfo, ReactNode } from "react";
import { errorSideEffects } from "/src/context/errorHandling";
import { ErrorTypes } from '/src/interfaces/errors';
import styles from "./ErrorPage.module.scss";
import ErrorPageHeader from '/src/components/ErrorPageHeader/ErrorPageHeader';
import ErrorIcon from '../../assets/triangle-error.png';

type Props = {
    children: ReactNode;
};
type State = {
    hasError: boolean;
};

export default class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        errorSideEffects(ErrorTypes.fatal, '', {
            error,
            additionalInfo: { componentStack: errorInfo.componentStack },
        });
    }

    render() {
        const { hasError } = this.state;
        const { children } = this.props;

        // at this level i18n is not available, this last-resort display in always in EN
        return !hasError ? (
            children
        ) : (
            <div className={styles.container}>
                <ErrorPageHeader
                    title="Something went wrong on our end"
                    subtitle="Please come inside the store and our associates would be happy to assist you with checking in."
                    image={ErrorIcon}
                />
            </div>
        );
    }
}
