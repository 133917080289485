import {
    STORE_ID_QUERYSTRING_PARAMETER,
    OFFER_CODE_QUERYSTRING_PARAMETER,
    LANGUAGE_QUERYSTRING_PARAMETER,
    APPOINTMENT_ID_QUERYSTRING_PARAMETER,
    KIOSK_MODE,
} from '../constants/queryStringParams';
import { validateStoreId, validateOfferCodeId } from './validators';
import { pciSteps } from '../flows/PreCheckIn/pciSteps';

export const BASE_URL = '/checkin';
export const INVALID_STORE_ID = 'invalid-store-id';
export const INVALID_OFFER_CODE = 'invalid-offer-code';

// test utils for mocking url params (a work-around, Cypress for component testing sucks)
let mockParams = null;
/** IMPORTANT: ALWAYS use together with restoreUrlParams() !
 * @param paramsObject E.g. { lang: 'es' }
 */
export const mockUrlParams = (paramsObject) => {
    mockParams = paramsObject;
};
// apply this one in `afterEach` in every suite you use `mockUrlParams`!
export const restoreUrlParams = () => {
    mockParams = null;
};

function getUrlParam(param) {
    if (mockParams) {
        return mockParams[param] ?? null;
    }
    const params = new URLSearchParams(window.location.search);
    const newParams = new URLSearchParams(
        [...params].map(([key, value]) => [key.toLowerCase(), value]),
    );
    return newParams.get(param.toLowerCase());
}

export function getValidatedStoreIdFromUrl() {
    // get the store number from the query param, if available
    const storeId = getUrlParam(STORE_ID_QUERYSTRING_PARAMETER);

    if (storeId) {
        if (validateStoreId(storeId)) {
            return storeId;
        } else {
            return INVALID_STORE_ID;
        }
    } else {
        return null;
    }
}

export function getOfferCodeFromUrl() {
    const offerCodeId = getUrlParam(OFFER_CODE_QUERYSTRING_PARAMETER);
    if (offerCodeId) {
        if (validateOfferCodeId(offerCodeId)) {
            return offerCodeId;
        } else {
            return INVALID_OFFER_CODE;
        }
    } else {
        return null;
    }
}

export const getLanguageFromUrl = () => getUrlParam(LANGUAGE_QUERYSTRING_PARAMETER) || null;

// accepting /pre/123/welcome and /pci-info?appointmentId=123
export const getAppointmentIdFromUrl = () =>
    getUrlParam(APPOINTMENT_ID_QUERYSTRING_PARAMETER) ||
    window.location.href.match(/\/pre\/(.+)\//)?.[1];

// e.g. getUrlFromPciPath('/pre/:appointmentId/welcome', 2) => '/pre/2/welcome
export const getUrlFromPciPath = (stepPath, appointmentId) =>
    stepPath.replace(':appointmentId', appointmentId);

export const getPciStepFromUrl = () => {
    const genericPath = window.location.pathname.replace(
        /^\/checkin\/pre\/(.+)\/([\w-]+)$/,
        '/pre/:appointmentId/$2',
    );
    return Object.values(pciSteps.v2).find((step) => step.path === genericPath);
};

export const getKioskModeFromUrl = () => getUrlParam(KIOSK_MODE);

// returns a url search (as in window.location.search) with all user-related info stripped out
export const getAnonymizedSearch = () =>
    [
        [STORE_ID_QUERYSTRING_PARAMETER, getValidatedStoreIdFromUrl],
        [KIOSK_MODE, getKioskModeFromUrl],
    ].reduce((search, [paramKey, getter]) => {
        const paramValue = getter();

        if (!paramValue) {
            return search;
        }
        const urlFragment = `${paramKey}=${paramValue}`;
        const urlFragmentConnector = search.length === 0 ? '?' : '&';
        return search + urlFragmentConnector + urlFragment;
    }, '');

// This object is added for testability/technical reasons only:
// Cypress can't mock functions, only object methods.
// Use it (and add to it) as needed.
export const getFromUrl = {
    validatedStore: getValidatedStoreIdFromUrl,
    // add other methods as needed
};
