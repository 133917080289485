import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { importMessages } from './utils/intlHelpers';
import enLang from './lang/en';
import classNames from 'classnames';
import './index.scss';
import styles from './App.module.scss';
import {
    OfferContext,
    useNavigation,
    useCheckIn,
    useSpinner,
    useFeatures,
    useStore,
} from './context';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import { apiWrapper } from './api/apiService';
import { featureFlagNames } from './constants/featureFlags';
import { apiRoutes, STORE_HOURS_AVAILABILITY } from './constants/api';
import {
    INVALID_STORE_ID,
    getValidatedStoreIdFromUrl,
    getOfferCodeFromUrl,
    INVALID_OFFER_CODE,
    getLanguageFromUrl,
} from './utils/urlHelpers';
import { getFeatureFlagsData, getStoreData } from './api/sharedApiCalls';
import { SPANISH_LANUGAGE_CODE } from './constants/languageCodes';
import flows from './flows/flows';
import unifiedSteps from './flows/steps/unifiedSteps';
import Routes from './flows/Routes';
import { useErrorHandling } from '/src/context';
import ErrorPage from './containers/ErrorPage/ErrorPage';
import { BaseConfiguration } from '/src/interfaces/Configuration';
import { setConfiguration } from '/src/utils/configuration';
import PageLayout from '/src/components/PageLayout/PageLayout';

export default function App() {
    const history = useHistory();

    const { error, setError } = useErrorHandling();

    const [isSpinnerVisible] = useSpinner();
    const [isInternalFetchingComplete, setIsInternalFetchingComplete] = useState(false);

    const [offer, setOffer] = useState<any>();
    const [offers, setOffers] = useState([]);
    const offerValue = { offer, setOffer, offers, setOffers };

    const [, setCheckInInfo] = useCheckIn();
    const [, setStore] = useStore();
    const [, setFeatures] = useFeatures();
    const { step, flow } = useNavigation();

    // TODO: break this function into smaller pieces and maybe move the logic to more relevant files
    useEffect(() => {
        window.addEventListener('popstate', () => {
            // Return to the initial landing page
            history.go(0);
        });

        apiWrapper
            .GET(apiRoutes.getConfigurationApi)
            .then((configuration: BaseConfiguration) => {
                setConfiguration(configuration);
                // add adobe launch integration
                const script = document.createElement('script');
                script.src = configuration.adobeLaunchUrl;
                script.async = true;
                document.body.appendChild(script);
                // add appInsights - disabled - not currently used, adds too much to the bundle
                // useAppInsights(configuration.applicationInsightsKey);
            })
            .catch(() => {}); // Logged error already - it is okay if adobe launch or AppInsights do not attach

        const storeId = getValidatedStoreIdFromUrl();
        if (storeId) {
            if (storeId !== INVALID_STORE_ID) {
                setCheckInInfo((prevState) => ({ ...prevState, storeNumber: storeId }));
                setOffer((prevState) => ({ ...prevState, storeNumber: storeId }));

                // TODO: move this logic to the context providers
                // preload feature flags and store hours data if valid storeId is found in querystring
                Promise.all([getStoreData(storeId), getFeatureFlagsData(storeId)])
                    .then(([storeData, featureFlagsData]) => {
                        if (storeData !== null) {
                            setCheckInInfo((prevState) => ({
                                ...prevState,
                                storeFormat: storeData.storeFormat,
                            }));
                            setStore(storeData);

                            if (storeData?.status === STORE_HOURS_AVAILABILITY.CLOSED) {
                                setError.checkInDisabled('Store closed', {
                                    additionalInfo: { storeData },
                                });
                            }
                        }

                        const offerCode = getOfferCodeFromUrl();
                        if (offerCode) {
                            if (offerCode !== INVALID_OFFER_CODE) {
                                setFeatures((prevState) => ({
                                    ...prevState,
                                    [featureFlagNames.IsUnifiedCheckInEnabled]: false,
                                    [featureFlagNames.IsSelfCheckInEnabled]: true,
                                }));
                            } else {
                                setFeatures((prevState) => ({
                                    ...prevState,
                                    [featureFlagNames.IsUnifiedCheckInEnabled]: false,
                                }));
                                setFeatures((prevState) => ({
                                    ...prevState,
                                    [featureFlagNames.IsSelfCheckInEnabled]: false,
                                }));
                            }
                        } else if (featureFlagsData !== null) {
                            setFeatures(featureFlagsData);
                        }
                        setIsInternalFetchingComplete(true);
                    })
                    .catch((error) => {
                        setError.general('Failed handling store or feature data', {
                            error,
                            additionalInfo: { storeId },
                        });
                        setIsInternalFetchingComplete(true);
                    });
            } else {
                setError.checkInDisabled('', { additionalInfo: { storeId } });
                setIsInternalFetchingComplete(true);
            }
        } else {
            // allow customer to use appraisal check in without a store id;
            setFeatures((prevState) => ({
                ...prevState,
                [featureFlagNames.IsUnifiedCheckInEnabled]: false,
                [featureFlagNames.IsSelfCheckInEnabled]: true,
            }));
            setIsInternalFetchingComplete(true);
        }
    }, []);

    const [messages, setMessages] = useState(enLang);
    useEffect(() => {
        if (getLanguageFromUrl() === SPANISH_LANUGAGE_CODE) {
            importMessages(SPANISH_LANUGAGE_CODE).then((messages) => {
                if (messages !== null) {
                    setMessages(messages);
                }
            });
        }
    }, []);

    const isSplashPage = flow === flows.Unified && step === unifiedSteps.UnifiedSplashPage;
    const shouldShowSpinner = isSpinnerVisible || !isInternalFetchingComplete;

    useEffect(() => {
        const action = shouldShowSpinner ? 'add' : 'remove';
        document.getElementById('spinner-animation')?.classList[action]('show');
    }, [shouldShowSpinner]);

    return (
        <IntlProvider messages={messages} locale="en" defaultLocale="en">
            {isInternalFetchingComplete &&
                (error ? (
                    <PageLayout
                        header={<Header />}
                        content={<ErrorPage />}
                    />
                ) : (
                    <div
                        className={classNames(styles.pageWrapper, {
                            [styles.hide]: shouldShowSpinner,
                        })}
                    >
                        <PageLayout
                            header={<Header isSplashPage={isSplashPage} />}
                            content={
                                <OfferContext.Provider value={offerValue}>
                                    <Routes />
                                </OfferContext.Provider>
                            }
                            footer={!isSplashPage && <Footer />}
                        />
                    </div>
                ))}
        </IntlProvider>
    );
}
