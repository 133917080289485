export enum SalesForceVisitReasons {
    Browse = 'Browse',
    TestDrive = 'Test Drive',
    Financing = 'Financing',
    Appraisal = 'Appraisal',
    IcoAppraisalAppointment = 'ICO Appraisal Appointment',
    ExpressPickup = 'Express Pickup',
    Other = 'Other',
    OtherInput = 'Other Text Input Value'
}
