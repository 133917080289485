import React from 'react';
import styles from './Footer.module.scss';

export default function Footer() {
    return (
        <footer className={styles["footer"]}>
            <div className={styles["footer-content"]}>

                <div className={styles["footer-content__legal"]}>
                    <nav className={styles["footer-content__legal__nav"]}>
                        <a href="https://www.carmax.com/privacy-policy" title="Privacy Policy" className={styles["footer-content__legal__nav__item"]}>PRIVACY POLICY</a>
                        <a href="https://www.carmax.com/terms" title="Terms of Use" className={styles["footer-content__legal__nav__item"]}>TERMS OF USE</a>
                    </nav>

                    <span id="copyright" className={styles["footer-content__legal__copyright"]}>
                        Copyright © 2022 CarMax Business Services, LLC
                    </span>
                </div>

            </div>
        </footer>
    );
}
