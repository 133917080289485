import { useState, useEffect, useCallback } from 'react';
import { apiWrapper } from '../api/apiService';
import { HTTP_STATUS } from '../constants/api';
import { noop } from "../constants/general";

interface UseDataFetchParams<T> {
    // if given, fetch this url
    url?: string;
    // if given, fetch by calling this function
    callback?: (...args: any[]) => Promise<T>;
    // apply these args to `callback`
    args?: any[];
    // only fetch if guard returns TRUE (useful for chaining api calls)
    guard?: () => boolean;
    // dependencies that trigger a re-run of the hook. Don't need to include deps that are in the args array
    deps?: any[];
}

const useDataFetch = <T>({ url, callback, args, guard, deps }: UseDataFetchParams<T>) => {
    const [status, setStatus] = useState(HTTP_STATUS.IDLE);
    const [data, setData] = useState<T>({} as T);
    const [error, setError] = useState();

    // useCallback, JSON.stringify -> avoid re-renders due to referential inequality
    const serializedArgs = JSON.stringify(args);
    const relevantDependencies = [url, callback, serializedArgs, ...deps ?? []];

    const apiCall = useCallback(
        url
            ? apiWrapper.GET.bind(null, url)
            : callback
                ? callback.bind(null, ...args)
                : noop,
        relevantDependencies,
    );

    useEffect(() => {
        if (guard && !guard() || apiCall === noop) {
            return;
        }
        const fetchData = async () => {
            try {
                const response = await apiCall();
                setData(response);
                setStatus(HTTP_STATUS.SUCCESS);
            } catch (e) {
                setStatus(HTTP_STATUS.ERROR);
                setError(e);
            }
        };
        setStatus(HTTP_STATUS.FETCHING);
        fetchData();
    }, relevantDependencies);

    return { data, error, status, isFetchComplete: [HTTP_STATUS.SUCCESS, HTTP_STATUS.ERROR].includes(status) };
};

export default useDataFetch;
