import React, { useState } from 'react';
import { useCheckIn, useNavigation, useSpinner, useErrorHandling } from '/src/context';
import { fsEvents, logFs } from '/src/utils/fullstory';
import { Button } from '@kmx/legos-react-button';
import { Steps } from '@kmx/acquisitions-steps';
import UnifiedCustomerContact from '../../components/UnifiedCustomerContact/UnifiedCustomerContact';
import classNames from 'classnames';
import { sendToSalesforce } from '/src/api/sharedApiCalls';
import styles from './UnifiedCustomerInfoPage.module.scss';
import { useAdobeTracking } from '../../utils/adobe/analytics';
import unifiedSteps from '../../flows/steps/unifiedSteps';
import { useIntl } from 'react-intl';
import { useEnterToMoveOn } from '/src/hooks/useEnterToMoveOn';
import useInitVisuals from '../../hooks/useInitVisuals';
import { UnifiedSteps } from '/src/flows/steps';
import trackEvent from '../../utils/adobe/trackEvent';

export default function UnifiedCustomerInfoPage() {
    const { formatMessage } = useIntl();
    const { setStep } = useNavigation();
    const [checkInInfo, setCheckInInfo] = useCheckIn();
    const [isValidContact, setIsValidContact] = useState(false);
    const [, showSpinner] = useSpinner();
    const { setError } = useErrorHandling();

    useInitVisuals();
    useAdobeTracking(UnifiedSteps.CustomerInfo);

    const stepDefinition = [
        {
            id: 'customer-contact',
            className: 'customer-contact-step',
            header: (
                <div id="customerStepHeader">
                    {formatMessage({ id: 'customerContactPage.customerStepLabel' })}
                </div>
            ),
            body: <UnifiedCustomerContact setIsValid={setIsValidContact} />,
            autoProceedOnComplete: false,
        },
    ];

    const submitContactInfo = async () => {
        logFs(fsEvents.customerPage.submitButton);
        trackEvent.unified.customerInfo.click2();
        showSpinner(true);
        try {
            await sendToSalesforce(checkInInfo);
            setStep(unifiedSteps.UnifiedCompletePage);
        } catch (error) {
            setError.general('Failed sending check-in data to Salesforce', { error });
        }
    };

    useEnterToMoveOn(submitContactInfo, [checkInInfo, isValidContact], () => isValidContact);

    return (
        <div className={styles.container}>
            <h4
                className={classNames('kmx-typography--display-4', styles.header)}
                id="unifiedContactStepHeader"
            >
                {formatMessage({ id: 'customerContactPage.header' })}
            </h4>
            <div className={styles.steps}>
                <Steps stepDefinition={stepDefinition} hostControlledStepExpansion={true} />
            </div>
            <Button
                id="submit-button"
                type="button"
                className={styles.primary}
                level="primary"
                disabled={!isValidContact}
                onClick={() => submitContactInfo()}
            >
                {formatMessage({ id: 'customerContactPage.submitButton' })}
            </Button>
        </div>
    );
}
