import React from 'react';
import { ITEMS_TO_BRING } from '/src/flows/PreCheckIn/v2/ItemsToBring/constants';
import useInitVisuals from '/src/hooks/useInitVisuals';
import useAppointmentAndStore from '/src/api/useAppointmentAndStore';

const DocumentList = () => {
    const { appointment, hasFetchedAppointment } = useAppointmentAndStore();

    return hasFetchedAppointment && (
        <hzn-stack space="large" align-x="left">
            {Object.values(ITEMS_TO_BRING).map(({ label, values, RenderComponent, guard }) => (
                (guard ? guard(appointment) : true) && <RenderComponent
                    key={label}
                    appointment={appointment}
                    label={label}
                    value={values || []}
                />
            ))}
        </hzn-stack>
    );
};
export default DocumentList;
