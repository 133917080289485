import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './IOConfirmOfferPage.module.scss';
import { useIntl } from 'react-intl';
import { logFsEvent, buildEventName } from '../../utils/fullstory';
import { fullstoryConstants } from '../../constants/fullstory';
import { useNavigation, OfferContext } from '../../context';
import IOSteps from '../../flows/steps/IOSteps';
import { Button } from '@kmx/legos-react-button';
import { Checkbox } from '@kmx/legos-react-checkbox';
import { formatCurrencyWithoutCents } from '../../utils';
import { useEnterToMoveOn } from '../../hooks/useEnterToMoveOn';
import useInitVisuals from '../../hooks/useInitVisuals';

function OfferCard(props) {
    const { formatMessage } = useIntl();
    const { valuation, code, expiresDisplayDate } = props;

    return (
        <div className={styles['offer-card']} id="offer-card">
            <div className={classNames(styles['line-1'], 'kmx-typography--display-4')} id="line-1">
                {formatMessage({ id: 'confirmOfferPage.offerCard.confirmOffer' })}
            </div>
            <div className={classNames(styles['line-2'], 'kmx-typography--display-6')} id="line-2">
                {formatCurrencyWithoutCents(String(valuation))}*
            </div>
            <div className={classNames(styles['line-3'], 'kmx-typography--display-2')} id="line-3">
                {formatMessage({ id: 'confirmOfferPage.offerCard.offerCode' })} {code}
            </div>
            <div className={classNames(styles['line-4'], 'kmx-typography--body-1')} id="line-4">
                {formatMessage({ id: 'confirmOfferPage.offerCard.validUntil' })}{' '}
                {expiresDisplayDate}
            </div>
        </div>
    );
}

export default function IOConfirmOfferPage(props) {
    const { formatMessage } = useIntl();

    const { offerValue } = props;

    const { setOffer } = useContext(OfferContext);
    const { setStep } = useNavigation();

    const [ack, setAck] = useState(false);

    useInitVisuals();

    function doNothing(e) {
        e.preventDefault();
    }

    function acknowledge(e) {
        setOffer((prevState) => ({ ...prevState, acknowledgeLegal: e.target.checked }));
        setAck(e.target.checked);

        logFsEvent(
            buildEventName(
                fullstoryConstants.confirmOfferPage.name,
                fullstoryConstants.confirmOfferPage.events.acknowledgementCheckbox,
            ),
        );
    }

    function onClick() {
        setStep(IOSteps.CustomerInfoPage);
        logFsEvent(
            buildEventName(
                fullstoryConstants.confirmOfferPage.name,
                fullstoryConstants.confirmOfferPage.events.continueButton,
            ),
        );
    }

    useEnterToMoveOn(onClick, [setStep, ack], () => ack);

    return (
        <form className={styles.container} onSubmit={doNothing}>
            <OfferCard
                valuation={offerValue?.valuation}
                code={offerValue?.code}
                expiresDisplayDate={offerValue?.expiresDisplayDate}
            />
            <div className={classNames('kmx-typography--body-2', styles.requirements)}>
                {formatMessage({ id: 'confirmOfferPage.requirementsList.header' })}
                <ul className={styles.list}>
                    <li>
                        {formatMessage({ id: 'confirmOfferPage.requirementsList.listItem1' })}
                        <p>
                            {formatMessage({
                                id: 'confirmOfferPage.requirementsList.listItem1.info',
                            })}
                        </p>
                    </li>
                    <li>{formatMessage({ id: 'confirmOfferPage.requirementsList.listItem2' })}</li>
                    <li>{formatMessage({ id: 'confirmOfferPage.requirementsList.listItem3' })}</li>
                    <li>{formatMessage({ id: 'confirmOfferPage.requirementsList.listItem4' })}</li>
                </ul>
            </div>
            <Checkbox
                className={styles.acknowledge}
                label={formatMessage({ id: 'confirmOfferPage.acknowledge' })}
                required={true}
                value={ack}
                onChange={acknowledge}
            />
            <div className={classNames('kmx-typography--body-1', styles.legal)}>{formatMessage({ id: 'confirmOfferPage.legal' })}</div>
            <Button
                className={styles.primary}
                level="primary"
                id="confirm-offer-button"
                disabled={!ack}
                onClick={() => onClick()}
            >
                {formatMessage({ id: 'confirmOfferPage.submit' })}
            </Button>
        </form>
    );
}

OfferCard.propTypes = {
    valuation: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    expiresDisplayDate: PropTypes.object.isRequired,
};

IOConfirmOfferPage.propTypes = {
    offerValue: PropTypes.object.isRequired,
};
