import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { OfferContext, useCheckIn } from '../../context';
import CustomerAddressInfo from '../CustomerAddressInfo/CustomerAddressInfo';
import CustomerInfoFields from '../CustomerInfoFields/CustomerInfoFields';

export default function CustomerInfo(props) {
    const { formatMessage } = useIntl();
    const { offer, setOffer } = useContext(OfferContext);
    const [, setCheckInInfo] = useCheckIn();

    const {
        setIsValid,
        onCompleteChange
    } = props;

    const [firstName, setFirstName] = useState(offer?.firstName ?? '');
    const [lastName, setLastName] = useState(offer?.lastName ?? '');

    const [phoneNumber, setPhoneNumber] = useState(offer?.phoneNumber ?? '');
    const [email, setEmail] = useState(offer?.email ?? '');

    const [contactInfoValid, setContactInfoValid] = useState(false);
    const [addressInfoIsValid, setAddressInfoIsValid] = useState(false);

    useEffect(() => {
        const isValid = addressInfoIsValid && contactInfoValid;
        setIsValid(isValid);
        onCompleteChange(isValid);
    }, [contactInfoValid, addressInfoIsValid]);

    useEffect(() => {
        setOffer(prevState => ({...prevState, firstName: firstName}));
        setCheckInInfo(prevState => ({...prevState, firstName: firstName}));
    }, [firstName]);

    useEffect(() => {
        setOffer(prevState => ({...prevState, lastName: lastName}));
        setCheckInInfo(prevState => ({...prevState, lastName: lastName}));
    }, [lastName]);

    useEffect(() => {
        setOffer(prevState => ({...prevState, phoneNumber: phoneNumber}));
        setCheckInInfo(prevState => ({...prevState, phoneNumber: phoneNumber}));
    }, [phoneNumber]);

    useEffect(() => {
        setOffer(prevState => ({...prevState, email: email}));
        setCheckInInfo(prevState => ({...prevState, email: email}));
    }, [email]);

    return (
        <div test_id="customerInfoBody">
            <h2 className='kmx-typography--display-3'>{formatMessage({id: 'customerInfoPage.customerStepLabel'})}</h2>
            <CustomerInfoFields
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                email={email}
                setEmail={setEmail}
                showEmailSubtext={false}
                setIsValid={setContactInfoValid}
            />
            <CustomerAddressInfo setAddressInfoIsValid={setAddressInfoIsValid}/>
        </div>
    );
}

CustomerInfo.propTypes = {
    setIsValid: PropTypes.func.isRequired,
    onCompleteChange: PropTypes.any
};
