import React from 'react';
import PropTypes from 'prop-types';
import styles from  './CarImageTitle.module.scss';

export default function CarImageTitle(props) {
    const { title } = props;

    return (
        <>
            <div className={styles.carImageTitle}>
                <h4 className={"kmx-typography--display-4"}>{title}</h4>
            </div>
        </>
    );
}

CarImageTitle.propTypes = {
    title: PropTypes.string.isRequired
};
