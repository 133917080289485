import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './StateDropdownSelector.module.scss';
import { logFsEvent, buildEventName } from '../../utils/fullstory';
import { fullstoryConstants } from '../../constants/fullstory';
import ValidatedSelect from '../ValidatedSelect/ValidatedSelect';

export default function StateDropdownSelector(props) {
    const { state, setState, errorMessageText} = props;

    useEffect(() => {
        if (state && state !== '') {
            setState(state);
        }
    }, [state]);

    const handleStateInputChange = (e) => {
        setState(e.target.value);
    };

    const onFocus = () => {
        logFsEvent(buildEventName(fullstoryConstants.checkInPage.name,
            fullstoryConstants.checkInPage.events.stateDropdown));
    };

    return (
        <div id="state-selector-area">
            <div className={styles.label}>
                <h4>{props.label}</h4>
            </div>
            <div>
                <ValidatedSelect
                    options={props.dataSource}
                    value={state}
                    disabled={false}
                    onChange={handleStateInputChange}
                    onFocus={() => onFocus()}
                    id="ico-state"
                    name="state"
                    displayHelperText={true}
                    dropdownErrorMessage={errorMessageText} />
            </div>
        </div>
    );
}

StateDropdownSelector.propTypes = {
    state: PropTypes.string.isRequired,
    setState: PropTypes.func.isRequired,
    dataSource: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    errorMessageText: PropTypes.string.isRequired
};
