import React, { useEffect, useState } from 'react';
import {
    useCheckIn,
    useNavigation,
    useSpinner,
    useErrorHandling, useStore
} from '/src/context';
import { salesForceVisitReasonsEnum, visitReasonsOptions } from '/src/constants/visitReasons';
import { addFsDetails, fsEvents, logFs } from '/src/utils/fullstory';
import { Button } from '@kmx/legos-react-button';
import IOSteps from '../../flows/steps/IOSteps';
import { UNIFIED_CHECKIN_PAGE_OTHER_REASON_TEXT_INPUT_MAX_LENGTH } from '../../constants/inputValidationConstants';
import VisitReasons from '../../components/VisitReasons/VisitReasons';
import flows from '../../flows/flows';
import { sendToSalesforce } from '/src/api/sharedApiCalls';
import styles from './UnifiedAppointmentPage.module.scss';
import unifiedSteps from '../../flows/steps/unifiedSteps';
import { useIntl } from 'react-intl';
import { useEnterToMoveOn } from '/src/hooks/useEnterToMoveOn';
import useInitVisuals from '../../hooks/useInitVisuals';
import { isKioskUX } from '../../utils/kioskMode';
import trackEvent from "../../utils/adobe/trackEvent";
import { useAdobeTracking } from "../../utils/adobe/analytics";
import { UnifiedSteps } from '/src/flows/steps';
import {
    SalesForceVisitReasons
} from '/src/interfaces/SalesForceVisitReasons';
import { StoreType } from '/src/interfaces/StoreData';
import { configuration } from '/src/utils/configuration';

export default function UnifiedAppointmentPage() {
    const { formatMessage } = useIntl();
    const { setStep, setFlowAndStep } = useNavigation();
    const [checkInInfo, setCheckInInfo] = useCheckIn();
    const { hadSalesforceAppointment } = checkInInfo;
    const [, showSpinner] = useSpinner();
    const { setError } = useErrorHandling();
    const [store] = useStore();

    const [selectedVisitReasons, setSelectedVisitReasons] = useState(visitReasonsOptions);
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
    const [otherReasonTextInput, setOtherReasonTextInput] = useState('');

    const isOtherSelected =
        selectedVisitReasons &&
        selectedVisitReasons.find((r) => r.value === salesForceVisitReasonsEnum.OTHER)?.isChecked;

    useInitVisuals();

    useEffect(() => {
        // determine if user has already made a previous appointment type(s)
        // and if so, prepopulate the selection checkboxes
        if (hadSalesforceAppointment) {
            prePopulateVistReasonCheckBoxSelections(checkInInfo.appointmentTypes);
        }
    }, []);
    useAdobeTracking(UnifiedSteps.Appointment, { variant: hadSalesforceAppointment ? 0 : 1 });

    useEffect(() => {
        const didUserSelectAnyVisitReasonCheckBoxes =
            selectedVisitReasons && selectedVisitReasons.some((r) => r.isChecked);

        const otherReasonCheckBoxSelectedButReasonInputIsEmpty =
            selectedVisitReasons.find((reason) => reason.value === salesForceVisitReasonsEnum.OTHER)?.isChecked &&
            otherReasonTextInput === '';

        // TODO: fix this code-smell
        if (otherReasonTextInput !== '')
            selectedVisitReasons.find((r) => r.isDisplayElement === false).value = otherReasonTextInput as any;

        setIsNextButtonDisabled(
            !didUserSelectAnyVisitReasonCheckBoxes || otherReasonCheckBoxSelectedButReasonInputIsEmpty,
        );

        const selectedAppointmentTypes = selectedVisitReasons
            .filter((r) => r.isDisplayElement && r.isChecked && r.value != salesForceVisitReasonsEnum.OTHER)
            .map((r) => r.value);

        setCheckInInfo((prevState) => ({
            ...prevState,
            appointmentTypes: selectedAppointmentTypes,
            description: isOtherSelected ? otherReasonTextInput : '',
        }));
    }, [otherReasonTextInput, selectedVisitReasons]);

    const prePopulateVistReasonCheckBoxSelections = (appointmentTypes) => {
        if (appointmentTypes) {
            appointmentTypes?.forEach((appointmentType) => {
                setSelectedVisitReasons(reasons => reasons.map((r) =>
                    r.value !== appointmentType ? r : { ...r, isChecked: true },
                ));
            });
        }
    };

    const onSubmit = async () => {
        trackEvent.unified.appointment.click6(hadSalesforceAppointment);
        selectedVisitReasons.forEach(reason => {
            if (reason.isChecked) {
                reason.adobeTracker(hadSalesforceAppointment);
            }
        });
        addFsDetails({
            visitReason: selectedVisitReasons
                .filter(r => r.isChecked)
                .map(r => r.fullstoryName)
                .join(', '),
        });

        const hasSelectedOnlyRedeemOffer = selectedVisitReasons?.every(
            r => r.value === SalesForceVisitReasons.IcoAppraisalAppointment ? r.isChecked : !r.isChecked
        );
        const shouldGetOffer = store.storeFormat === StoreType.XF && selectedVisitReasons?.every(
            r => r.value === SalesForceVisitReasons.Appraisal ? r.isChecked : !r.isChecked
        );
        const hasSelectedBrowse = selectedVisitReasons?.find(r => r.value === SalesForceVisitReasons.Browse)?.isChecked;
        if (hasSelectedOnlyRedeemOffer) {
            logFs(fsEvents.unifiedLandingPage.redeemOfferCheckbox);
            setFlowAndStep(flows.InstantOffer, IOSteps.CheckInPage);
        } else if (shouldGetOffer) {
            logFs(fsEvents.unifiedAppointmentPage.goToGetOffer);
            // Currently we only "continue on this device"
            // setStep(unifiedSteps.UnifiedGetOfferPage);
            window.location.replace(configuration.sellMyCarUrl(store.id, store.zipCode));
        } else if (!hadSalesforceAppointment) {
            setStep(unifiedSteps.UnifiedCustomerInfoPage);
        } else if (hasSelectedBrowse) {
            logFs(fsEvents.unifiedAppointmentPage.hasSelectedBrowse);
            setStep(unifiedSteps.UnifiedCustomerInfoPage);
        } else {
            showSpinner(true);
            try {
                await sendToSalesforce(checkInInfo);
                setStep(unifiedSteps.UnifiedCompletePage);
            } catch (error) {
                setError.general('Failed sending check-in data to Salesforce', { error });
            }
        }
    };

    useEnterToMoveOn(
        onSubmit,
        [
            checkInInfo,
            setStep,
            setCheckInInfo,
            selectedVisitReasons,
            isNextButtonDisabled,
            setFlowAndStep,
        ],
        () => !isNextButtonDisabled,
    );

    const isKiosk = isKioskUX();
    const titleId =
        (!isKiosk && 'unified.checkInPage.title') ||
        (checkInInfo.hadSalesforceAppointment && 'unified.checkInPage.subtitle.kioskWithAppointment') ||
        'unified.checkInPage.subtitle';

    return (
        <div className={styles.container}>
            <div className={styles.heading}>
                <hzn-heading as="h4" size="medium">
                    {formatMessage({id: titleId })}
                </hzn-heading>
            </div>

            <VisitReasons
                title={formatMessage({ id: 'unified.checkInPage.subtitle' })}
                selectedVisitReasons={selectedVisitReasons}
                setSelectedVisitReasons={setSelectedVisitReasons}
                otherReasonTextInput={otherReasonTextInput}
                setOtherReasonTextInput={setOtherReasonTextInput}
                otherReasonTextInputMaxLength={UNIFIED_CHECKIN_PAGE_OTHER_REASON_TEXT_INPUT_MAX_LENGTH}
                otherReasonTextInputInputValidationErrorMessage={formatMessage({
                    id: 'unified.checkInPage.other.reason.validationErrorMessage',
                })}
                isOtherSelected={isOtherSelected}
            />

            <div className={styles.nextButtonContainer}>
                <Button
                    id="nextButton"
                    onClick={onSubmit}
                    disabled={isNextButtonDisabled}
                    className={styles.nextButton}
                    level="primary"
                >
                    {formatMessage({ id: 'unified.checkInPage.buttonLabel' })}
                </Button>
            </div>
        </div>
    );
}
