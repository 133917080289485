import React from 'react';
import PropTypes from 'prop-types';
import styles from './ExpressDropoffLogoImage.module.scss';

export default function ExpressDropoffLogoImage(props) {
    const {image} = props;

    return (
        <div className={styles.container}>
            <img src={image} className={styles.logoImage} />
        </div>
    );
}

ExpressDropoffLogoImage.propTypes = {
    image: PropTypes.any.isRequired
};
