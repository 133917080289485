import React, { useState } from 'react';
import CheckButton from '../CheckButton/CheckButton';

type OptionValue = string | number;
interface Option {
    value: OptionValue;
    label: string;
}

// a group of check-buttons where only one selection is allowed
export default function CheckButtonRadios({
    options,
    onChange,
    className,
    buttonClassName,
    defaultValue
}: {
    // keys are the actual option values, values are labels
    options: Option[];
    onChange: (key: OptionValue) => void;
    // allows adding more styles to the container
    className?: string;
    buttonClassName?: string;
    defaultValue?: OptionValue;
}) {
    const [activeOption, setActiveOption] = useState<OptionValue>(defaultValue);
    return (
        <div className={className}>
            {options.map(({ value, label }) => (
                <CheckButton
                    key={value}
                    label={label}
                    isChecked={value === activeOption}
                    onClick={() => {
                        if (value !== activeOption) {
                            setActiveOption(value);
                            onChange(value);
                        }
                    }}
                    className={buttonClassName}
                />
            ))}
        </div>
    );
}
