import React, {useContext, useEffect, useMemo, useState} from 'react';
import {
    Control,
    useController,
    UseFormClearErrors,
    UseFormSetValue,
    UseFormWatch
} from 'react-hook-form';
import { useAddressSuggestions } from '/src/hooks/useAddressSuggestions';
import { useSmartAddress } from '/src/hooks/useSmartAddress';
import AutoComplete from '../AutoComplete/AutoComplete';
import DropdownOption from '../../interfaces/DropdownOption';
import { IRules } from '/src/interfaces/form-input';
import { logFs } from '../../utils/fullstory';
import { fsEventNames } from '../../constants/fullstory';
import styles from './AddressDetails.module.scss';
import {OfferContext} from "/src/context";
import {useIntl} from "react-intl";

type AddressDetailsProps = {
    name: string;
    label: string;
    setAddressInfoIsValid: (valid: boolean) => void;
    control: Control<any>;
    setValue: UseFormSetValue<any>;
    clearErrors: UseFormClearErrors<any>;
    watch: UseFormWatch<any>;
    rules?: IRules;
};

export default function AddressDetails({ name, control, clearErrors, label, rules, setAddressInfoIsValid }: AddressDetailsProps) {
    const { setOffer } = useContext(OfferContext);
    const { field, fieldState } = useController({
        name,
        control,
        rules,
    });
    const { onChange, onBlur, value } = field;
    const { invalid, error } = fieldState;

    const { formatMessage } = useIntl();

    const searchingText = formatMessage({id: 'customerInfoPage.searchingText'});
    const noResultsText = formatMessage({id: 'customerInfoPage.noResultsText'});
    const selectAddressErrorText = formatMessage({id: 'customerInfoPage.selectAddressErrorText'});

    const [address, setAddress] = useState('');
    const [currentAddress, setCurrentAddress] = useState('');
    const [selectAddressError, setSelectAddressError] = useState('');

    const { addressSuggestions, addressSuggestionsError, isAddressSuggestionsFetching } = useAddressSuggestions(
        value,
        true,
    );
    const { smartAddress, smartAddressError, isSmartAddressFetching } = useSmartAddress(address);
    const hasCompleteSmartAddress = smartAddress.addressLine1 && smartAddress.city && smartAddress.state && smartAddress.zipCode;

    const setAddressFields = () => {
        const street = `${smartAddress?.addressLine1 ?? ''}`;
        const apartment = `${smartAddress?.addressLine2 ?? ''}`;
        const county = `${smartAddress?.county ?? ''}`;
        const cityStateZip = `${smartAddress?.city ?? ''}, ${smartAddress?.state ?? ''} ${smartAddress?.zipCode ?? ''}`;

        if (street && cityStateZip && !smartAddressError) {
            setAddressInfoIsValid(true);
            clearErrors('mailingAddress');
            setSelectAddressError('');
            setOffer(prevState => ({
                    ...prevState,
                    address: {
                        street,
                        cityStateZip
                    },
                    apartment,
                    county,
                })
            );
        }
    };

    useEffect(() => {
        setAddressFields();
    }, [smartAddress, smartAddressError]);

    useEffect(() => {
        setAddressInfoIsValid(!!(!error && currentAddress && hasCompleteSmartAddress));
    }, [error]);

    useEffect(() => {
        if (currentAddress && value !== currentAddress) {
            onChange('');
            setSelectAddressError('');
            clearErrors('mailingAddress');
            setCurrentAddress('');
            setAddress('');
            setAddressInfoIsValid(false);
        }
    }, [value]);

    useEffect(() => {
        if (smartAddressError) {
            setAddressInfoIsValid(false);
            logFs(fsEventNames.customerPage.mailingAddressNotFoundError);
        }
        if (addressSuggestionsError) {
            setAddressInfoIsValid(false);
        }
    }, [smartAddressError, addressSuggestionsError]);

    const getHelperText = () => {
        if (invalid) return error.message;
        if (addressSuggestionsError) return addressSuggestionsError;
        if (smartAddressError) return smartAddressError;
        if (selectAddressError) return selectAddressError;
        return null;
    };

    const onClickOutside = () => {
        if (value && !currentAddress) {
            setSelectAddressError(selectAddressErrorText)
            setAddressInfoIsValid(false);
        } else {
            setSelectAddressError('');
        }
    }

    return (
        <div>
            <div className={styles['address']}>
                <div className={styles['address-field']} data-cy="address-search-field">
                        <AutoComplete
                            autoComplete="off"
                            label={label}
                            id="address"
                            noResultsText={noResultsText}
                            searchingText={searchingText}
                            name={name}
                            value={value}
                            getOptionLabel={(option: DropdownOption) => option.label}
                            onChange={onChange}
                            onBlur={onBlur}
                            onSelection={(selection: DropdownOption) => {
                                onChange(selection.value);
                                setCurrentAddress(selection.value);
                                setAddress(selection.label);
                            }}
                            onClickOutside={onClickOutside}
                            helperText={getHelperText()}
                            options={addressSuggestions}
                            validationStatus={
                                (error && invalid) || smartAddressError || addressSuggestionsError || selectAddressError
                                    ? 'invalid'
                                    : undefined
                            }
                            loading={isAddressSuggestionsFetching || isSmartAddressFetching}
                        />
                </div>
            </div>
        </div>
    );
}
