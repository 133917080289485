import React from 'react';
import { FormattedMessage } from 'react-intl';
import pciCommonStyles from '../common/pciCommon.module.scss';
import useInitVisuals from '/src/hooks/useInitVisuals';
import { fsEvents, logFs } from '/src/utils/fullstory';
import useAppointmentAndStore from '/src/api/useAppointmentAndStore';
import VehicleCard from '/src/components/VehicleCard/VehicleCard';
import { useMediaQuery } from '/src/utils/mediaQueries/useMediaQueries';
import useAdobePci from '/src/flows/PreCheckIn/v2/common/hooks/useAdobePci';
import trackEvent from '/src/utils/adobe/trackEvent';
import Questions from '/src/flows/PreCheckIn/v2/common/Questions/Questions';
import AppointmentDetailsCard from '/src/flows/PreCheckIn/v2/common/AppointmentDetailsCard/AppointmentDetailsCard';
import { pciSteps, usePciSteps } from '/src/flows/PreCheckIn/pciSteps';
import IconCheck from '@kmx/legos-icons-essentials/assets/svg/check.svg';
import styles from './DirectionsPage.module.scss';
import PersonalItemsAlert from '/src/flows/PreCheckIn/v2/common/PersonalItemsAlert/PersonalItemsAlert';
import RescheduleLink from '/src/flows/PreCheckIn/v2/common/Reschedule/Reschedule';
import useFullstoryPci from '/src/flows/PreCheckIn/v2/common/hooks/useFullstoryPci';
import { postTripEvent } from '/src/api/sharedApiCalls';
import commonStyles from "/src/common.module.scss";

export default () => {
    const { goToNextStep } = usePciSteps();

    const { appointment, store, isFetchComplete, isDataInvalid } = useAppointmentAndStore();

    useInitVisuals(isFetchComplete);

    const { isDesktop } = useMediaQuery();

    useAdobePci(pciSteps.v2.directions.name);
    useFullstoryPci(fsEvents.pciDirections);

    const onClickContinue = () => {
        trackEvent.preCheckin.directions.click2();

        postTripEvent({
            appointmentId: appointment.appointmentId,
            storeNumber: store.id,
            ciamId: appointment.ciamId,
            hasCustomerArrived: false,
        }).catch(() => logFs(fsEvents.pciDirections.tripUpdateFailed));

        goToNextStep();
    };

    const shouldShowCta =
        appointment?.appointmentDatetime?.toDateString() === new Date().toDateString();

    return (
        isFetchComplete &&
        !isDataInvalid && (
            <div className={pciCommonStyles.pciPageLayout}>
                <hzn-card
                    border-radius="medium"
                    elevation={isDesktop ? 'none' : 'medium'}
                    padding="medium"
                >
                    <hzn-stack space="large" align-x="center">
                        <hzn-heading
                            as="h1"
                            style={{ width: isDesktop ? 'auto' : '90%' }}
                            size="large"
                            align="center"
                        >
                            <FormattedMessage id="pci.directions.heading" />
                        </hzn-heading>

                        {shouldShowCta && (
                            <>
                                <hzn-heading
                                    as="h2"
                                    size="xsmall"
                                    align={isDesktop ? 'center' : 'left'}
                                >
                                    <FormattedMessage id="pci.directions.ctaInfo" />
                                </hzn-heading>
                                <div onClick={onClickContinue} className={commonStyles.buttonContainer}>
                                    {/* note: null is not accidental, full-width works like in pure html attr */}
                                    <hzn-button full-width={isDesktop ? null : true}>
                                        <FormattedMessage id="pci.directions.cta" />
                                    </hzn-button>
                                </div>

                                <hzn-text size="small">
                                    <FormattedMessage id="pci.directions.actionFootNote" />
                                </hzn-text>
                            </>
                        )}

                        <hzn-card
                            border-radius="medium"
                            elevation={isDesktop ? 'flat' : 'none'}
                            padding={isDesktop ? 'large' : 'none'}
                        >
                            <hzn-columns space={isDesktop ? 'small' : 'none'} align-x="center">
                                {isDesktop && (
                                    <hzn-column>
                                        <VehicleCard {...appointment} />
                                    </hzn-column>
                                )}

                                <hzn-column>
                                    <AppointmentDetailsCard
                                        appointment={appointment}
                                        store={store}
                                        onClickAddress={trackEvent.preCheckin.directions.click6}
                                    >
                                        <hzn-stack align-x="center" space="medium">
                                            <div className={styles.confirmedLabel}>
                                                <IconCheck className={styles.confirmedIcon} />

                                                <hzn-text tone="inverse">
                                                    <FormattedMessage id="pci.directions.confirmed" />
                                                </hzn-text>
                                            </div>
                                            <RescheduleLink
                                                adobeTracker={
                                                    trackEvent.preCheckin.directions.click7
                                                }
                                                fullstoryPage={fsEvents.pciDirections}
                                            />
                                        </hzn-stack>
                                    </AppointmentDetailsCard>
                                </hzn-column>
                            </hzn-columns>
                        </hzn-card>

                        <PersonalItemsAlert />

                        <Questions
                            eventTrackers={{
                                faq: trackEvent.preCheckin.directions.click3,
                                phone: trackEvent.preCheckin.directions.click4,
                                docs: trackEvent.preCheckin.directions.click5,
                            }}
                        />
                    </hzn-stack>
                </hzn-card>
            </div>
        )
    );
};
