import { useLocalStorage } from 'usehooks-ts';
import { getAppointmentIdFromUrl } from '/src/utils/urlHelpers';

enum PciLocalStorageKeys {
    lastVisited = 'isxPciLastStep',
    hasCompleted = 'isxPciCompletedCheckIn',
}

// a repository-like layer to work with the local storage (from PCI PoV only)
const useLocalStoragePci = () => {
    const defaultAppointmentId = getAppointmentIdFromUrl();

    // last visited step
    const [lastVisited, setLastVisited] = useLocalStorage<Record<string, string>>(
        PciLocalStorageKeys.lastVisited,
        {},
    );
    const getLastVisitedStep = (appointmentId = defaultAppointmentId) => lastVisited[appointmentId];
    const setLastVisitedStep = (url: string, appointmentId = defaultAppointmentId) =>
        setLastVisited({ ...lastVisited, [appointmentId]: url });

    // has completed check-in
    const [hasCompleted, setHasCompleted] = useLocalStorage<Record<string, boolean>>(
        PciLocalStorageKeys.hasCompleted,
        {},
    );
    const getHasCompletedCheckIn = (appointmentId = defaultAppointmentId) => hasCompleted[appointmentId];
    const setHasCompletedCheckIn = (hasCompletedCheckIn: boolean, appointmentId = defaultAppointmentId) =>
        setHasCompleted({ ...hasCompleted, [appointmentId]: hasCompletedCheckIn });

    return {
        getLastVisitedStep,
        setLastVisitedStep,
        getHasCompletedCheckIn,
        setHasCompletedCheckIn,
    };
};
export default useLocalStoragePci;
