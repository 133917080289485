import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import App from './App.tsx';
import { initFsClient } from './utils/fullstory';
import { BrowserRouter as Router } from 'react-router-dom';
import { GlobalStateProvider } from './context';
import ErrorBoundary from './containers/ErrorPage/ErrorBoundary';
import './horizon';

initFsClient();

Modal.setAppElement('#root');

ReactDOM.render(
    <ErrorBoundary>
        <Router basename='/checkin'>
            <GlobalStateProvider>
                <App title="CarMax Check-In" />
            </GlobalStateProvider>
        </Router>
    </ErrorBoundary>,
    document.getElementById('root'),
);
