import React, { useState } from 'react';
import styles from './UnifiedDoYouHaveAppointment.module.scss';
import { useIntl } from 'react-intl';
import { useNavigation } from '/src/context';
import unifiedSteps from '../../flows/steps/unifiedSteps';
import { Button } from '@kmx/legos-react-button';
import { useAdobeTracking } from '../../utils/adobe/analytics';
import { useEnterToMoveOn } from '/src/hooks/useEnterToMoveOn';
import useInitVisuals from '../../hooks/useInitVisuals';
import CheckButtonRadios from '../../components/checkButtons/CheckButtonRadios';
import classNames from 'classnames';
import trackEvent from '/src/utils/adobe/trackEvent';
import { UnifiedSteps } from '/src/flows/steps';
import { addFsDetails } from '/src/utils/fullstory';

enum Answer {
    no = 'No',
    yes = 'Yes',
}

export default function UnifiedDoYouHaveAppointment() {
    const { formatMessage } = useIntl();
    const { setStep } = useNavigation();
    const [answer, setAnswer] = useState<Answer>();

    useInitVisuals();
    useAdobeTracking(UnifiedSteps.DoYouHaveAppointment);

    const isNextEnabled = answer !== undefined;

    const onClickNext = () => {
        const hasAppointment = answer === Answer.yes;

        addFsDetails({
            appointment: answer,
        });

        trackEvent.unified.doYouHaveAppointment.click2(hasAppointment);
        trackEvent.unified.doYouHaveAppointment.click3();
        setStep(hasAppointment ? unifiedSteps.UnifiedLandingPage : unifiedSteps.UnifiedAppointmentPage);
    };

    useEnterToMoveOn(onClickNext, [setStep, answer], () => isNextEnabled);

    return (
        <div className={styles.container}>
            <h4 className={classNames(styles.header, 'kmx-typography--display-4')}>
                {formatMessage({ id: 'common.welcome' })}
            </h4>
            <div className="kmx-typography--body-3 kmx-blue-900">
                {formatMessage({ id: 'unified.doYouHaveAppointment.question' })}
            </div>
            <CheckButtonRadios
                className={styles.checkButtonRadios}
                options={[
                    {
                        value: Answer.yes,
                        label: formatMessage({ id: 'common.yes' }),
                    },
                    {
                        value: Answer.no,
                        label: formatMessage({ id: 'common.no' }),
                    },
                ]}
                onChange={(val: Answer) => setAnswer(val)}
            />
            <Button
                className={classNames(styles.nextButton)}
                id="next-button"
                onClick={onClickNext}
                disabled={!isNextEnabled}
                level="primary"
            >
                {formatMessage({ id: 'common.next' })}
            </Button>
        </div>
    );
}
