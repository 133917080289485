import { useEffect } from 'react';
import useAppointmentAndStore from '/src/api/useAppointmentAndStore';
import { useHistory } from 'react-router-dom';
import { featureFlagNames } from '../constants/featureFlags';
import { pciV2StepArray } from '/src/flows/PreCheckIn/pciSteps';
import { getAppointmentIdFromUrl, getUrlFromPciPath } from '/src/utils/urlHelpers';
import { useLocalStoragePci } from '../flows/PreCheckIn/v2/common/hooks';

export const usePreCheckinRedirect = () => {
    const { features, hasFetchedFeatures } = useAppointmentAndStore(true);
    const history = useHistory();
    const { getLastVisitedStep } = useLocalStoragePci();

    useEffect(() => {
        if (hasFetchedFeatures && features[featureFlagNames.IsPreCheckinV2Enabled]) {
            history.push(
                getLastVisitedStep() ||
                getUrlFromPciPath(pciV2StepArray[0].path, getAppointmentIdFromUrl()),
            );
        }
    }, [hasFetchedFeatures, features]);
};
