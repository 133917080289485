import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import styles from './IOPicsyCompleteStorePage.module.scss';
import ExpressDropoffLogoImage from '../../components/ExpressDropoffImage/ExpressDropoffLogoImage';
import OfferCodeBox from '../../components/OfferCodeBox/OfferCodeBox';
import image from '../../assets/express-dropoff.png';
import { useIntl } from 'react-intl';
import { OfferContext } from '/src/context';
import { useSplashRedirection } from '../../hooks/useSplashRedirection';
import useInitVisuals from '../../hooks/useInitVisuals';
import { StoreType } from '/src/interfaces/StoreData';
import { fsEvents, logFs } from '/src/utils/fullstory';

interface Props {
    storeType: StoreType;
    reasonOfCompletion: 'alreadyVerified' | 'picsy'; // note: values in sync with fsEvents.ioQuickCompletion
}

export default function IOQuickCompletionPage({ storeType, reasonOfCompletion }: Props) {
    const { formatMessage } = useIntl();
    const { offer } = useContext(OfferContext);

    useSplashRedirection();
    useInitVisuals();
    useEffect(() => {
        logFs(fsEvents.ioQuickCompletion[reasonOfCompletion], { reasonOfCompletion });
    }, []);

    const title = formatMessage({
        id:
            storeType === StoreType.Traditional
                ? 'picsyCheckinPage.traditional.store.title'
                : 'picsyCheckinPage.xfs.store.title',
    });
    const subtitle = formatMessage({
        id:
            storeType === StoreType.Traditional
                ? 'picsyCheckinPage.traditional.store.subtitle'
                : 'picsyCheckinPage.xfs.store.subtitle',
    });

    return (
        <div id="offerCodeBox" className={styles.container}>
            <ExpressDropoffLogoImage image={image} />
            <h2 className={classNames('kmx-typography--display-3', styles.title)}>{title}</h2>
            <OfferCodeBox offerCode={offer?.code} />
            <h2 className={classNames('kmx-typography--body-2', styles.subtitle)}>
                <strong>{subtitle}</strong>
            </h2>
        </div>
    );
}
