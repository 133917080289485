import React, { useEffect, useRef } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { pciSteps } from '/src/flows/PreCheckIn/pciSteps';
import { useLocalStoragePci } from './v2/common/hooks';
import {
    getAppointmentIdFromUrl,
    getUrlFromPciPath,
    getPciStepFromUrl,
} from '/src/utils/urlHelpers';
import useAppointmentAndStore from '/src/api/useAppointmentAndStore';
import Welcome from '/src/flows/PreCheckIn/v2/Welcome/WelcomePage';
import ItemsToBring from '/src/flows/PreCheckIn/v2/ItemsToBring';
import AppointmentDetails from '/src/flows/PreCheckIn/v2/AppointmentDetails/AppointmentDetailsPage';
import Parking from '/src/flows/PreCheckIn/v2/Parking';
import Complete from '/src/flows/PreCheckIn/v2/Complete';
import Directions from '/src/flows/PreCheckIn/v2/Directions';
import MissedAppointment from '/src/flows/PreCheckIn/v2/MissedAppointment';
import PostPurchasePage from "/src/flows/PreCheckIn/v2/PostPurchase";

const PciRouting = () => {
    // save the current page as last visited
    const history = useHistory();
    const appointmentId = getAppointmentIdFromUrl();

    const currentPath = history.location.pathname;
    const { getHasCompletedCheckIn, setLastVisitedStep } = useLocalStoragePci();
    const hasCompletedCheckIn = useRef(getHasCompletedCheckIn()); // ref sticks with the initial read
    useEffect(() => {
        // only save steps that are part of the progression (not missed/post-check-in etc)
        const currentStep = getPciStepFromUrl();
        if (currentStep?.stepNumber) setLastVisitedStep(currentPath);
    }, [currentPath]);

    // make any redirections if necessary
    const { appointment, hasFetchedAppointment } = useAppointmentAndStore();
    useEffect(() => {
        if (!appointment) {
            return;
        }
        const hasCompletedCheckinBefore = hasCompletedCheckIn.current;
        const isVehicleSold = appointment?.isSold ?? false;
        let overridingStep;
        if (isVehicleSold) {
            overridingStep = pciSteps.v2.postPurchase;
        } else {
            if (hasCompletedCheckinBefore) {
                overridingStep = pciSteps.v2.postCheckIn;
            } else if (appointment.appointmentDatetime < new Date()) {
                overridingStep = pciSteps.v2.missed;
            }
        }
        if (overridingStep) {
            history.push(getUrlFromPciPath(overridingStep.path, appointmentId));
        }
    }, [appointment]);

    return (
        hasFetchedAppointment && (
            <Switch>
                <Route path={pciSteps.v2.welcome.path}>
                    <Welcome />
                </Route>
                <Route path={pciSteps.v2.items.path}>
                    <ItemsToBring />
                </Route>
                <Route path={pciSteps.v2.details.path}>
                    <AppointmentDetails />
                </Route>
                <Route path={pciSteps.v2.directions.path}>
                    <Directions />
                </Route>
                <Route path={pciSteps.v2.parking.path}>
                    <Parking />
                </Route>
                <Route path={pciSteps.v2.complete.path}>
                    <Complete isPostCheckIn={false} />
                </Route>
                <Route path={pciSteps.v2.postCheckIn.path}>
                    <Complete isPostCheckIn={true} />
                </Route>
                <Route path={pciSteps.v2.missed.path}>
                    <MissedAppointment />
                </Route>
                <Route path={pciSteps.v2.postPurchase.path}>
                    <PostPurchasePage />
                </Route>
            </Switch>
        )
    );
};
export default PciRouting;
