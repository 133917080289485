/** @deprecated Use fsEvents, fsAppName, fsOrgId */
export const fullstoryConstants = {
    orgId: '17KK5T',
    appName: 'Appraisal Self Check-In',
    activeOffersPage: {
        name: 'Choose Offer',
        events: {
            selectedOffer: 'Selected Offer',
            continueButton: 'Clicked Continue',
        },
    },
    checkInPage: {
        name: 'Find My Offer',
        events: {
            offerCodeToggle: 'Toggled to Offer Code',
            licensePlateToggle: 'Toggled to License Plate',
            offerCodeTextbox: 'Clicked into Offer Code Box',
            licensePlateTextbox: 'Clicked into License Plate Box',
            stateDropdown: 'Selected from State Dropdown',
            storeDropdown: 'Selected from Store Dropdown',
            findMyOfferButton: 'Clicked Find My Offer',
            noActiveOffersFound: 'No active offers found',
            isAppraisalCheckout: 'Appraisal Checkout workflow activated',
            onLoad: 'Navigated to IO Checkin Page',
        },
    },
    completePage: {
        name: 'Complete',
        events: {
            clickFaq1: 'Clicked FAQ 1',
            clickFaq2: 'Clicked FAQ 2',
            clickFaq3: 'Clicked FAQ 3',
        },
    },
    confirmOfferPage: {
        name: 'Confirm Offer',
        events: {
            acknowledgementCheckbox: 'Checked Acknowledgement Check Box',
            continueButton: 'Clicked Confirm',
        },
    },
    customerPage: {
        name: 'Customer Information',
        events: {
            mailingAddressNotFoundError: 'Mailing Address Not Found Error',
            apartmentDropdown: 'Selected from Apartment Dropdown',
            apartmentTextbox: 'Clicked into Apartment Box',
            submitButton: 'Clicked Submit',
        },
    },
    vehiclePage: {
        name: 'Vehicle Information',
        events: {
            exteriorColorDropdown: 'Selected from Exterior Color Dropdown',
            driveabilityChoice: 'Selected Safe to Drive Choice',
            intentionToSellChoice: 'Selected Planning to Sell Choice',
            paymentsChoice: 'Selected Vehicle Payment Choice',
            tradeInChoice: 'Selected Vehicle Trade-In Choice',
            submitButton: 'Clicked Submit',
        },
    },
    landingPage: {
        name: 'Landing Page',
        events: {
            getStartedButton: 'Clicked Get Started',
        },
    },
    errorPage: {
        name: 'Error Page',
        events: {
            errorPageLoaded: 'Directed to Error Page',
            clickedReturnButton: 'Clicked Return to Check In',
        },
    },
    unifiedLandingPage: {
        name: 'Unified Landing Page',
        events: {
            nextButton: 'Clicked Next',
            redeemOfferCheckbox: 'Clicked Redeem Offer',
            landingPage: 'Navigated to Unified Landing page',
        },
    },
};

export const fsOrgId = fullstoryConstants.orgId;
export const fsAppName = fullstoryConstants.appName;

export type FullstoryPageSpecs = {
    _pageName: string;
    [eventKey: string]: string;
};

// create events object for PCI pages (they're very much alike)
export type FullstoryPciPageSpecs = FullstoryPageSpecs & { pageView: string, reschedule: string }
const getPciEvents = (
    pageName: string,
    customEvents?: Record<string, string>,
): FullstoryPciPageSpecs => ({
    _pageName: pageName,
    pageView: 'Page Load',
    reschedule: 'Clicked Reschedule/Cancel',
    ...customEvents,
});

// note: this object is generated with `fsEventsConvertedFromFsConstants` (see comments below)
// It should eventually be the single source of truth, replacing fullstoryConstants
const eventsShortNames = {
    activeOffersPage: {
        _pageName: 'Choose Offer',
        selectedOffer: 'Selected Offer',
        continueButton: 'Clicked Continue',
    },
    checkInPage: {
        _pageName: 'Find My Offer',
        offerCodeToggle: 'Toggled to Offer Code',
        licensePlateToggle: 'Toggled to License Plate',
        offerCodeTextbox: 'Clicked into Offer Code Box',
        licensePlateTextbox: 'Clicked into License Plate Box',
        stateDropdown: 'Selected from State Dropdown',
        storeDropdown: 'Selected from Store Dropdown',
        findMyOfferButton: 'Clicked Find My Offer',
        noActiveOffersFound: 'No active offers found',
        isAppraisalCheckout: 'Appraisal Checkout workflow activated',
        onLoad: 'Navigated to IO Checkin Page',
    },
    completePage: {
        _pageName: 'Complete',
        clickFaq1: 'Clicked FAQ 1',
        clickFaq2: 'Clicked FAQ 2',
        clickFaq3: 'Clicked FAQ 3',
    },
    confirmOfferPage: {
        _pageName: 'Confirm Offer',
        acknowledgementCheckbox: 'Checked Acknowledgement Check Box',
        continueButton: 'Clicked Confirm',
    },
    customerPage: {
        _pageName: 'Customer Information',
        mailingAddressNotFoundError: 'Mailing Address Not Found Error',
        apartmentDropdown: 'Selected from Apartment Dropdown',
        apartmentTextbox: 'Clicked into Apartment Box',
        submitButton: 'Clicked Submit',
    },
    vehiclePage: {
        _pageName: 'Vehicle Information',
        exteriorColorDropdown: 'Selected from Exterior Color Dropdown',
        driveabilityChoice: 'Selected Safe to Drive Choice',
        intentionToSellChoice: 'Selected Planning to Sell Choice',
        paymentsChoice: 'Selected Vehicle Payment Choice',
        tradeInChoice: 'Selected Vehicle Trade-In Choice',
        submitButton: 'Clicked Submit',
        colorNotSelected: 'Color not selected',
        colorSelectedAfterOthers: 'Color selected after other fields',
    },
    landingPage: {
        _pageName: 'Landing Page',
        getStartedButton: 'Clicked Get Started',
    },
    ioQuickCompletion: {
        _pageName: 'Complete',
        picsy: 'Picsy',
        alreadyVerified: 'Already Verified',
    },
    errorPage: {
        _pageName: 'Error Page',
        errorPageLoaded: 'Directed to Error Page',
        clickedReturnButton: 'Clicked Return to Check In',
    },
    unifiedLandingPage: {
        _pageName: 'Unified Landing Page',
        nextButton: 'Clicked Next',
        redeemOfferCheckbox: 'Clicked Redeem Offer',
        landingPage: 'Navigated to Unified Landing page',
    },
    unifiedSplashPage: {
        _pageName: 'Kiosk Splash Page',
        getStarted: 'Clicked Get Started',
    },
    unifiedCompletePage: {
        _pageName: 'Unified Complete Page',
        pageView: 'Page Load',
    },
    unifiedAppointmentPage: {
        _pageName: 'Unified Appointment Page',
        hasSelectedBrowse: 'Selected Browse',
        goToGetOffer: 'Going to Get Offer',
    },
    pciWelcome: getPciEvents('PCI Welcome Page'),
    pciItemsToBring: getPciEvents('PCI Items to Bring Page'),
    pciDetails: getPciEvents('PCI Details Page'),
    pciDirections: getPciEvents('PCI Directions Page', {
        tripUpdateFailed: 'Failed posting trip update',
    }),
    pciParking: getPciEvents('PCI Parking Page', {
        tripUpdateFailed: 'Failed posting trip update',
    }),
    pciComplete: getPciEvents('PCI Complete Page'),
    pciPostCheckIn: getPciEvents('PCI Post-Check-In Page'),
    pciMissed: getPciEvents('PCI Missed Appointment Page'),
    pciPostPurchase: getPciEvents('PCI Post Purchase Page'),
    info: {
        _pageName: 'Info',
        requestCancelled: 'API Request Failed (Cancelled?)',
        requestRetried: 'API Request Retried',
        invalidUrlArg: 'Invalid URL Argument',
        requestRetriesExhausted: 'API Request Retries Exhausted',
    },
};

// Event names, complete (page name + event name)
export const fsEventNames = new Proxy(eventsShortNames, {
    get(target, prop) {
        return Object.entries(target[prop])
            .map(([eventKey, shortEventName]) => [
                eventKey,
                `${target[prop]._pageName} - ${shortEventName}`,
            ])
            .reduce(
                (acc, [eventKey, completeEventName]) =>
                    eventKey === '_pageName' // remove _pageName from the end result
                        ? acc
                        : {
                              ...acc,
                              [eventKey]: completeEventName,
                          },
                {},
            );
    },
});

/*
// This logic digests fullstoryConstants, producing eventsShortNames.
// It's left here in case it's needed again (before fullstoryConstants disappears).
// Using it directly confuses the IDE, unfortunately, and autocomplete is lost.
// So, for autocomplete's sake, I've pasted the result of fsEventsConvertedFromFsConstants
// into constant `eventsShortNames` and used a Proxy that concatenates pageName and eventName

const fsEventsConvertedFromFsConstants = Object.entries(fullstoryConstants)
    .filter(([, value]) => value.name && value.events) // select only "page" objects
    .map(([pageKey, pageVal]) => [
        pageKey,
        Object.entries(pageVal.events)
            //.map(([eventKey, shortEventName]) => [eventKey, `${pageVal.name} - ${shortEventName}`])
            .reduce(
                (acc, [eventKey, completeEventName]) => ({
                    ...acc,
                    [eventKey]: completeEventName,
                }),
                { _pageName: pageVal.name },
            ),
    ])
    .reduce(
        (acc, [pageKey, events]) => ({
            ...acc,
            [pageKey]: events,
        }),
        {},
    );
*/
