// window._satellite.track is created by the "launch" script that's loaded async by App
// if the call is made  before the script is loaded, wait and retry (every 100ms, for a max of 2s by default)
const makeDirectCall = (callName, numberOfRetries = 20) => {
    if (window.location.hostname === 'localhost') {
        console.info('Adobe event', window.digitalData.eventVars);
        return;
    }

    if (window._satellite?.track) {
        window._satellite.track(callName);
    } else if (numberOfRetries > 0) {
        setTimeout(() => makeDirectCall(callName, numberOfRetries - 1), 100);
    }
    // else: out of retries, do nothing
}
export default makeDirectCall;
