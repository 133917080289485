import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './ValidatedSelect.module.scss';

import { Select } from '@kmx/legos-react-select';
import classNames from 'classnames';

export default function ValidatedSelect(props) {
    const [isTouched, setIsTouched] = useState(false);
    const controlTouched = () => {
        setIsTouched(true);
    };
    return (
        <div>
            <Select {...props} onBlur={controlTouched}/>
            {
                (isTouched && !props.value) ?
                    <div id="invalid-helper-text" className={classNames(styles.helperText, styles.invalid)}>{props.dropdownErrorMessage}</div>
                    :
                    props.displayHelperText && !props.value && <div id="helper-text" className={styles.helperText}>{props.dropdownErrorMessage}</div>
            }
        </div>
    );
}

ValidatedSelect.propTypes = {
    dropdownErrorMessage: PropTypes.string.isRequired,
    displayHelperText: PropTypes.bool,
    value: PropTypes.string.isRequired
};
