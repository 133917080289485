import { FullstoryPciPageSpecs } from "/src/constants/fullstory";
import { useEffect } from "react";
import { logFs, setFsDetails } from "/src/utils/fullstory";
import useAppointmentAndStore from "/src/api/useAppointmentAndStore";

const useFullstoryPci = (pageSpecs: FullstoryPciPageSpecs) => {
    const { store } = useAppointmentAndStore();
    useEffect(() => {
        if (store?.id) {
            setFsDetails({
                acquisitionChannel: 'SMS', // TODO: remote/store
                storeNumber: store?.id,
                appointmentType: 'EPU',
                storeType: store?.storeFormat,
                appointment: 'Yes',
            });
            logFs(pageSpecs.pageView);
        }
    }, [store]);
}
export default useFullstoryPci;
