import React from 'react';
import { FormattedMessage } from 'react-intl';
import { pciSteps } from '/src/flows/PreCheckIn/pciSteps';
import useInitVisuals from '/src/hooks/useInitVisuals';
import { fsEvents } from '/src/utils/fullstory';
import useAppointmentAndStore from '/src/api/useAppointmentAndStore';
import VehicleCard from '/src/components/VehicleCard/VehicleCard';
import { useMediaQuery } from '/src/utils/mediaQueries/useMediaQueries';
import useAdobePci from '/src/flows/PreCheckIn/v2/common/hooks/useAdobePci';
import trackEvent from '/src/utils/adobe/trackEvent';
import Questions from '/src/flows/PreCheckIn/v2/common/Questions';
import styles from './PostPurchasePage.module.scss';
import pciCommonStyles from '../common/pciCommon.module.scss';
import useFullstoryPci from '/src/flows/PreCheckIn/v2/common/hooks/useFullstoryPci';

export default () => {
    const { appointment, isFetchComplete, isDataInvalid, hasFetchedAppointment } =
        useAppointmentAndStore();

    useInitVisuals(hasFetchedAppointment);

    const { isDesktop } = useMediaQuery();
    const defaultAlignment = isDesktop ? 'center' : 'left';

    useAdobePci(pciSteps.v2.postPurchase.name);
    useFullstoryPci(fsEvents.pciPostPurchase);

    return (
        isFetchComplete &&
        !isDataInvalid && (
            <div className={pciCommonStyles.pciPageLayout}>
                <hzn-card
                    class={styles.main}
                    border-radius="medium"
                    elevation={isDesktop ? 'none' : 'flat'}
                    padding="medium"
                >
                    <hzn-stack space="large" align-x="center">
                        <hzn-heading as="h1" size="large" align="center">
                            <FormattedMessage id="pci.postPurchase.heading" />
                            {/* show first name if available: */}
                            {appointment.customerName &&
                                `, ${appointment.customerName.split(' ')[0]}`}
                            !
                        </hzn-heading>
                        <hzn-text>
                            <FormattedMessage id="pci.welcome.intro" /> {appointment.year}{' '}
                            {appointment.make} {appointment.model}
                        </hzn-text>

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <VehicleCard {...appointment} />
                        </div>

                        <hzn-heading size="medium" align={defaultAlignment}>
                            <FormattedMessage id="pci.postPurchase.servicesHeading" />
                        </hzn-heading>

                        <hzn-stack align-x={defaultAlignment}>
                            <hzn-text tone="info" weight="bold" style={{ fontSize: 20 }}>
                                MaxCare
                            </hzn-text>
                            <hzn-text align={defaultAlignment}>
                                <FormattedMessage id="pci.postPurchase.maxCareInfo" />
                            </hzn-text>
                            <hzn-text-link href="https://www.carmax.com/car-buying-process/maxcare-service-plans">
                                <FormattedMessage id="pci.postPurchase.learnMore" />
                            </hzn-text-link>
                        </hzn-stack>

                        <Questions
                            eventTrackers={{
                                faq: trackEvent.preCheckin.welcome.click3,
                                phone: trackEvent.preCheckin.welcome.click4,
                                docs: trackEvent.preCheckin.welcome.click5,
                            }}
                        />
                    </hzn-stack>
                </hzn-card>
            </div>
        )
    );
};
