import { useState } from 'react';
import { getAddressSuggestions } from '../api/sharedApiCalls';
import { useDebounce } from './useDebounce';
import AddressSuggestion from '../interfaces/AddressSuggestion';
import DropdownOption from '../interfaces/DropdownOption';
import {useIntl} from "react-intl";

export const useAddressSuggestions = (searchTerm: string, useSuggestions: boolean) => {
    const [addressSuggestions, setAddressSuggestions] = useState<DropdownOption[]>([]);
    const [addressSuggestionsError, setAddressSuggestionsError] = useState<string>('');
    const [isAddressSuggestionsFetching, setIsAddressSuggestionsFetching] = useState(false);

    const { formatMessage } = useIntl();
    const addressNoneFoundErrorMessage = formatMessage({id: 'customerInfoPage.addressNoneFoundErrorMessage'});
    const searchErrorText = formatMessage({id: 'useSmartAddress.searchError'});

    const handleGetAddressSuggestions = async () => {
        setIsAddressSuggestionsFetching(true);

        try {
            const addressSuggestions = await getAddressSuggestions(searchTerm);
            if (addressSuggestions.length > 0) {
                setAddressSuggestions(
                    addressSuggestions.map((suggestion: AddressSuggestion) => {
                        const street = `${suggestion?.street ?? ''} ${suggestion?.secondary ?? ''}`;
                        const cityStateZip = `${suggestion?.city ?? ''}, ${suggestion?.state ?? ''} ${suggestion?.zipCode ?? ''}`;
                        const value = `${street}\n${cityStateZip}`;
                        const label = `${street} ${cityStateZip}`;
                        return { value: value, label: label, street: street, cityStateZip: cityStateZip};
                    })
                );
                setAddressSuggestionsError('');
            } else {
                setAddressSuggestions([]);
                setAddressSuggestionsError(addressNoneFoundErrorMessage);
            }
        } catch {
            setAddressSuggestions([]);
            setAddressSuggestionsError(searchErrorText);
        }
    };
    useDebounce(
        () => {
            if (searchTerm.trim() && useSuggestions) {
                setIsAddressSuggestionsFetching(true);
                handleGetAddressSuggestions().finally(() => setIsAddressSuggestionsFetching(false));
            } else {
                setAddressSuggestions([]);
                setIsAddressSuggestionsFetching(false);
            }
        },
        [searchTerm],
        300
    );

    return { addressSuggestions, addressSuggestionsError, isAddressSuggestionsFetching };
};
