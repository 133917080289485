import React, {
    Context,
    Provider,
    createContext,
    Dispatch,
    ReactChildren,
    useContext,
    useState, SetStateAction,
} from 'react';
import { noop } from '../constants/general';

export type StatefulContextValue<T> = [T, Dispatch<SetStateAction<T>>];

function createStatefulContext<T>(
    // the initial state of this context
    defaultValue: T,
    // display name for the context provider (debug ftw)
    displayName?: string,
): [
    Provider<StatefulContextValue<T>>,
    () => StatefulContextValue<T>,
    Context<StatefulContextValue<T>>,
] {
    const Context = createContext<StatefulContextValue<T>>([defaultValue, noop as Dispatch<T>]);

    const ContextProvider = ({ children }: { children: ReactChildren }) => {
        const [value, setValue] = useState(defaultValue);
        return <Context.Provider value={[value, setValue]} children={children} />;
    };
    if (displayName) {
        ContextProvider.displayName = `${displayName}Provider`;
    }

    const hook = () => {
        const contextValue = useContext(Context);
        return contextValue;
    };

    return [ContextProvider as unknown as Provider<StatefulContextValue<T>>, hook, Context];
}

export default createStatefulContext;
