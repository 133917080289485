import React from 'react';
import PropTypes from 'prop-types';
import styles from './OfferCodeBox.module.scss';
import { useIntl } from 'react-intl';
export default function OfferCodeBox(props) {
    const {offerCode} = props;
    const { formatMessage } = useIntl();

    return (
        <div className={styles.container}>
            <div className={"kmx-typography--display-2"} style={{"color": "white", "marginTop": "1.5rem", "fontWeight": "bold"}}>
                {formatMessage({id: 'confirmOfferPage.offerCard.offerCode'})}
            </div>
            <div className={"kmx-typography--display-4"} style={{"color": "white", "marginTop": ".5rem"}}>
                {offerCode}
            </div>
        </div>
    );
}

OfferCodeBox.propTypes = {
    offerCode: PropTypes.string.isRequired
};

