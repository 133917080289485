import React, { useEffect } from 'react';
import unifiedSteps from '../flows/steps/unifiedSteps';
import UnifiedLandingPage from '../containers/UnifiedLandingPage/UnifiedLandingPage';
import UnifiedAppointmentPage from '../containers/UnifiedAppointmentPage/UnifiedAppointmentPage';
import UnifiedCustomerInfoPage from '../containers/UnifiedCustomerInfoPage/UnifiedCustomerInfoPage';
import UnifiedCompletePage from '../containers/UnifiedCompletePage/UnifiedCompletePage';
import UnifiedSplashPage from '../containers/UnifiedSplashPage/UnifiedSplashPage';
import { useNavigation, useStore } from '../context';
import UnifiedDoYouHaveAppointment from '../containers/UnifiedDoYouHaveAppointment';
import { setFsDetails } from '/src/utils/fullstory';
import { isKioskUX } from '/src/utils/kioskMode';
import UnifiedGetOffer from '/src/containers/UnifiedGetOffer';

export default function Unified() {
    const { step } = useNavigation();

    const [store] = useStore();
    useEffect(() => {
        setFsDetails({
            acquisitionChannel: isKioskUX() ? 'Kiosk' : 'Sign',
            storeNumber: store?.id,
            storeType: store?.storeFormat,
        });
    }, []);

    switch (step) {
        case unifiedSteps.UnifiedSplashPage:
            return <UnifiedSplashPage />;
        case unifiedSteps.DoYouHaveAppointment:
            return <UnifiedDoYouHaveAppointment />;
        case unifiedSteps.UnifiedLandingPage:
            return <UnifiedLandingPage />;
        case unifiedSteps.UnifiedAppointmentPage:
            return <UnifiedAppointmentPage />;
        case unifiedSteps.UnifiedCustomerInfoPage:
            return <UnifiedCustomerInfoPage />;
        case unifiedSteps.UnifiedGetOfferPage:
            return <UnifiedGetOffer />;
        case unifiedSteps.UnifiedCompletePage:
            return <UnifiedCompletePage />;
        default:
            return null;
    }
}
