import React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import Modal from 'react-modal';
import IconClose from '@kmx/legos-icons-essentials/assets/svg/close.svg';
import { FormattedMessageWithLink } from '/src/utils/intlHelpers';
import { ExternalRoutes } from '/src/constants/env';
import { useMediaQuery } from '/src/utils/mediaQueries/useMediaQueries';
import styles from './Reschedule.module.scss';
import commonStyles from '/src/common.module.scss';
import useAppointmentAndStore from '/src/api/useAppointmentAndStore';
import { formatPhoneNumber } from '/src/utils';
import { logFs } from '/src/utils/fullstory';

interface Props {
    adobeTracker: () => void;
    fullstoryPage: { reschedule: string };
}

const RescheduleLink = ({ adobeTracker, fullstoryPage }: Props) => {
    const { store } = useAppointmentAndStore();
    const { isDesktop } = useMediaQuery();

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const closeModal = () => setIsOpen(false);
    const onClickOpen = () => {
        setIsOpen(true);
        adobeTracker();
        logFs(fullstoryPage.reschedule);
    };

    return (
        (store?.phoneLocal ?? null) && (
            <div>
                <hzn-text-link href="javascript:" onClick={onClickOpen} align="center">
                    <FormattedMessage id="pci.reschedule.trigger" />
                </hzn-text-link>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    className={cx(styles.dialog, commonStyles.modalWindow)}
                    overlayClassName={commonStyles.modalOverlay}
                >
                    <IconClose onClick={closeModal} className={styles.iconClose} />
                    <hzn-stack space="large">
                        <hzn-heading>
                            <FormattedMessage id="pci.reschedule.heading" />
                        </hzn-heading>
                        {isDesktop && (
                            <hzn-text tone="subdued">
                                <FormattedMessageWithLink
                                    id="preCheckIn.questions.faq"
                                    url={`${ExternalRoutes.Www}/buying-online#buyonlinefaq`}
                                />
                            </hzn-text>
                        )}
                        <hzn-text tone="subdued">
                            <FormattedMessageWithLink
                                id={
                                    isDesktop
                                        ? 'preCheckIn.questions.phone'
                                        : 'pci.reschedule.callUs'
                                }
                                url={`tel:${store.phoneLocal}`}
                                linkText={formatPhoneNumber(store.phoneLocal)}
                            />
                        </hzn-text>
                    </hzn-stack>
                </Modal>
            </div>
        )
    );
};
export default RescheduleLink;
