import React, { useEffect } from 'react';
import styles from './ErrorPage.module.scss';
import { buildEventName, logFsEvent } from '../../utils/fullstory';
import { fullstoryConstants } from '../../constants/fullstory';
import { useIntl } from 'react-intl';
import ErrorPageHeader from '../../components/ErrorPageHeader/ErrorPageHeader';
import ErrorIcon from '../../assets/triangle-error.png';
import SadCarIcon from '../../assets/car-sad.png';
import { Button } from '@kmx/legos-react-button';
import ExclamationImage from '../../assets/store_closed_exclamation.png';
import StoreHoursCardContainer from '../../components/StoreHours/StoreHoursCardContainer';
import { useEnterToMoveOn } from '/src/hooks/useEnterToMoveOn';
import { useStore } from '/src/context';
import useInitVisuals from '../../hooks/useInitVisuals';
import { useErrorHandling } from '/src/context/errorHandling';
import { ErrorTypes } from '/src/interfaces/errors';
import { StoreStatus } from '/src/interfaces/StoreData';
import { getFromUrl } from '/src/utils/urlHelpers';

export default function ErrorPage({ type: typeFromProps }: { type?: ErrorTypes }) {
    const { formatMessage } = useIntl();
    const { error } = useErrorHandling();
    const [store] = useStore();
    const type = error || typeFromProps;
    const canRedirectToCheckIn = Boolean(getFromUrl.validatedStore());

    const storeHoursIsValid = Boolean(store?.hoursToday?.startHour && store?.hoursToday?.endHour);

    useInitVisuals();

    const onClick = () => {
        logFsEvent(
            buildEventName(
                fullstoryConstants.errorPage.name,
                fullstoryConstants.errorPage.events.clickedReturnButton,
            ),
        );
        document.location.reload();
    };

    const getErrorImage = (type) => (type === ErrorTypes.checkInDisabled ? SadCarIcon : ErrorIcon);

    const storeHoursClosingSoonSectionDivContainer = (
        <div test-id="store-hours-container">
            <StoreHoursCardContainer
                storeHours={store}
                storeHoursLabel={formatMessage({ id: `storeHoursPage.storeHoursLabel` })}
                openTodayLabel={formatMessage({ id: `storeHoursPage.openTodayLabel` })}
                message={formatMessage({ id: `errorPage.checkInDisabled.storeClosingSoonMessage` })}
                icon={ExclamationImage}
            />
        </div>
    );

    const storeHoursClosedSectionDivContainer = (
        <div test-id="store-hours-container">
            <StoreHoursCardContainer
                storeHours={store}
                storeHoursLabel={formatMessage({ id: `storeHoursPage.storeHoursLabel` })}
                openTodayLabel={formatMessage({ id: `storeHoursPage.openTodayLabel` })}
                message={formatMessage({ id: `errorPage.checkInDisabled.storeClosedMessage` })}
                icon={ExclamationImage}
            />
        </div>
    );

    const storeHoursOpenSectionDivContainer = (
        <div test-id="store-hours-container">
            <StoreHoursCardContainer
                storeHours={store}
                storeHoursLabel={formatMessage({ id: `storeHoursPage.storeHoursLabel` })}
                openTodayLabel={formatMessage({ id: `storeHoursPage.openTodayLabel` })}
            />
        </div>
    );

    const returnToCheckInButtonDivContainer = !canRedirectToCheckIn ? null : (
        <div test-id="error-button-container">
            {/* do NOT change button's ID, it's used in Adobe */}
            <Button
                id="error-page--return-button"
                className={styles.button}
                level="primary"
                onClick={onClick}
                type="button"
            >
                {formatMessage({ id: 'errorPage.buttonTitle' })}
            </Button>
        </div>
    );

    useEnterToMoveOn(onClick, [], () => canRedirectToCheckIn);

    return (
        <div className={styles.container}>
            <div>
                <ErrorPageHeader
                    title={formatMessage({ id: `errorPage.${type || ErrorTypes.general}.title` })}
                    subtitle={formatMessage({
                        id: `errorPage.${
                            type && type !== ErrorTypes.notFound ? type : ErrorTypes.general
                        }.subtitle`,
                    })}
                    image={getErrorImage(type)}
                />
            </div>
            {type === ErrorTypes.checkInDisabled && storeHoursIsValid
                ? store.status === StoreStatus.Closed
                    ? storeHoursClosedSectionDivContainer
                    : store.status === StoreStatus.ClosingSoon
                    ? storeHoursClosingSoonSectionDivContainer
                    : storeHoursOpenSectionDivContainer
                : null}

            {type === ErrorTypes.general || type === ErrorTypes.notFound
                ? returnToCheckInButtonDivContainer
                : null}
        </div>
    );
}
