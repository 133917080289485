import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from  './IOCustomerInfoPage.module.scss';
import { useIntl } from 'react-intl';
import { Steps } from '@kmx/acquisitions-steps';
import { logFsEvent, buildEventName } from '../../utils/fullstory';
import { fullstoryConstants } from '../../constants/fullstory';
import IOSteps from '../../flows/steps/IOSteps';
import { Button } from '@kmx/legos-react-button';
import { useNavigation, useCheckIn, OfferContext } from '../../context';
import { findSalesforceAppointmentInformation } from '../../api/sharedApiCalls';
import { salesForceVisitReasonsEnum } from '../../constants/visitReasons.tsx';
import CustomerInfo from '../../components/CustomerInfo/CustomerInfo';
import { useErrorHandling } from "../../context";
import useInitVisuals from "../../hooks/useInitVisuals";

export default function IOCustomerInfoPage() {
    const { formatMessage } = useIntl();

    const { setStep } = useNavigation();
    const { offer } = useContext(OfferContext);
    const [checkInInfo, setCheckInInfo] = useCheckIn();
    const { setError } = useErrorHandling();

    const [submitDisabled, setSubmitDisabled] = useState(true);

    const [customerInfoIsValid, setCustomerInfoIsValid] = useState(false);

    useEffect(() => {
        setSubmitDisabled(!(customerInfoIsValid));
    }, [customerInfoIsValid]);

    useInitVisuals();

    const stepDefinition =
    [
        {
            id: 'customer-info',
            className: 'customer-info-step',
            header: <div id="customerStepHeader">{formatMessage({id: 'customerInfoPage.customerStepLabel'})}</div>,
            body: <CustomerInfo
                setIsValid={setCustomerInfoIsValid} />,
            autoProceedOnComplete: false
        }
    ];

    const onSubmit = async () => {
        logFsEvent(buildEventName(fullstoryConstants.customerPage.name,
            fullstoryConstants.customerPage.events.submitButton));

        if (!checkInInfo.appointmentTypes) {
            findSalesforceAppointmentInformation(offer.phoneNumber, offer.storeNumber)
                .then((appointmentInfo) => {
                    if (appointmentInfo.appointmentTypes.indexOf(salesForceVisitReasonsEnum.ICO_APPRAISAL_APPOINTMENT) === -1)
                        appointmentInfo.appointmentTypes.push(salesForceVisitReasonsEnum.ICO_APPRAISAL_APPOINTMENT);

                    Object.assign(checkInInfo, appointmentInfo);
                    setCheckInInfo(prevState => ({...prevState, ...checkInInfo}));
                })
                .catch((error) => {
                    setError.general('Failed fetching appointment', {
                        error,
                        additionalInfo: { offer },
                    });
                });
        }

        setStep(IOSteps.VehicleInfoPage);
    };

    return (
        <div className={styles.container}>
            <h4 className={classNames("kmx-typography--display-4", styles.header)}>{formatMessage({id: 'customerInfoPage.header'})}</h4>
            <div className={styles.steps}>
                <Steps stepDefinition={stepDefinition} hostControlledStepExpansion={true}/>
            </div>
            {/* do NOT change button's ID, it's used in Adobe */}
            <Button
                id="customer-info--submit"
                className={styles.primary}
                level="primary"
                disabled={submitDisabled}
                onClick={onSubmit}
                type='button'>
                {formatMessage({id: 'customerInfoPage.submitButton'})}
            </Button>
        </div>
    );
}

