import React from 'react';
import classNames from 'classnames';
import styles from './IOLandingPage.module.scss';
import { useIntl } from 'react-intl';
import { logFs, fsEvents } from "../../utils/fullstory";
import { useFeatures, useNavigation } from '../../context';
import IOSteps from '../../flows/steps/IOSteps';
import { Button } from '@kmx/legos-react-button';
import dogImg from '../../assets/ManWithDog.png';
import { featureFlagNames } from '../../constants/featureFlags';
import { useErrorHandling } from "../../context";
import useInitVisuals from "../../hooks/useInitVisuals";

export default function IOLandingPage() {
    const { formatMessage } = useIntl();
    const { setStep } = useNavigation();
    const [features] = useFeatures();
    const { setError } = useErrorHandling();

    useInitVisuals();

    const onClick = () => {
        if (!features[featureFlagNames.IsSelfCheckInEnabled]) {
            setError.checkInDisabled('Self-check-in not enabled');
        } else {
            setStep(IOSteps.CheckInPage);
        }

        logFs(fsEvents.landingPage.getStartedButton);
    };

    return (
        <div className={styles.container}>
            <img className={styles.image} src={dogImg} />
            <h4 className={classNames(styles.header, "kmx-typography--display-5")}>{formatMessage({id: 'landingPage.header'})}</h4>
            <p className={classNames(styles.subtitle, "kmx-typography--body-3")}>{formatMessage({id: 'landingPage.subtitle'})}</p>
            <Button
                className={styles.button}
                level="primary"
                id='get-started-button'
                onClick={() => onClick()}>
                {formatMessage({id: 'landingPage.button'})}
            </Button>
        </div>
    );
}
