import Item, { PaymentItem, TitleItem } from '/src/flows/PreCheckIn/v2/ItemsToBring/Item';
import { SalesforceAppointment } from '/src/interfaces/SalesforceAppointment';

export enum ItemSection {
    Identification = 'Identification',
    Insurance = 'Insurance',
    FinanceDocumentation = 'Finance Documentation',
    PaymentTypes = 'Payment Types',
    Vehicle = 'Vehicle',
    Title = 'Title',
    Registration = 'Registration',
    Keys = 'Keys',
}

export enum DownPayment {
    Debit = 'Debit Card',
    Check = 'Check',
    LienHolderCheck = 'Lienholder Check',
    Cash = 'Cash',
    BankDraft = 'Bank Draft',
    ePay = 'ePay',
    notSet = 'Not Set',
    heldCheck = 'Held Check',
    certifiedFunds = 'Certified Funds',
    wireTransfer = 'Wire Transfer',
    travelersCheck = 'Travelers Check',
}

export type ItemValueDefinition = string[] | ((appointment: SalesforceAppointment) => string[]);

export interface RenderComponentProps {
    label: string;
    appointment: SalesforceAppointment;
    paymentType?: DownPayment;
    titleHolderCount?: number;
    value: ItemValueDefinition;
    children?: JSX.Element | JSX.Element[];
}

interface ItemDefinition {
    label: string;
    values?: ItemValueDefinition;
    RenderComponent: (specs: RenderComponentProps) => JSX.Element;
    // if present and returns false, it will prevent the component from showing
    guard?: (appointment: SalesforceAppointment) => boolean;
}

// note that if tradeIn is unknown, returns true
const showIfTradingIn = (appointment: SalesforceAppointment) =>
    appointment?.preCheckInData?.isTradeIn !== false;

export const ITEMS_TO_BRING: Record<string, ItemDefinition> = {
    [ItemSection.Identification]: {
        label: 'pci.items.identification.label',
        values: ['pci.items.identification.value'],
        RenderComponent: Item,
    },
    [ItemSection.Insurance]: {
        label: 'pci.items.insurance.label',
        values: ['pci.items.insurance.value'],
        RenderComponent: Item,
    },
    [ItemSection.FinanceDocumentation]: {
        label: 'pci.items.finance.label',
        values: (appointment) => [
            appointment?.preCheckInData?.hasStipT
                ? 'pci.items.finance.stipTPresent'
                : 'pci.items.finance.stipTUnknown',
        ],
        RenderComponent: Item,
        guard: (appointment) => appointment?.preCheckInData?.hasStipT !== false,
    },
    [ItemSection.PaymentTypes]: {
        label: 'pci.items.payment.label',
        RenderComponent: PaymentItem,
    },
    [ItemSection.Vehicle]: {
        label: 'pci.items.vehicle.label',
        values: ['pci.items.vehicle.value'],
        RenderComponent: Item,
        guard: showIfTradingIn,
    },
    [ItemSection.Title]: {
        label: 'pci.items.title.label',
        RenderComponent: TitleItem,
        guard: showIfTradingIn,
    },
    [ItemSection.Registration]: {
        label: 'pci.items.registration.label',
        values: ['pci.items.registration.value'],
        RenderComponent: Item,
        guard: showIfTradingIn,
    },
    [ItemSection.Keys]: {
        label: 'pci.items.keys.label',
        values: ['pci.items.keys.value'],
        RenderComponent: Item,
        guard: showIfTradingIn,
    },
};

export const DOWN_PAYMENT_OPTIONS = {
    [DownPayment.Debit]: {
        value: 'pci.items.payment.debit.value',
        alert: {
            type: 'info',
            heading: 'pci.items.payment.debit.alert.heading',
            subHeading: 'pci.items.payment.debit.alert.subHeading',
        },
    },
    [DownPayment.Check]: {
        value: 'pci.items.payment.check.value',
        alert: {
            type: 'info',
            heading: 'pci.items.payment.check.alert.heading',
            subHeading: 'pci.items.payment.check.alert.subHeading',
        },
    },
    [DownPayment.LienHolderCheck]: {
        value: 'pci.items.payment.lienHolderCheck.value',
        alert: {
            type: 'info',
            heading: 'pci.items.payment.lienHolderCheck.alert.heading',
        },
    },
    [DownPayment.Cash]: {
        value: 'pci.items.payment.cash.value',
        alert: {
            type: 'info',
            heading: 'pci.items.payment.cash.alert.heading',
        },
    },
    [DownPayment.BankDraft]: {
        value: 'pci.items.payment.bankDraft.value',
        alert: {
            type: 'info',
            heading: 'pci.items.payment.bankDraft.alert.heading',
        },
    },
    [DownPayment.ePay]: {
        value: 'pci.items.payment.ePay.value',
        alert: {
            type: 'info',
            heading: 'pci.items.payment.ePay.alert.heading',
            subHeading: 'pci.items.payment.ePay.alert.subHeading',
        },
    },
};
