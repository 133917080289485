import { useEffect } from "react";

/**
 * Assigns a handler to pressing ENTER
 * @param onPressEnter Callback to be called on key press
 * @param deps Dependencies (exactly as for useEffect)
 * @param guardCb Callback that will block execution if returns false (e.g a form validation)
 */
export const useEnterToMoveOn = (onPressEnter, deps?, guardCb = () => true) => {
    useEffect(() => {
        const handleEnterKey = ({ key }) => {
            if (key === "Enter" && guardCb()) {
                onPressEnter();
            }
        };
        document.addEventListener('keyup', handleEnterKey);

        return () => {
            document.removeEventListener('keyup', handleEnterKey);
        };
    }, deps);
};
