export const OFFER_CODE_TEXT_INPUT_FIELD_MIN_LENGTH = 8;

export const OFFER_CODE_TEXT_INPUT_FIELD_MAX_LENGTH = 10;

export const OFFER_CODE_VALID_LENGTHS = [8, 11];

export const LICENSE_PLATE_TEXT_INPUT_FIELD_MIN_LENGTH = 2;

export const LICENSE_PLATE_TEXT_INPUT_FIELD_MAX_LENGTH = 8;

export const MAX_ADDRESS_RESULTS_COUNT = 5;

export const PHONE_NUMBER_MAX_LENGTH = 15;

export const APARTMENT_NUMBER_MAX_LENGTH = 8;

export const UNIFIED_CHECKIN_PAGE_OTHER_REASON_TEXT_INPUT_MIN_LENGTH = 1;

export const UNIFIED_CHECKIN_PAGE_OTHER_REASON_TEXT_INPUT_MAX_LENGTH = 255;
