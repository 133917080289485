import React from 'react';
import PropTypes from 'prop-types';
import styles from './CarLogoImage.module.scss';

export default function CarLogoImage(props) {
    const { carImage } = props;

    return (
        <div className={styles.carimagecontainer}>
            <img src={carImage} className={styles.carimage} />
        </div>
    );
}

CarLogoImage.propTypes = {
    carImage: PropTypes.string.isRequired
};
