import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import styles from './IOExpressCompletePage.module.scss';
import ExpressDropoffLogoImage from '../../components/ExpressDropoffImage/ExpressDropoffLogoImage';
import OfferCodeBox from '../../components/OfferCodeBox/OfferCodeBox';
import image from '../../assets/express-dropoff.png';
import { useIntl } from 'react-intl';
import {
    useCheckIn,
    OfferContext,
    useErrorHandling, useNavigation
} from "/src/context";
import { findSalesforceAppointmentInformation, sendToSalesforce } from '../../api/sharedApiCalls';
import { useSplashRedirection } from "../../hooks/useSplashRedirection";
import useInitVisuals from "../../hooks/useInitVisuals";
import { addFsDetails } from "../../utils/fullstory";
import IOSteps from "../../flows/steps/IOSteps";

export default function IOExpressCompletePage() {
    const { formatMessage } = useIntl();
    const { offer } = useContext(OfferContext);
    const [checkInInfo] = useCheckIn();
    const { setError } = useErrorHandling();

    useInitVisuals();

    const { step } = useNavigation();
    const storeTypeNameInMessages = step === IOSteps.ExpressCheckInXFStoreCompletePage ? 'xf' : 'traditional';
    const title = formatMessage({id: `expressCheckinPage.${storeTypeNameInMessages}.store.title`});
    const subtitle = formatMessage({id: `expressCheckinPage.${storeTypeNameInMessages}.store.subtitle`});

    useSplashRedirection();

    useEffect(() => {
        submitSalesforceAppointmentCheckIn();
    }, []);

    const submitSalesforceAppointmentCheckIn  = async () => {
        let appointmentInfo;

        try {
            if(!checkInInfo.appointmentId){
                appointmentInfo = await findSalesforceAppointmentInformation(offer.phoneNumber, offer.storeNumber);
                Object.assign(checkInInfo, appointmentInfo);
            }
            addFsDetails({
                appointment: checkInInfo.appointmentId ? 'Yes' : 'No',
            });

            if(checkInInfo.appointmentId){
                await sendToSalesforce(checkInInfo);
            }
        } catch(error) {
            setError.general('Failed finding appointment or sending check-in to Salesforce.', {
                error,
                additionalInfo: { checkInInfo }
            });
        }
    };

    return (
        <div id="offerCodeBox" className={styles.container}>
            <ExpressDropoffLogoImage image={image} />
            <h2 className={classNames("kmx-typography--display-3", styles.title)}>
                {title}
            </h2>
            <OfferCodeBox offerCode={offer?.code} />
            <h2 className={classNames("kmx-typography--body-2", styles.subtitle)}>
                <strong>{subtitle}</strong>
            </h2>
        </div>
    );
}
