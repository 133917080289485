import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import pciCommonStyles from '../common/pciCommon.module.scss';
import useInitVisuals from '/src/hooks/useInitVisuals';
import { fsEvents } from '/src/utils/fullstory';
import useAppointmentAndStore from '/src/api/useAppointmentAndStore';
import VehicleCard from '/src/components/VehicleCard/VehicleCard';
import { useMediaQuery } from '/src/utils/mediaQueries/useMediaQueries';
import useAdobePci from '/src/flows/PreCheckIn/v2/common/hooks/useAdobePci';
import trackEvent from '/src/utils/adobe/trackEvent';
import Questions from '../common/Questions/Questions';
import AppointmentDetailsCard from '../common/AppointmentDetailsCard';
import { pciSteps, usePciSteps } from '/src/flows/PreCheckIn/pciSteps';
import PersonalItemsAlert from '/src/flows/PreCheckIn/v2/common/PersonalItemsAlert/PersonalItemsAlert';
import RescheduleLink from '/src/flows/PreCheckIn/v2/common/Reschedule';
import useFullstoryPci from '/src/flows/PreCheckIn/v2/common/hooks/useFullstoryPci';

export default () => {
    const { goToNextStep } = usePciSteps();

    const { appointment, store, isFetchComplete, isDataInvalid, hasFetchedAppointment } =
        useAppointmentAndStore();

    // store is used in tracking but for now is not needed to render the UI:
    useInitVisuals(hasFetchedAppointment);

    const { isDesktop } = useMediaQuery();

    useAdobePci(pciSteps.v2.details.name);
    useFullstoryPci(fsEvents.pciDetails);

    const onClickContinue = () => {
        trackEvent.preCheckin.confirm.click2();
        goToNextStep();
    };

    return (
        isFetchComplete &&
        !isDataInvalid && (
            <div className={pciCommonStyles.pciPageLayout}>
                <hzn-card
                    border-radius="medium"
                    elevation={isDesktop ? 'none' : 'medium'}
                    padding="medium"
                >
                    <hzn-stack space="large" align-x="center">
                        <hzn-heading
                            as="h1"
                            style={{ width: isDesktop ? 'auto' : '90%' }}
                            size="large"
                            align="center"
                        >
                            <FormattedMessage id="pci.confirm.heading" />
                        </hzn-heading>
                        {isDesktop && (
                            <hzn-text style={{ marginTop: 10 }}>
                                <FormattedMessage id="pci.welcome.intro" /> {appointment.year}{' '}
                                {appointment.make} {appointment.model}
                            </hzn-text>
                        )}

                        <hzn-card
                            border-radius="medium"
                            elevation={isDesktop ? 'flat' : 'none'}
                            padding={isDesktop ? 'large' : 'none'}
                        >
                            <hzn-columns space={isDesktop ? 'small' : 'none'} align-x="center">
                                {isDesktop && (
                                    <hzn-column>
                                        <VehicleCard {...appointment} />
                                    </hzn-column>
                                )}

                                <hzn-column>
                                    <AppointmentDetailsCard appointment={appointment} store={store}>
                                        <hzn-stack align-x="center" space="medium">
                                            <div
                                                onClick={onClickContinue}
                                                style={{ width: '100%' }}
                                            >
                                                <hzn-button full-width={true}>
                                                    <FormattedMessage id="common.confirm" />
                                                </hzn-button>
                                            </div>

                                            <RescheduleLink
                                                adobeTracker={trackEvent.preCheckin.confirm.click6}
                                                fullstoryPage={fsEvents.pciDetails}
                                            />
                                        </hzn-stack>
                                    </AppointmentDetailsCard>
                                </hzn-column>
                            </hzn-columns>
                        </hzn-card>

                        <PersonalItemsAlert />

                        <Questions
                            eventTrackers={{
                                faq: trackEvent.preCheckin.confirm.click7,
                                phone: trackEvent.preCheckin.confirm.click8,
                                docs: trackEvent.preCheckin.confirm.click9,
                            }}
                        />
                    </hzn-stack>
                </hzn-card>
            </div>
        )
    );
};
