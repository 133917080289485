import React, { useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './AccordionSection.module.scss';
import { logFsEvent, buildEventName } from '../../utils/fullstory';
import { fullstoryConstants } from '../../constants/fullstory';
import DownIcon from '@kmx/legos-icons-material-system/assets/svg/keyboard-arrow-down.svg';
import { scrollToElement } from '../../utils/scrollToElement';
import { accordionTypes } from '../../constants/pageTypes';

// Based heavily on the component from the Online Finance team: https://github.carmax.com/CarMax/online-finance-web-ui/tree/master/src/components/accordion-section
export function AccordionSection(props) {
    const { title, children, className, sectionId, type } = props;

    const [isSectionExpanded, setIsSectionExpanded] = useState(false);
    const [isInitiated, setIsInitiated] = useState(false);

    const onClick = () => {
        setIsSectionExpanded(!isSectionExpanded);

        if (sectionId && type && type === accordionTypes.CompletePage) {
            const faqClickFullstoryBaseName = 'clickFaq';

            logFsEvent(buildEventName(fullstoryConstants.completePage.name,
                fullstoryConstants.completePage.events[`${faqClickFullstoryBaseName}${sectionId}`]));
        }
    };

    if (sectionId) {
        useEffect(() => {
            const handleAutoExpand = () => {
                const searchElement = '#' + sectionId;

                if (window.location.hash === searchElement) {
                    setIsSectionExpanded(true);
                    scrollToElement(searchElement);
                }
            };

            if (!isInitiated) {
                setIsInitiated(true);
                handleAutoExpand();
            }

            window.addEventListener('hashchange', handleAutoExpand);

            return () => {
                window.removeEventListener('hashchange', handleAutoExpand);
            };
        });
    }

    return (
        <div id={sectionId} className={classNames(className)}>
            <div className={classNames(styles.innerGrid, "mdc-layout-grid__inner")}>
                <div onClick={() => onClick()} className={classNames(styles.clickable, styles.questionWrapper, "mdc-layout-grid__cell mdc-layout-grid__cell--span-3-phone mdc-layout-grid__cell--span-7-tablet mdc-layout-grid__cell--span-11-desktop")}>
                    <h3 className={classNames("kmx-typography--body-2 kmx-typography--weight-700", styles.accordionQuestion)}>{title}</h3>

                    <div className={classNames(styles.clickable, styles.expansionIcon, "mdc-layout-grid__cell mdc-layout-grid__cell--span-1")}>
                        <span>
                            <DownIcon height="24" width="24" className={classNames(styles.accordionIcon, isSectionExpanded ? styles.accordionIconExpanded : styles.accordionIconCollapsed)} />
                        </span>
                    </div>
                </div>

                <div onClick={() => onClick()} className={classNames(styles.clickable, styles.expansionIcon, "mdc-layout-grid__cell mdc-layout-grid__cell--span-1")}>
                    <span>
                        <DownIcon height="24" width="24" className={classNames(styles.accordionIcon, isSectionExpanded ? styles.accordionIconExpanded : styles.accordionIconCollapsed)} />
                    </span>
                </div>
                <div className={classNames("mdc-layout-grid__cell mdc-layout-grid__cell--span-12", isSectionExpanded ? styles.accordionContentExpand : styles.accordionContentCollapsed)}>
                    {children}
                </div>
            </div>
            <hr />
        </div>
    );
}

AccordionSection.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node,
    className: PropTypes.string,
    sectionId: PropTypes.number,
    type: PropTypes.string,
};

export default AccordionSection;
