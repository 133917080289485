import { useCheckIn } from '/src/context';
import React, { Dispatch, useEffect } from 'react';
import { formatPhoneNumber } from '../../utils/index';
import styles from './CustomerInfoFields.module.scss';
import { useIntl } from 'react-intl';
import { toTitleCase } from '/src/utils/generalHelpers';
import { useForm } from 'react-hook-form';
import InputText from '/src/components/inputFields/InputText';
import { maxLen, required, emailPattern } from '/src/components/inputFields/validators';
import InputPhone from '/src/components/inputFields/InputPhone';

interface Props {
    firstName: string;
    setFirstName: Dispatch<string>;
    lastName: string;
    setLastName: Dispatch<string>;
    phoneNumber: string;
    setPhoneNumber: Dispatch<string>;
    email: string;
    setEmail: Dispatch<string>;
    showEmailSubtext: string;
    setIsValid: Dispatch<boolean>;
}

function CustomerInfoFields(props: Props) {
    const { formatMessage } = useIntl();
    const [checkInInfo] = useCheckIn();

    const {
        setFirstName,
        setLastName,
        setPhoneNumber,
        setEmail,
        showEmailSubtext,
        setIsValid,
    } = props;

    const disablePhoneNumberField =
        checkInInfo?.hadSalesforceAppointment && !!formatPhoneNumber(props.phoneNumber);

    const {
        handleSubmit,
        control,
        formState: { isValid },
        getValues,
    } = useForm({
        mode: 'all',
        defaultValues: {
            phone: props.phoneNumber ?? '',
            firstName: props.firstName,
            lastName: props.lastName,
            email: props.email,
        },
    });
    const values = getValues();

    useEffect(() => {
        setIsValid(isValid);
    }, [isValid]);
    useEffect(() => {
        setFirstName(values.firstName);
    }, [values.firstName]);
    useEffect(() => {
        setLastName(values.lastName);
    }, [values.lastName]);
    useEffect(() => {
        if (setEmail) setEmail(values.email);
    }, [values.email]);
    useEffect(() => {
        if (!disablePhoneNumberField) setPhoneNumber(values.phone);
    }, [values.phone]);

    return (
        <div className={styles['customer-info-fields']} id="customerInfoFields">
            <form>
                <InputText
                    name="firstName"
                    labelId="customerInfoPage.firstNameLabel"
                    control={control}
                    validators={{
                        'customerInfoPage.firstNameRequiredErrorMessage': required(),
                        'customerInfoPage.firstNameTooLong': maxLen(15),
                    }}
                    transformDisplay={toTitleCase}
                />
                <InputText
                    name="lastName"
                    labelId="customerInfoPage.lastNameLabel"
                    control={control}
                    validators={{
                        'customerInfoPage.lastNameRequiredErrorMessage': required(),
                        'customerInfoPage.lastNameTooLong': maxLen(20),
                    }}
                    transformDisplay={toTitleCase}
                />
                <InputPhone
                    control={control}
                    textFieldProps={{ disabled: disablePhoneNumberField }}
                />
                {setEmail && (
                    <fieldset className={styles['form-field']} id="email-address">
                        <InputText
                            name="email"
                            labelId="customerInfoPage.emailLabel"
                            control={control}
                            validators={{
                                'customerInfoPage.emailRequiredErrorMessage': required(),
                                'customerInfoPage.emailInvalidErrorMessage': emailPattern(),
                            }}
                            textFieldProps={{
                                type: 'email',
                                inputMode: 'email',
                            }}
                        />
                        {showEmailSubtext && (
                            <p id="emailSubtext" className="kmx-typography--fine-print">
                                {formatMessage({ id: 'customerContactPage.emailAddressSubtext' })}
                            </p>
                        )}
                    </fieldset>
                )}
            </form>
        </div>
    );
}

export default CustomerInfoFields;
