import React from 'react';
import PropTypes from 'prop-types';
import styles from './ErrorMessageLabel.module.scss';

export default function ErrorMessageLabel(props) {
    const { errorMessage } = props;

    return (
        <div className={styles.label}>
            <h3>{errorMessage}</h3>
        </div>
    );
}

ErrorMessageLabel.propTypes = {
    errorMessage: PropTypes.string.isRequired,
};
