import React, { useEffect, useState } from 'react';
import styles from './UnifiedGetOffer.module.scss';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import CheckIcon from '@kmx/legos-icons-essentials/assets/svg/check.svg';
import { useCheckIn, useStore } from '/src/context';
import { Button } from '@kmx/legos-react-button';
import { useAdobeTracking } from '../../utils/adobe/analytics';
import useInitVisuals from '../../hooks/useInitVisuals';
import classNames from 'classnames';
import trackEvent from '/src/utils/adobe/trackEvent';
import { UnifiedSteps } from '/src/flows/steps';
import { SnackBar } from '@kmx/legos-react-snackbar';
import flows from '/src/flows/flows';
import { BASE_URL, getAnonymizedSearch } from '/src/utils/urlHelpers';
import InputPhone from '/src/components/inputFields/InputPhone';
import { configuration } from '/src/utils/configuration';

import '@kmx/legos-snackbar/dist/index.min.css';

// wait 7s before redirecting to splash page when switching kiosk -> mobile
const redirectionTimeoutS = 7;

function UnifiedGetOffer() {
    const { formatMessage } = useIntl();
    const [storeData] = useStore();

    const [checkInInfo] = useCheckIn();
    const { handleSubmit, control } = useForm({
        defaultValues: {
            phone: checkInInfo?.phoneNumber ?? '',
        },
    });
    // TODO: no need for new state, use {formState:{isValid}}=useForm()
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(Boolean(checkInInfo?.phoneNumber));

    const [redirectionCountdown, setRedirectionCountdown] = useState<number>();
    const willSoonRedirect = redirectionCountdown !== undefined;

    useInitVisuals();
    useAdobeTracking(UnifiedSteps.GetOffer);

    const onClickContinueHere = async () => {
        trackEvent.unified.getOffer.click3();
        window.location.replace(configuration.sellMyCarUrl(storeData.id, storeData.zipCode));
    };

    const onClickSendSms = ({ phone }) => {
        trackEvent.unified.getOffer.click2();

        // TODO: send sms api call
        console.log('TODO: send sms to ', phone);

        setRedirectionCountdown(redirectionTimeoutS);
        setInterval(() => setRedirectionCountdown((x) => x - 1), 1000);
    };
    useEffect(() => {
        if (redirectionCountdown === 0) {
            window.location.replace(BASE_URL + flows.Unified + getAnonymizedSearch());
        }
    }, [redirectionCountdown]);

    return (
        <div className={styles.container}>
            <h4 className={classNames(styles.header, 'kmx-typography--display-3')}>
                {formatMessage({ id: 'unified.getOffer.title' })}
            </h4>
            <form
                onSubmit={handleSubmit(onClickSendSms, console.error)}
                className={classNames(styles.smsForm)}
            >
                <div className="kmx-typography--body-2 kmx-blue-900">
                    {formatMessage({ id: 'unified.getOffer.sendSms' })}
                </div>
                <InputPhone
                    control={control}
                    textFieldProps={{ disabled: willSoonRedirect }}
                    validStateSetter={setIsPhoneNumberValid}
                />
                <Button
                    className={styles.button}
                    id="next-button"
                    disabled={willSoonRedirect || !isPhoneNumberValid}
                    type="submit"
                >
                    {formatMessage({ id: 'common.submit' })}
                </Button>
            </form>

            <div className={styles.continueHereWrapper}>
                <div className="kmx-typography--body-2 kmx-blue-900">
                    {formatMessage({ id: 'unified.getOffer.continueHere' })}
                </div>

                <Button
                    className={styles.button}
                    id="next-button"
                    onClick={onClickContinueHere}
                    disabled={willSoonRedirect}
                >
                    {formatMessage({ id: 'unified.getOffer.continueHereCta' })}
                </Button>
            </div>

            {willSoonRedirect && (
                <SnackBar
                    textLabel={
                        formatMessage({ id: 'unified.getOffer.smsSent' }) +
                        '... ' +
                        redirectionCountdown
                    }
                    type="success"
                    icon={CheckIcon}
                    cancelAction={() => setRedirectionCountdown(undefined)}
                    closeTimeout={redirectionTimeoutS * 1000 - 200}
                />
            )}
        </div>
    );
}

export default UnifiedGetOffer;
