import React, {
    useState,
    createContext,
    useContext
} from "react";
import unifiedSteps from "../flows/steps/unifiedSteps";
import { useHistory } from "react-router-dom";
import { noop } from "../constants/general";

export const NavigationContext = createContext({
    flow: null,
    step: null,
    setStep: noop,
    getPreviousStep: noop,
    setFlowAndStep: noop,
});

type Step = Symbol | string;

const stepHistory: Step[] = [];
/**
 * @param indexBack Positive number, how many steps back. 0 = current step, 1 = the step before etc
 */
const getPreviousStep = (indexBack: number = 1) => stepHistory[stepHistory.length - 1 - indexBack];

export const NavigationProvider = ({ children }: { children: JSX.Element }) => {
    const [step, setStepState] = useState<Symbol|string|undefined>();
    const setStep = (newStep: Step) => {
        if (getPreviousStep(0) !== newStep) {
            stepHistory.push(newStep);
        }
        setStepState(newStep);
    };
    const [flow, setFlow] = useState<Symbol|string|undefined>();

    const history = useHistory();

    return <NavigationContext.Provider
        value={{
            flow,
            step,
            setStep,
            getPreviousStep,
            setFlowAndStep: (flow: string, step?: Symbol | string) => {
                history.push(flow + history.location.search);
                if (step) setStep(step);
                setFlow(flow);
            },
        }}
        children={children}
    />;
};

export const useNavigation = () => {
    const contextValue = useContext(NavigationContext);
    return contextValue;
};
