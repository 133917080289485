import React, { Context } from 'react';
import { StatefulContextValue } from "/src/context/createStatefulContext";

type ExtendedProviderType<T> = React.Provider<T> | (({ children }) => JSX.Element) | Context<StatefulContextValue<T>>;
export type ProviderSpecs<T> = [provider: ExtendedProviderType<T>, value?: T];

// Compose providers (with or without a value given as prop)
// Example usage:
// <ComposeProviders providers={[[FeatureContext.Provider, [{ features: {}, setFeatures: noop }]], [NavigationProvider]]}>
export const ComposeProviders = ({
    providers,
    children,
}: {
    providers: ProviderSpecs<unknown>[];
    children: React.ReactElement;
}) =>
    providers.reduceRight(
        (acc, [ContextProvider, value]) =>
            React.createElement(ContextProvider as  React.Provider<any>, value ? { value } : undefined, acc),
        children,
    );

// Compose providers that don't need a value given from the outside
// (like ComposeProviders but with a simplified interface)
// Example usage:
// <ComposeProviders providers={[SpinnerProvider, NavigationProvider]}>
export const ComposeStatefulProviders = ({
    providers,
    children,
}: {
    providers: React.Provider<unknown>[];
    children: React.ReactElement;
}) =>
    providers.reduceRight(
        (acc, ContextProvider) => React.createElement(ContextProvider, undefined, acc),
        children,
    );
