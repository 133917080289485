import React from 'react';
import MagnifyingGlassCar from '@kmx/legos-icons-brand/assets/svg/magnifying-glass-car.svg';
import CarHappy from '@kmx/legos-icons-brand/assets/svg/car-happy.svg';
import OfferInHand from '@kmx/legos-icons-brand/assets/svg/offer-in-hand.svg';
import TalkAboutFinancing
    from '@kmx/legos-icons-brand/assets/svg/talk-about-financing.svg';
import Clipboard from '@kmx/legos-icons-brand/assets/svg/clipboard.svg';
import CheckButtonGroupOption from '../interfaces/CheckButtonGroupOption';
import trackEvent from '/src/utils/adobe/trackEvent';
import { SalesForceVisitReasons } from '/src/interfaces/SalesForceVisitReasons';

interface VisitReasonsOption extends CheckButtonGroupOption<SalesForceVisitReasons> {
    isDisplayElement: boolean;
    isInKiosk: boolean;
    adobeTracker?: (hasAppointment: boolean) => void;
    fullstoryName?: string;
}

export const visitReasonsOptions: VisitReasonsOption[] = [
    {
        label: 'Browse for a car',
        value: SalesForceVisitReasons.Browse,
        isChecked: false,
        isDisplayElement: true,
        isInKiosk: true,
        adobeTracker: trackEvent.unified.appointment.click8,
        icon: MagnifyingGlassCar,
        fullstoryName: 'Browse',
    },
    {
        label: 'Test drive a car',
        value: SalesForceVisitReasons.TestDrive,
        isChecked: false,
        isDisplayElement: true,
        isInKiosk: true,
        adobeTracker: trackEvent.unified.appointment.click2,
        icon: CarHappy,
        fullstoryName: 'Test drive',
    },
    {
        label: 'Explore financing',
        value: SalesForceVisitReasons.Financing,
        isChecked: false,
        isDisplayElement: true,
        isInKiosk: true,
        adobeTracker: trackEvent.unified.appointment.click3,
        icon: TalkAboutFinancing,
        fullstoryName: 'Financing',
    },
    {
        label: 'Get an offer on your car',
        value: SalesForceVisitReasons.Appraisal,
        isChecked: false,
        isDisplayElement: true,
        isInKiosk: true,
        adobeTracker: trackEvent.unified.appointment.click4,
        icon: Clipboard,
        fullstoryName: 'Get Offer',
    },
    {
        label: 'Redeem my online offer',
        value: SalesForceVisitReasons.IcoAppraisalAppointment,
        isChecked: false,
        isDisplayElement: true,
        isInKiosk: true,
        adobeTracker: trackEvent.unified.appointment.click5,
        icon: OfferInHand,
        fullstoryName: 'Redeem Offer',
    },
    {
        label: 'Other',
        value: SalesForceVisitReasons.Other,
        isChecked: false,
        isDisplayElement: true,
        isInKiosk: false,
    },
    {
        label: 'OtherTextInput',
        value: SalesForceVisitReasons.OtherInput,
        isChecked: null,
        isDisplayElement: false,
        isInKiosk: false,
    },
];

/** @deprecated Use the TS enum instead */
export const salesForceVisitReasonsEnum = {
    BROWSE: 'Browse',
    FINANCING: 'Financing',
    APPRAISAL: 'Appraisal',
    ICO_APPRAISAL_APPOINTMENT: 'ICO Appraisal Appointment',
    ONLINE_PURCHASE: 'Express Pickup',
    OTHER: 'Other',
    OTHER_INPUT: 'Other Text Input Value',
};
