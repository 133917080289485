import { apiWrapper } from './apiService';
import {
    mapAppraisalAppointmentData,
    mapSalesforceAppointmentData,
    mapOfferData,
} from '../utils/transformData';
import { removeNonNumericCharacters } from '../utils';
import { apiRoutes } from '../constants/api';
import {
    SalesforceAppointment,
    SalesforceAppointmentId,
} from '../interfaces/SalesforceAppointment';
import { StoreData } from '../interfaces/StoreData';
import AddressSuggestion from '../interfaces/AddressSuggestion';
import { MAX_ADDRESS_RESULTS_COUNT } from '../constants/inputValidationConstants';
import SmartAddress from '../interfaces/SmartAddress';
import { Quote } from '/src/interfaces/Quote';
import { Either } from '/src/utils/types';
import { CheckIn } from '/src/interfaces/CheckIn';
import { OfferInfo } from '/src/interfaces/OfferInfo';

export async function findAppointment(code) {
    let appointmentData = null;

    try {
        if (code) {
            appointmentData = await apiWrapper.GET(apiRoutes.appraisalAppointmentsApi(code));
            appointmentData.appraisalCode = code;
        }
    } catch (error) {
        if (error.statusCode !== 404) {
            throw error;
        }
    }
    return mapAppraisalAppointmentData(appointmentData);
}

export async function findSalesforceAppointmentInformation(phoneNumber, storeNumber) {
    let appointmentData;

    try {
        if (phoneNumber) {
            const numberOnly = removeNonNumericCharacters(phoneNumber);
            appointmentData = await apiWrapper.GET(apiRoutes.salesforceAppointmentsApi(numberOnly));
            appointmentData.phoneNumber = phoneNumber;
        } else {
            appointmentData = null;
        }
    } catch (e) {
        appointmentData = { phoneNumber: phoneNumber };
        if (e.statusCode !== 404) {
            throw new Error(e.message);
        }
    }
    return mapSalesforceAppointmentData(appointmentData, phoneNumber, storeNumber);
}

export async function getSalesforceAppointmentById(
    id: string,
    includeOrderData?: boolean,
): Promise<SalesforceAppointment> {
    const appointmentData = await apiWrapper.GET(apiRoutes.salesforceAppointmentsById(id, includeOrderData));
    return mapSalesforceAppointmentData(appointmentData);
}

export function sendToSalesforce(checkIn: CheckIn) {
    if (!checkIn) {
        throw new Error('No check-in data');
    }
    const shouldUpdateName = !checkIn.hadSalesforceAppointment;
    const request = {
        appointmentId: checkIn.appointmentId ?? '',
        appointmentTypes: checkIn.appointmentTypes ?? [],
        otherAppointmentValue: checkIn.description ?? '',
        storeNumber: checkIn.storeNumber ?? '',
        firstName: shouldUpdateName ? checkIn.firstName : null,
        lastName: shouldUpdateName ? checkIn.lastName : null,
        email: !checkIn.hadSalesforceAppointment ? checkIn.email : null,
        phone: !checkIn.hadSalesforceAppointment
            ? removeNonNumericCharacters(checkIn.phoneNumber)
            : null,
    };
    return apiWrapper.POST(apiRoutes.sendToSalesforceApi, request);
}

export async function sendIOV(offer: OfferInfo, appointmentId: SalesforceAppointmentId) {
    const request = mapOfferData(offer, appointmentId);
    await apiWrapper.POST(apiRoutes.sendIOV, request);
}

export async function getStoresData() {
    let storesData = await apiWrapper.GET(apiRoutes.storesApi);
    return storesData;
}

export const getStoreData = (storeId: number): Promise<StoreData> =>
    apiWrapper.GET(apiRoutes.storeDataApi(storeId));

export async function getExteriorColorsData() {
    let exteriorColorsData;

    try {
        exteriorColorsData = await apiWrapper.GET(apiRoutes.exteriorColorsApi);
    } catch (e) {
        exteriorColorsData = null;
    }

    return exteriorColorsData;
}

export async function getFeatureFlagsData(storeId) {
    let featureFlagsData;

    try {
        featureFlagsData = await apiWrapper.GET(apiRoutes.getFeatureFlagsApi(storeId));
    } catch (e) {
        featureFlagsData = null;
    }

    return featureFlagsData;
}

export const getAddressSuggestions = async (searchTerm: string): Promise<AddressSuggestion[]> => {
    searchTerm = searchTerm.trim().replace(/[\n\r]/g, ' ');
    const request = apiRoutes.addressSuggestionsApi
        .replace('{value}', searchTerm)
        .replace('{count}', MAX_ADDRESS_RESULTS_COUNT);
    const response = await apiWrapper.GET(`${request}`);
    return response.suggestions;
};

export const getSmartAddress = async (address: string): Promise<SmartAddress> => {
    address = address.replace(/[\n\r]/g, ' ');
    const request = apiRoutes.smartAddressApi.replace('{address}', address);
    return await apiWrapper.GET(`${request}`);
};

export const findQuotes = async (
    requestParams: Either<{ offerCode: string }, { licensePlate: string; state: string }>,
): Promise<Quote[] | null> => {
    try {
        let result;
        if (requestParams.offerCode) {
            result = await apiWrapper.GET(apiRoutes.quotesApi(requestParams.offerCode));
        } else {
            result = await apiWrapper.POST(
                apiRoutes.quotesApi(),
                {
                    licensePlate: requestParams.licensePlate,
                    stateCode: requestParams.state,
                },
                3,
            );
        }
        return result?.filter((offer) => offer.valuation > 0);
    } catch (error) {
        return null;
    }
};

/** @return true if verified, false if not */
export const getAppraisalVerificationStatus = (storeId: number, vin: string): Promise<boolean> =>
    apiWrapper.GET(apiRoutes.appraisalVerificationStatus(storeId, vin));

export const postTripEvent = (body: {
    appointmentId: string;
    storeNumber: string;
    ciamId: string;
    hasCustomerArrived: boolean;
}) => apiWrapper.POST(apiRoutes.postTripEvent, body);
