export default {
    'landingPage.header': 'Welcome to CarMax Appraisal Check-in',
    'landingPage.subtitle': 'Skip the line - check in on your own',
    'landingPage.button': 'Get Started',
    'landingPage.instantOffer': 'I want to get an instant offer',
    'activeOffersPage.title': 'Which offer will you redeem today?',
    'activeOffersPage.subtitle': 'You have multiple valid offers.',
    'activeOffersPage.buttonTitle': 'CONTINUE',
    'checkInPage.title': "Let's find your offer ",
    'checkInPage.subtitle': "Let's find your offer",
    'checkInPage.dataInputSwitcherTitle': 'Provide one of the following:',
    'checkInPage.dataInputSwitcherOption1Label': 'OFFER CODE',
    'checkInPage.dataInputSwitcherOption2Label': 'LICENSE PLATE',
    'checkInPage.offerCodeTextInputLabel': 'Enter your offer code',
    'checkInPage.offerCodeTextInputRequiredValidationErrorMessage': 'Offer code must be entered.',
    'checkInPage.offerCodeTextInputSpecialCharactersValidationErrorMessage':
        'Please enter a valid offer code.',
    'checkInPage.licensePlateTextInputLabel': 'Enter your license plate',
    'checkInPage.licensePlateRequiredValidationErrorMessage': 'License plate must be entered.',
    'checkInPage.licensePlateSpacesFoundValidationErrorMessage':
        'Please enter a valid license plate.',
    'checkInPage.storeSelectorLabel': 'Select CarMax store',
    'checkInPage.storeSelectorErrorMessageHelperText': 'Store selection is required.',
    'checkInPage.stateSelectorDropdownLabel': 'Where is your vehicle registered?',
    'checkInPage.stateSelectorErrorMessageHelperText': 'State selection is required.',
    'checkInPage.findMyOfferButtonLabel': 'FIND MY OFFER',
    'checkInPage.findMyOfferNotFoundErrorMessage':
        '*No active offers found. Try entering it again.',
    'checkInPage.storeClosingSoonMessage':
        'The store will be closing soon. Please come inside to get checked in with an associate.',
    'checkInPage.storeClosedMessage':
        'The store is currently closed. Please come back during store hours.',
    'parkingSpotNumberPage.title': "Where's your vehicle parked?",
    'parkingSpotNumberPage.subtitle': 'Please provide us with the spot number located on the sign.',
    'parkingSpotNumberPage.licensePlateInputLabel': 'Enter your license plate number',
    'parkingSpotNumberPage.licensePlateRequiredValidationErrorMessage':
        'License plate must be entered.',
    'parkingSpotNumberPage.continueButtonLabel': 'CONTINUE',
    'confirmOfferPage.offerCard.confirmOffer': 'Redeem this offer',
    'confirmOfferPage.offerCard.offerCode': 'Offer code:',
    'confirmOfferPage.offerCard.validUntil': 'Valid until',
    'confirmOfferPage.requirementsList.header': "You'll need these items to sell us your vehicle",
    'confirmOfferPage.requirementsList.listItem1': "Title (If it's not with the lien holder)",
    'confirmOfferPage.requirementsList.listItem1.info':
        'The title must be provided at the time of sale and all titleholders should be present. If a previously paid-off lien is listed on the title, you will need both the title and lien release from the lienholder before CarMax can purchase your car.',
    'confirmOfferPage.requirementsList.listItem2': 'Valid registration',
    'confirmOfferPage.requirementsList.listItem3':
        'Valid state-issued photo ID for all titleholders',
    'confirmOfferPage.requirementsList.listItem4': 'All keys and remotes (if applicable)',
    'confirmOfferPage.acknowledge':
        'I acknowledge my offer can be verified but not sold to CarMax without the items listed above.',
    'confirmOfferPage.legal':
        "The offer from CarMax is contingent on your providing accurate information. CarMax will conduct a verification of your vehicle and evaluate other vehicle use and history information prior to finalizing the offer. Any differences between the information your provide and the vehicle and the vehicle's actual condition, use, and history may impact the offer you receive from CarMax.",
    'confirmOfferPage.submit': 'Confirm',
    'customerInfoPage.header': 'Your information',
    'customerInfoPage.submitButton': 'Next',
    'customerInfoPage.customerStepLabel': 'Contact info',
    'customerInfoPage.firstNameLabel': 'First Name',
    'customerInfoPage.firstNameErrorLabel': 'First name',
    'customerInfoPage.firstNameRequiredErrorMessage': 'First name must be entered.',
    'customerInfoPage.firstNameTooLong': 'First name must be less than 16 characters.',
    'customerInfoPage.lastNameLabel': 'Last Name',
    'customerInfoPage.lastNameErrorLabel': 'Last name',
    'customerInfoPage.lastNameRequiredErrorMessage': 'Last name must be entered.',
    'customerInfoPage.lastNameTooLong': 'Last name must be less than 21 characters.',
    'customerInfoPage.phoneNumberLabel': 'Phone',
    'customerInfoPage.phoneNumberRequiredErrorMessage': 'Phone number must be entered.',
    'customerInfoPage.phoneNumberInvalidErrorMessage': 'Please enter a valid phone number.',
    'customerInfoPage.emailLabel': 'Email Address',
    'customerInfoPage.emailRequiredErrorMessage': 'Email address must be entered.',
    'customerInfoPage.emailInvalidErrorMessage': 'Please enter a valid email address.',
    'customerInfoPage.addressLabel': 'Mailing Address',
    'customerInfoPage.addressRequiredErrorMessage': 'Address must be entered.',
    'customerInfoPage.addressNoneFoundErrorMessage':
        'No addresses match your input. Please try again.',
    'customerInfoPage.apartmentLabel': 'Apartment info (optional)',
    'customerInfoPage.apartmentTypeLabel': 'Type',
    'customerInfoPage.apartmentValueLabel': 'Number',
    'customerInfoPage.searchingText': 'Searching...',
    'customerInfoPage.noResultsText': 'No results found',
    'customerInfoPage.selectAddressErrorText':
        'Search for an address and select from the list below.',
    'useSmartAddress.addressNotFound': 'Address not found',
    'useSmartAddress.searchError':
        'An error occurred searching for address. Please try again soon.',
    'customerInfoPage.apartmentValueNotEnteredError': 'Please enter a value for apartment number.',
    'customerContactPage.customerStepLabel': 'Your contact info',
    'customerContactPage.header': 'Last step: what do we call you?',
    'customerContactPage.submitButton': 'Check Me In',
    'customerContactPage.emailAddressSubtext':
        'We use this to see if you’ve worked with us before.  It’s how we keep your info all in one place.',
    'vehicleInfoPage.header': 'Your information',
    'vehicleInfoPage.submitButton': 'Submit',
    'vehicleInfoPage.vehicleStepLabel': 'Vehicle Info',
    'vehicleInfoPage.styleLabel': 'Style',
    'vehicleInfoPage.transmissionLabel': 'Transmission',
    'vehicleInfoPage.driveLabel': 'Drive',
    'vehicleInfoPage.mileageLabel': 'Mileage',
    'vehicleInfoPage.colorSelectorLabel': 'What color is your car?',
    'vehicleInfoPage.colorSelectorErrorMessage': 'Color is required',
    'vehicleInfoPage.intentionToSellRadioLabel': 'Are you planning to sell your car today?',
    'vehicleInfoPage.vehicleDriveabilityRadioLabel': 'Is your car safe to drive?',
    'vehicleInfoPage.vehiclePaymentsRadioLabel': 'Are you still making payments on your car?',
    'vehicleInfoPage.vehicleTradeInRadioLabel': 'Is this part of a trade-in?',
    'completePage.header': "You're checked in!",
    'completePage.subtitle1':
        'Please bring your keys to the associate at the Welcome Desk and let them know that you are here for your "online offer"',
    'completePage.subtitle2': 'An associate will complete your vehicle assessment.',
    'completePage.subtitle3':
        "You will receive a call at the phone number you provided when your vehicle assessment is complete and we're ready to buy your vehicle.",
    'completePage.kiosk.subtitle1':
        "Bring your keys to the Welcome Desk and we'll finish your vehicle assessment.",
    'completePage.kiosk.subtitle2': "We'll call you when it's done.",
    'completePage.subtitle1.XF': 'We will be with you shortly to complete your vehicle assessment.',
    'completePage.subtitle2.XF':
        'We will contact you when your vehicle assessment is complete and we’re ready to buy your vehicle.',
    'completePage.kiosk.subtitle.XF':
        'We’ll be right with you to finish your vehicle assessment, and we’ll let you know when it’s done.',
    'completePage.faqTitle': 'Frequently Asked Questions',
    'completePage.faqQuestion1': 'Can I negotiate my online offer?',
    'completePage.faqAnswer1': 'No, all of our offers are firm and valid for 7 days.',
    'completePage.faqQuestion2': 'What do I need to sell my car?',
    'completePage.faqAnswer2':
        "Requirements can vary from state to state, but there are a few items you'll need no matter where you are:",
    'completePage.faqAnswer2.1':
        "Your car's title or payoff information. All titleholders should be present.",
    'completePage.faqAnswer2.2': 'Valid and current registrations.',
    'completePage.faqAnswer2.3': 'Valid state-issued photo ID for all title holders.',
    'completePage.faqAnswer2.4': 'All keys and remotes.',
    'completePage.faqQuestion3': 'How do I get paid for my car?',
    'completePage.faqAnswer3':
        'You will leave with payment in hand the same day you sell us your car. We pay with a bank draft, which is a type of check where payment is guaranteed to be available by the issuing bank. A bank draft must be deposited and cannot be cashed. Before attempting to draw on the bank draft, always check with your bank to see if they have a specific hold policy on deposits.',
    'errorPage.buttonTitle': 'RETURN TO CHECK-IN',
    'errorPage.general.title': 'Something went wrong on our end',
    'errorPage.general.subtitle':
        'Please come inside the store and our associates would be happy to assist you with checking in.',
    'errorPage.duplicateSubmission.title': 'We are currently reviewing this car',
    'errorPage.duplicateSubmission.subtitle':
        "The car you're trying to check in is currently being reviewed. If you need to make any changes to your car info, go to the Welcome Desk at the front of the store and we'd be happy to help.",
    'errorPage.pendingEpu.title': 'Something went wrong on our end',
    'errorPage.pendingEpu.subtitle':
        'Come to the Welcome Desk at the front of the store and we would be happy to assist you with checking in.',
    'storeHoursPage.title': 'Self check-in is not available at this time',
    'storeHoursPage.subtitle':
        'Please feel free to go inside the store and speak with an associate.',
    'storeHoursPage.buttonTitle': 'RETURN TO CHECK-IN',
    'storeHoursPage.storeHoursLabel': 'Store Hours:',
    'storeHoursPage.openTodayLabel': 'Open today',
    'errorPage.checkInDisabled.title': 'Self check-in is not available at this time',
    'errorPage.checkInDisabled.subtitle':
        'Come to the Welcome Desk at the front of the store and we would be happy to assist you with checking in.',
    'errorPage.checkInDisabled.storeClosingSoonMessage':
        'The store will be closing soon. Please come inside to get checked in with an associate.',
    'errorPage.checkInDisabled.storeClosedMessage':
        'The store is closed at this time. Please come back during store hours.',
    'errorPage.notFound.title': 'Page not found.',
    'errorPage.missingStoreId.title': 'Missing Store ID',
    'errorPage.missingStoreId.subtitle': 'Please add storeId to the URL arguments',
    'validation.requiredField': 'Required field',
    'validation.offerCodeInvalid': 'Offer code must be 8 or 11 characters',
    'expressCheckinPage.traditional.store.title':
        'You’re checked in and ready for your express drop-off',
    'expressCheckinPage.traditional.store.subtitle':
        'Bring your offer code to the Business Office to complete your sale.',
    'expressCheckinPage.xf.store.title': 'You’re checked in and ready for your express drop-off',
    'expressCheckinPage.xf.store.subtitle': "We'll be right with you to complete your sale.",
    'picsyCheckinPage.traditional.store.title': 'You’re checked in and ready to redeem your offer',
    'picsyCheckinPage.traditional.store.subtitle':
        'Bring your offer code to the Business Office to complete your sale.',
    'picsyCheckinPage.xfs.store.title': 'You’re checked in and ready to redeem your offer',
    'picsyCheckinPage.xfs.store.subtitle': "We'll be right with you to complete your sale.",
    'unified.checkInPage.title': 'Next, tell us about your visit',
    'unified.checkInPage.subtitle': "Today, I'm here to:",
    'unified.checkInPage.subtitle.kioskWithAppointment': 'Is this still the plan today?',
    'unified.checkInPage.other.reason.validationErrorMessage': 'Other reason cannot be empty.',
    'unified.checkInPage.buttonLabel': 'Next',
    'unified.getOffer.title': 'Get a real offer in minutes',
    'unified.getOffer.sendSms': 'Send me a link by text message',
    'unified.getOffer.continueHere': 'Or continue on this device',
    'unified.getOffer.continueHereCta': 'Continue',
    'unified.getOffer.smsSent': 'Link sent. Closing session',
    'unified.completePage.header': "You're checked in",
    'unified.completePage.instructionsNotEpu':
        "Keep your phone handy; we'll keep you updated and let you know when it is your turn.",
    'unified.completePage.instructionsWait':
        'Please have a seat in the waiting area and we will be with you shortly.',
    'unified.completePage.instructionsWaitOrBrowse':
        'Please have a seat in the waiting area or feel free to walk the lot.',
    'unified.completePage.instructionsEpuXf': "We'll be right with you to complete your purchase.",
    'unified.completePage.instructionsEpuTrad1':
        'Come to the Business Office and let us know you\'re here for an "express pickup".',
    'unified.completePage.instructionsEpuTrad2':
        "We'll confirm your purchase and answer any questions you have.",
    'unified.completePage.gotItButtonLabel': 'Got It',
    'unified.completePage.browse': "We'll be right with you to help you browse our lot.",
    'unified.completePage.testDrive':
        "We'll be right with you to get you ready for your test drive",
    'unified.completePage.financing': "We'll be right with you to get your financing prepared.",
    'unified.visitReason.onlinePurchase': 'Express pickup',
    'unified.doYouHaveAppointment.question': 'Do you have an appointment today?',
    'unified.landingPage.subtitle': "Let's get you checked in",
    'unified.landingPage.titleInKiosk': "Let's find your appointment",
    'unified.landingPage.subtitleInKiosk': 'Enter your phone number',
    'unified.landingPage.phoneNumberLabel': 'Mobile Phone #',
    'unified.landingPage.phoneNumberRequiredErrorMessage': 'Phone number must be entered.',
    'unified.landingPage.phoneNumberInvalidErrorMessage': 'Please enter a valid phone number.',
    'unified.landingPage.phoneInstructions':
        'This is how we check for a scheduled appointment.  We’ll also text you when we’re ready to help you.',
    'unified.landingPage.tryAgainTitle': 'Try again',
    'unified.landingPage.tryAgainDetails': "That number didn't match any appointment for today",
    'appointmentDetails.header': 'Appointment details',
    'appointmentDetails.directions': 'Get Directions',
    'preCheckIn.header': 'Get ready to meet your car',
    'preCheckIn.instructionsHeader': 'When you arrive',
    'preCheckIn.instructionsStep1':
        'Once you have parked, click the link below to let us know that you are here.',
    'preCheckIn.instructionsStep2':
        'Come inside the store to the Welcome Desk and let them know that you are here for your “express pickup”.',
    'preCheckIn.instructionsStep3':
        'We’ll take it from there! You’ll be driving home before you know it.',
    'preCheckIn.requirements.info': 'Here’s a few reminders to help you prepare.',
    'preCheckIn.requirements.buy.header': 'To purchase your car you’ll need',
    'preCheckIn.requirements.buy.item1':
        'Your <i>driver’s license</i>, <i>insurance info</i>, and <i>payment method</i>.',
    'preCheckIn.requirements.trade.header': 'If trading in your car you’ll need',
    'preCheckIn.requirements.trade.item1': 'Car title and, if applicable,  payoff information.',
    'preCheckIn.requirements.trade.item1Details':
        'All titleholders should be present. If this is not possible, call your store to see what type of documentation you will need to bring.',
    'preCheckIn.requirements.trade.item2': 'Valid, state-issued photo ID for all titleholders.',
    'preCheckIn.requirements.trade.item3': 'Valid, current vehicle registration.',
    'preCheckIn.requirements.trade.item4': 'All keys, fobs, and remotes for the vehicle.',
    'preCheckIn.questions.title': 'Questions about Express Pickup?',
    'preCheckIn.questions.faq': 'Get answers in our <a>FAQ</a>',
    'preCheckIn.questions.phone':
        'You can also reach us at <a>***</a> and ask to speak to the "Business Office".',
    'pciArrived.cta': "I'm here",
    'pciArrived.instructions': 'When you arrive at the store, tap "I\'m here"',
    // PCI v2
    // Welcome
    'pci.welcome.heading': 'Ready to meet your car',
    'pci.welcome.intro': 'You scheduled an express pickup for your',
    'pci.welcome.continue': 'Continue to see everything you need to bring with you.',
    'pci.welcome.continueCta': 'What to Bring',
    'pci.reschedule.trigger': 'I need to reschedule or cancel',
    'pci.reschedule.heading': 'Need to reschedule or cancel?',
    'pci.reschedule.callUs':
        'Just give us a call at <a>***</a> and ask to speak to the "Business Office".',
    // Items to Bring
    'pci.items.heading': 'Be sure to bring',
    'pci.items.continueCta': 'Confirm Appointment',
    'pci.items.requiredDocuments': 'Required <a>Documents</a>.',
    'pci.items.identification.label': 'Identification',
    'pci.items.identification.value': "Current driver's license for all signers",
    'pci.items.insurance.label': 'Insurance',
    'pci.items.insurance.value': 'Auto Insurance Card',
    'pci.items.finance.label': 'Finance Documentation',
    'pci.items.finance.stipTPresent': 'Social Security Card',
    'pci.items.finance.stipTUnknown': 'Social Security Card (if applicable)',
    'pci.items.payment.label': 'Payment Types',
    'pci.items.vehicle.label': 'Vehicle',
    'pci.items.vehicle.value': 'The vehicle you are trading in.',
    'pci.items.title.label': 'Title',
    'pci.items.title.title.value': 'Car title',
    'pci.items.title.payoff.notDone': 'Payoff information',
    'pci.items.title.payoff.unknown': 'Payoff information (if applicable)',
    'pci.items.title.multiple.heading': 'Title Holders',
    'pci.items.title.multiple.subHeading':
        '*All titleholders should be present. If this is not possible, call your store to see what type of documentation you will need to bring.',
    'pci.items.registration.label': 'Registration',
    'pci.items.registration.value': 'Current vehicle registration',
    'pci.items.keys.label': 'Keys',
    'pci.items.keys.value': 'All keys and remotes for the vehicle',
    //Down Payment Section
    'pci.items.payment.unknown':
        'Specific payment method as discussed previously with your associate',
    'pci.items.payment.debit.value': 'Debit Card',
    'pci.items.payment.debit.alert.heading': 'Debit Card',
    'pci.items.payment.debit.alert.subHeading':
        'Call your bank to increase any daily limits prior to arriving at the store.',
    'pci.items.payment.check.value': 'Check',
    'pci.items.payment.check.alert.heading': 'Check with name & address pre-printed',
    'pci.items.payment.check.alert.subHeading':
        'Bring your bank login credentials in case we need to verify funds.',
    'pci.items.payment.lienHolderCheck.value': 'Lien Holder Check',
    'pci.items.payment.lienHolderCheck.alert.heading':
        'Lien holder check and all instructions from lien holder',
    'pci.items.payment.cash.value': 'Cash',
    'pci.items.payment.cash.alert.heading': 'Cash $$',
    'pci.items.payment.bankDraft.value': 'Bank Draft',
    'pci.items.payment.bankDraft.alert.heading':
        'Bank Draft form and all instructions from lien holder',
    'pci.items.payment.ePay.value': 'ePay',
    'pci.items.payment.ePay.alert.heading': 'ePay',
    'pci.items.payment.ePay.alert.subHeading':
        'Complete ePay prior to arriving at the store. Bring your bank login credentials to verify funds. (show only if total exceeds $45K)',
    'pci.common.personalItems.alert.heading': 'Personal Items',
    'pci.common.personalItems.alert.subHeading':
        'Remember to remove all personal items from your trade-in vehicle before your appointment.',
    'pci.confirm.heading': 'Confirm your appointment',
    'pci.confirm.detailsTitle': 'Appointment details',
    'pci.directions.confirmed': 'Confirmed',
    'pci.directions.heading': "Let us know when you're on the way",
    'pci.directions.cta': "I'm on the way",
    'pci.directions.ctaInfo':
        "This will send a message to your Carmax associate that you're on your way so we can be ready.",
    'pci.directions.actionFootNote':
        '* By clicking, "I\'m on my way", your phone will not be tracked via location services. We purely use this to inform our associates that you\'re coming, so we can be ready.',
    'pci.parking.heading': "Park and let us know you're here",
    'pci.parking.info1': 'You can find Express Pickup parking near the front of the store.',
    'pci.parking.info2':
        "This will send a message to your Carmax associate that you're here and ready to finalize your purchase.",
    'pci.parking.cta': "I'm here",
    'pci.complete.heading': 'Great, we are ready for you.',
    'pci.complete.info':
        'Express Pickup signage inside the Store will direct you to the Business Office.',
    'pci.missed.heading': 'Sorry we missed you!',
    'pci.missed.info': "Let's reschedule your Express Pickup by calling <a>***</a>",
    'pci.postPurchase.heading': 'Congratulations on your new car',
    'pci.postPurchase.servicesHeading': 'Additional Services you may be interested in.',
    'pci.postPurchase.maxCareInfo': 'MaxCare is an optional extended service plan that makes every mile you drive worry-free. It provides repair coverage beyond the manufacturer’s warranty and the CarMax limited warranty.',
    'pci.postPurchase.learnMore': 'Learn more',
    'pci.questions.documentsLink': 'Required <a>Documents</a>.',
    'pci.questions.documentsHeading': 'Required Documents',
    // common
    'common.next': 'Next',
    'common.back': 'Back',
    'common.submit': 'Submit',
    'common.confirm': 'Confirm',
    'common.yes': 'Yes',
    'common.no': 'No',
    'common.welcome': 'Welcome to CarMax',
    'common.date': 'Date',
    'common.time': 'Time',
    'common.store': 'Store',
};
