import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './VisitReasons.module.scss';
import { Checkbox } from '@kmx/legos-react-checkbox';
import { TextField } from '@kmx/legos-react-text-field';
import { validateRequiredString } from '../../utils/validators';

import {
    UNIFIED_CHECKIN_PAGE_OTHER_REASON_TEXT_INPUT_MIN_LENGTH,
    UNIFIED_CHECKIN_PAGE_OTHER_REASON_TEXT_INPUT_MAX_LENGTH,
} from '../../constants/inputValidationConstants';
import CheckButtonGroup from '../checkButtons/CheckButtonGroup';
import { isKioskUX } from '../../utils/kioskMode';

export default function VisitReasons(props) {
    const {
        title,
        selectedVisitReasons,
        setSelectedVisitReasons,
        otherReasonTextInputLabel,
        otherReasonTextInput,
        setOtherReasonTextInput,
        otherReasonTextInputMaxLength,
        otherReasonTextInputInputValidationErrorMessage,
        isOtherSelected,
    } = props;

    const [textInputWasTouched, setTextInputWasTouched] = useState(false);

    const onVisitReasonChange = (evt) => {
        setSelectedVisitReasons(oldSelection => oldSelection.map((o) =>
            o.value !== evt.value ? o : { ...o, isChecked: !o.isChecked },
        ));
    };

    const handleInputChange = (e) => {
        setOtherReasonTextInput(e.target.value);
    };

    const onFocus = () => {
        // TODO: can add fullstory activity logging here, if needed
    };

    const getValidationError = () => {
        return validateRequiredString(
            otherReasonTextInput,
            'Other reason',
            UNIFIED_CHECKIN_PAGE_OTHER_REASON_TEXT_INPUT_MIN_LENGTH,
            UNIFIED_CHECKIN_PAGE_OTHER_REASON_TEXT_INPUT_MAX_LENGTH,
            otherReasonTextInputInputValidationErrorMessage,
        );
    };

    const otherReasonTextInputControlDivContainer = (
        <div className={styles.textInput}>
            <TextField
                id="otherReasonTextInput"
                onChange={handleInputChange}
                label={otherReasonTextInputLabel}
                value={otherReasonTextInput}
                onBlur={() => setTextInputWasTouched(true)}
                onFocus={() => onFocus()}
                validationStatus={!textInputWasTouched ? null : getValidationError() ? 'invalid' : 'valid'}
                helperText={getValidationError()}
                maxLength={otherReasonTextInputMaxLength}
            />
        </div>
    );

    const isKiosk = isKioskUX();
    const options = selectedVisitReasons.filter((o) => o.isDisplayElement && (!isKiosk || o.isInKiosk));

    return (
        <div className={cx(styles.container, { [styles.nonKiosk]: !isKiosk })}>
            {isKiosk ? (
                <CheckButtonGroup
                    options={options}
                    onChange={setSelectedVisitReasons}
                    className={styles.kioskButtonGroup}
                    buttonClassName={styles.kioskButton}
                />
            ) : (
                <fieldset className={'kmx-flex-wrapper'}>
                    <legend className={styles.label}>{title}</legend>

                    {options.map((option, index) => (
                        <Checkbox
                            className={styles.checkboxes}
                            id={`visit-reasons-${index}`}
                            key={`visit-reasons-${index}`}
                            label={option?.label}
                            required={true}
                            value={option?.value}
                            checked={option?.isChecked}
                            onChange={(event) => onVisitReasonChange(event.target)}
                        />
                    ))}
                </fieldset>
            )}
            {isOtherSelected ? otherReasonTextInputControlDivContainer : null}
        </div>
    );
}

VisitReasons.propTypes = {
    title: PropTypes.string.isRequired,
    otherReasonTextInputLabel: PropTypes.string,
    otherReasonTextInput: PropTypes.string.isRequired,
    setOtherReasonTextInput: PropTypes.func.isRequired,
    otherReasonTextInputMaxLength: PropTypes.number.isRequired,
    otherReasonTextInputInputValidationErrorMessage: PropTypes.string.isRequired,
    selectedVisitReasons: PropTypes.array.isRequired,
    setSelectedVisitReasons: PropTypes.func.isRequired,
    isOtherSelected: PropTypes.bool,
};
