import React, { useEffect } from 'react';
import useInitVisuals from '/src/hooks/useInitVisuals';
import { FormattedMessage } from 'react-intl';
import styles from './PCIInfoPage.module.scss';
import EpuVehicle from '/src/components/EpuVehicle';
import expressPickupImg from '/src/assets/express-pickup.png';
import RequirementsCard, { RequirementsType } from './RequirementsCard';
import AppointmentInfo from '/src/components/AppointmentInfo';
import { ExternalRoutes } from '/src/constants/env';
import useAppointmentAndStore from '/src/api/useAppointmentAndStore';
import { PreCheckInSteps } from '/src/flows/steps';
import useAdobePci from '../../v2/common/hooks/useAdobePci';
import { setFsDetails } from '/src/utils/fullstory';
import { usePreCheckinRedirect } from '/src/hooks/usePreCheckinRedirect';
import { FormattedMessageWithLink } from '/src/utils/intlHelpers';
import { pciSteps } from '/src/flows/PreCheckIn/pciSteps';

export default function PCIInfoPage() {
    usePreCheckinRedirect();
    const isTradeIn = true; // for now, we show the related info to everybody
    // const isTradeIn = Boolean(appointment.appointmentTypes?.includes(SalesForceVisitReasons.Appraisal));

    const { isFetchComplete, isDataInvalid, appointment, store } = useAppointmentAndStore();

    useInitVisuals(isFetchComplete);
    useAdobePci(PreCheckInSteps.PCIInfo);

    useEffect(() => {
        if (isFetchComplete && !isDataInvalid) {
            setFsDetails({
                acquisitionChannel: 'SMS',
                storeNumber: store?.id,
                appointmentType: 'EPU',
                storeType: store?.storeFormat,
                appointment: 'Yes',
            });
        }
    }, [isFetchComplete]);

    const pciArrivedUrl = window.location.href.replace(
        pciSteps.v1.info.path,
        pciSteps.v1.arrived.path,
    );

    return (
        isFetchComplete &&
        !isDataInvalid && (
            <div className={styles.container}>
                <div className={styles.title}>
                    <h1 className="kmx-typography--display-5">
                        <FormattedMessage id="preCheckIn.header" />
                    </h1>
                </div>
                <AppointmentInfo
                    appointmentDatetime={appointment.appointmentDatetime}
                    store={store}
                    withHeader={false}
                />
                <EpuVehicle {...appointment} />
                <div className={styles.instructions}>
                    <h2 className="kmx-typography--display-2">
                        <FormattedMessage id="preCheckIn.instructionsHeader" />
                    </h2>
                    <ol>
                        <li>
                            <FormattedMessage tagName="div" id="preCheckIn.instructionsStep1" />
                            {/* do NOT change link's id - it's used in analytics */}
                            <a id="go-to-arrival" href={pciArrivedUrl}>
                                {pciArrivedUrl}
                            </a>
                        </li>
                        <FormattedMessage tagName="li" id="preCheckIn.instructionsStep2" />
                        <FormattedMessage tagName="li" id="preCheckIn.instructionsStep3" />
                    </ol>
                </div>
                <img
                    src={expressPickupImg}
                    alt="express pickup"
                    className={styles.expressPickupImg}
                />
                <div className={styles.requirements}>
                    <FormattedMessage tagName="p" id="preCheckIn.requirements.info" />
                    <RequirementsCard reqType={RequirementsType.Buy} />
                    {isTradeIn && <RequirementsCard reqType={RequirementsType.Trade} />}
                </div>
                <div className={styles.questions}>
                    <div className={styles.questionsTitle}>
                        <FormattedMessage tagName="p" id="preCheckIn.questions.title" />
                    </div>
                    {/* do NOT change div's ID, it's used in adobe analytics */}
                    <div id="pre-check-in-faq">
                        <FormattedMessageWithLink
                            tagName="p"
                            id="preCheckIn.questions.faq"
                            url={`${ExternalRoutes.Www}/buying-online#buyonlinefaq`}
                        />
                    </div>
                    <FormattedMessageWithLink
                        tagName="p"
                        id="preCheckIn.questions.phone"
                        url="tel:(770)734-1130 "
                    />
                </div>
            </div>
        )
    );
}
