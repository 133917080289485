import React from 'react';
import styles from './ParkingSpotNumberPage.module.scss';
import { useIntl } from 'react-intl';
import ParkingSpotPageSelector from '../../components/ParkingSpotPageSelector/ParkingSpotPageSelector';
import { LICENSE_PLATE_TEXT_INPUT_FIELD_MAX_LENGTH } from '../../constants/inputValidationConstants';
import useInitVisuals from "../../hooks/useInitVisuals";

export default function ParkingSpotNumberPage() {
    const { formatMessage } = useIntl();
    useInitVisuals();

    return (
        <div className={styles.container}>
            <ParkingSpotPageSelector
                title={formatMessage({id: 'parkingSpotNumberPage.title'})}
                subtitle={formatMessage({id: 'parkingSpotNumberPage.subtitle'})}
                licensePlateInputLabel={formatMessage({id: 'parkingSpotNumberPage.licensePlateInputLabel'})}
                licensePlateTextInputFieldMaxLength={LICENSE_PLATE_TEXT_INPUT_FIELD_MAX_LENGTH}
                licensePlateRequiredValidationErrorMessage={formatMessage({id: 'parkingSpotNumberPage.licensePlateRequiredValidationErrorMessage'})} />
        </div>
    );
}
