// api routes without the prefix
const routes = {
    addressSuggestionsApi:
        '/smart-address/address-suggestions?SearchTerm={value}&MaxResults={count}',
    smartAddressApi: '/smart-address/smart-address?address={address}',
    appraisalAppointmentsApi: (offerCode?: string) => '/appointments' + (offerCode ? `/${offerCode}` : ''),
    salesforceAppointmentsApi: (phoneNumber: string) => `/appointments?phone=${phoneNumber}`,
    salesforceAppointmentsById: (id: string, includeOrderData = false) =>
        `/appointments/id/${id}?includeOrderData=${includeOrderData}`,
    sendToSalesforceApi: '/appointments/send',
    storesApi: '/stores',
    storeType: (storeId: number | string) => `/stores/${storeId}`, // storeId is a number, but cypress can use wildcards
    quotesApi: (offerCode?: string) => (offerCode ? `/quotes/${offerCode}` : '/quotes'),
    exteriorColorsApi: '/vehicle-specifications/exterior-colors',
    sendIOV: '/iov/send',
    getConfigurationApi: '/configuration',
    getFeatureFlagsApi: (storeId: number | string) => `/feature-flags/${storeId}`,
    storeDataApi: (storeId: number | string) => `/stores/availability/${storeId}`,
    appraisalVerificationStatus: (storeId: number, vin: string) =>
        `/appraisal?storeId=${storeId}&vin=${vin}`,
    postTripEvent: '/trip-events',
};

const apiPrefix = '/checkin/api';

// api routes, including prefix
export const apiRoutes = new Proxy(routes, {
    // the proxy preserves types
    get: (target, route: keyof typeof routes) =>
        typeof target[route] === 'string'
            ? apiPrefix + target[route]
            : (...args: any) => apiPrefix + (target[route] as any)(...args),
});

export const UNIFIED_LANDING_FLOW_BASE_ROUTE = '/unified';
export const INSTANT_OFFER_FLOW_BASE_ROUTE = '/';

export const CONFLICT_STATUS_CODE = 409;
export const DEFAULT_API_RETRIES = 3;
export const DEFAULT_API_RETRY_DELAY_MS = 1000;

/** @deprecated Use StoreData types */
export const STORE_HOURS_AVAILABILITY = {
    OPEN: 'Open',
    CLOSED: 'Closed',
    CLOSING_SOON: 'ClosingSoon',
};

export const HTTP_STATUS = {
    IDLE: 'IDLE',
    FETCHING: 'FETCHING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
};

export type HttpStatusType = typeof HTTP_STATUS[keyof typeof HTTP_STATUS];
