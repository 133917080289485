import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './IOCheckInPage.module.scss';
import { useIntl } from 'react-intl';
import { fsEvents, logFs } from '/src/utils/fullstory';
import { Button } from '@kmx/legos-react-button';
import CarImage from '../../assets/CarImage.png';
import CarLogoImage from '../../components/CarLogoImage/CarLogoImage';
import CarTitle from '../../components/CarImageTitle/CarImageTitle';
import IOCheckInPageDataInputSwitcher from '../../components/IOCheckInPageDataInputSwitcher/IOCheckInPageDataInputSwitcher';
import CheckInTextInput from '../../components/CheckInTextInput/CheckInTextInput';
import ErrorMessageLabel from '../../components/ErrorMessageLabel/ErrorMessageLabel';
import StoreSelector from '../../components/StoreSelector/StoreSelector';
import StateDropdownSelector from '../../components/StateDropdownSelector/StateDropdownSelector';
import {
    OfferContext,
    useCheckIn,
    useErrorHandling,
    useNavigation,
    useSpinner,
    useStore,
} from '/src/context';
import IOSteps from '../../flows/steps/IOSteps';
import { findAppointment, findQuotes } from '/src/api/sharedApiCalls';
import stateDropdownOptions from '../../constants/states';
import { checkInOfferRequestSource } from '../../constants/offer';
import { storeTypes } from '../../constants/storeTypes';
import StoreHoursCardContainer from '../../components/StoreHours/StoreHoursCardContainer';
import ExclamationImage from '../../assets/store_closed_exclamation.png';
import { sourceSystems } from '../../constants/sourceSystems';

import {
    LICENSE_PLATE_TEXT_INPUT_FIELD_MAX_LENGTH,
    LICENSE_PLATE_TEXT_INPUT_FIELD_MIN_LENGTH,
    OFFER_CODE_TEXT_INPUT_FIELD_MAX_LENGTH,
    OFFER_CODE_VALID_LENGTHS,
} from '../../constants/inputValidationConstants';
import { useEnterToMoveOn } from '/src/hooks/useEnterToMoveOn';
import useInitVisuals from '../../hooks/useInitVisuals';
import { getFromUrl } from '../../utils/urlHelpers';
import { trackStep } from '../../utils/adobe/analytics';
import trackEvent from '../../utils/adobe/trackEvent';
import { StoreData, StoreGeneric, StoreStatus } from '/src/interfaces/StoreData';
import { Quote } from '/src/interfaces/Quote';
import { getOfferCodeFromUrl, INVALID_OFFER_CODE } from '/src/utils/urlHelpers';

export default function IOCheckInPage({ stores }: { stores: StoreGeneric[] }) {
    const { formatMessage } = useIntl();
    const urlParamStoreId = getFromUrl.validatedStore();
    const isStoreIdInUrl = Boolean(urlParamStoreId);

    const { offer, setOffer } = useContext(OfferContext);
    const [checkInInfo, setCheckInInfo] = useCheckIn();
    const { setOffers } = useContext(OfferContext);
    const { setStep } = useNavigation();

    const offerCodeFromUrl = useRef(getOfferCodeFromUrl()); // calculating it only once
    const [offerCode, setOfferCode] = useState(
        offerCodeFromUrl.current && offerCodeFromUrl.current !== INVALID_OFFER_CODE
            ? offerCodeFromUrl.current
            : '',
    );

    // isLoadingStore & isPendingSubmit are used to avoid disabling the submit button after
    // a store is selected, so the UX is smoother. If the user does click submit before
    // the store is fetched, the function will wait until the store is fetched (spinner on)
    const [isLoadingStore, setIsLoadingStore] = useState(false);
    const [isPendingSubmit, setIsPendingSubmit] = useState(false);

    const [licensePlate, setLicensePlate] = useState(offer?.licensePlate);
    const [state, setState] = useState(''); // state of the USA, not of React
    const [dropDownErrorMessage, setDropdownErrorMessage] = useState('');
    const [checkInOfferSource, setCheckInOfferSource] = useState(
        checkInOfferRequestSource.OFFER_CODE,
    );
    const [offerWasFound, setOfferWasFound] = useState(true);

    const [textInputHasSpecialCharacters, setTextInputHasSpecialCharacters] = useState(false);
    const [textInputHasSpaces, setTextInputHasSpaces] = useState(false);
    const [storeData] = useStore();
    const storeStatus = storeData?.status ?? StoreStatus.Open;
    const { setError } = useErrorHandling();
    const [, showSpinner] = useSpinner();

    useInitVisuals();

    useEffect(() => {
        if (!offerCodeFromUrl && !isStoreIdInUrl) {
            logFs(fsEvents.checkInPage.onLoad, undefined, true);
        }
    }, []);

    const runIsAppraisalCheckoutRedirectWorkflow = (storeType) => {
        logFs(fsEvents.checkInPage.isAppraisalCheckout, undefined, true);

        if (storeType?.storeFormat === storeTypes.XF) {
            setStep(IOSteps.ExpressCheckInXFStoreCompletePage);
        } else {
            setStep(IOSteps.ExpressCheckInTraditionalStoreCompletePage);
        }
    };

    useEffect(() => {
        if (isPendingSubmit) {
            if (isLoadingStore) {
                showSpinner(true);
            } else {
                onSubmit().then(() => {
                    setIsPendingSubmit(false);
                });
            }
        }
    }, [isPendingSubmit, isLoadingStore]);

    async function onSubmit() {
        showSpinner(true);
        let code = offerCode;

        trackEvent.instantOffer.checkIn.click4(!offerWasFound);
        try {
            if (
                storeData?.storeFormat === storeTypes.XF ||
                storeData?.storeFormat === storeTypes.Traditional
            ) {
                setOffer((prevState) => ({ ...prevState, storeType: storeData?.storeFormat }));
            } else {
                setError.general('Invalid store type', { additionalInfo: { storeData } });
                return;
            }
            logFs(fsEvents.checkInPage.findMyOfferButton, undefined, true);

            setCheckInInfo((previous) => ({ ...previous, storeNumber: storeData.id }));

            const offerSearchResult = await findMyOffer();
            if (offerSearchResult) {
                if (offerSearchResult.length > 1) {
                    setOffers(offerSearchResult);
                    setStep(IOSteps.ActiveOffersPage);
                } else {
                    const foundOffer: Quote = offerSearchResult;
                    setOffer((prevState) => ({
                        ...prevState,
                        ...foundOffer,
                        email: foundOffer.metaData?.emailAddress ?? '',
                        exteriorColorCode: foundOffer.specifications?.exteriorColorCode,
                    }));
                    setCheckInInfo((prevState) => ({
                        ...prevState,
                        email: foundOffer.metaData?.emailAddress ?? '',
                    }));
                    // If the offer is an appraisal checkout, redirect to the express dropoff completed page
                    if (foundOffer?.isAppraisalCheckout === true) {
                        runIsAppraisalCheckoutRedirectWorkflow(storeData);
                    } else if (foundOffer?.sourceSystem?.toLowerCase() === sourceSystems.picsy) {
                        setStep(
                            storeData?.storeFormat === storeTypes.Traditional
                                ? IOSteps.PicsyCheckInStoreCompletePage
                                : IOSteps.PicsyCheckInXFSStoreCompletePage,
                        );
                        //    ----------  TEMPORARILY DISABLE "IF VERIFIED, THEN COMPLETE"  ----------
                        // } else if (await getAppraisalVerificationStatus(store?.id, foundOffer.vin)) {
                        //     setStep(
                        //         storeData?.storeFormat === storeTypes.Traditional
                        //             ? IOSteps.AlreadyVerifiedTraditionalPage
                        //             : IOSteps.AlreadyVerifiedXFPage,
                        //     );
                    } else {
                        let appraisalAppointment = await findAppointment(foundOffer.code);
                        if (checkInInfo.phoneNumber)
                            appraisalAppointment.phoneNumber = checkInInfo.phoneNumber;

                        setOffer((prevState) => ({
                            ...prevState,
                            ...(appraisalAppointment || { code }),
                        }));

                        setStep(IOSteps.ConfirmSingleOfferPage);
                    }
                }
            }
        } catch (error) {
            setError.general('Failed fetching offer and appointment', {
                error,
                additionalInfo: { offerCode },
            });
        }
    }

    async function findMyOffer() {
        let offer = null;

        const result = await findQuotes(
            checkInOfferSource === checkInOfferRequestSource.OFFER_CODE
                ? { offerCode }
                : { licensePlate, state },
        );

        if (result?.length > 0) {
            setOfferWasFound(true);
            if (result?.length > 1) {
                offer = result;
            } else if (result?.length === 1) {
                offer = result[0];
            }
        } else {
            showSpinner(false);
            setOfferWasFound(false);

            trackStep(IOSteps.CheckInNoActiveOffers.description);

            logFs(fsEvents.checkInPage.noActiveOffersFound);
        }

        return offer;
    }

    const isFindMyOfferButtonDisabled = () => {
        if (checkInOfferSource && checkInOfferSource === checkInOfferRequestSource.OFFER_CODE) {
            return (
                offerCode?.length === 0 ||
                (!isLoadingStore && !storeData?.id) ||
                !OFFER_CODE_VALID_LENGTHS.includes(offerCode.length) ||
                textInputHasSpecialCharacters === true ||
                storeStatus === StoreStatus.ClosingSoon ||
                storeStatus === StoreStatus.Closed
            );
        } else {
            return (
                licensePlate === undefined ||
                licensePlate?.length === 0 ||
                (!isLoadingStore && !storeData?.id) ||
                state?.length === 0 ||
                licensePlate?.length < LICENSE_PLATE_TEXT_INPUT_FIELD_MIN_LENGTH ||
                licensePlate?.length > LICENSE_PLATE_TEXT_INPUT_FIELD_MAX_LENGTH ||
                textInputHasSpaces === true ||
                storeStatus === StoreStatus.ClosingSoon ||
                storeStatus === StoreStatus.Closed
            );
        }
    };

    useEnterToMoveOn(
        () => setIsPendingSubmit(true),
        null,
        () => !isFindMyOfferButtonDisabled(),
    );

    const offerCodeTextInputDivContainer = (
        <div className={styles.inputControlWidth}>
            <CheckInTextInput
                label={formatMessage({ id: 'checkInPage.offerCodeTextInputLabel' })}
                requiredValidationErrorMessage={formatMessage({
                    id: 'checkInPage.offerCodeTextInputRequiredValidationErrorMessage',
                })}
                specialCharactersValidationErrorMessage={formatMessage({
                    id: 'checkInPage.offerCodeTextInputSpecialCharactersValidationErrorMessage',
                })}
                textInputFieldMaxLength={OFFER_CODE_TEXT_INPUT_FIELD_MAX_LENGTH + 2} // 2 extra characters allowed for easy copy pasting
                source={checkInOfferSource}
                setSource={setCheckInOfferSource}
                offerCode={offerCode}
                setOfferCode={setOfferCode}
                textInputHasSpecialCharacters={textInputHasSpecialCharacters}
                setTextInputHasSpecialCharacters={setTextInputHasSpecialCharacters}
                textInputHasSpaces={textInputHasSpaces}
                setTextInputHasSpaces={setTextInputHasSpaces}
            />
        </div>
    );

    const licensePlateTextInputDivContainer = (
        <div className={styles.inputControlWidth}>
            <CheckInTextInput
                label={formatMessage({ id: 'checkInPage.licensePlateTextInputLabel' })}
                requiredValidationErrorMessage={formatMessage({
                    id: 'checkInPage.licensePlateRequiredValidationErrorMessage',
                })}
                spacesFoundInTextInputValidationErrorMessage={formatMessage({
                    id: 'checkInPage.licensePlateSpacesFoundValidationErrorMessage',
                })}
                textInputFieldMaxLength={LICENSE_PLATE_TEXT_INPUT_FIELD_MAX_LENGTH}
                source={checkInOfferSource}
                setSource={setCheckInOfferSource}
                licensePlate={licensePlate}
                setLicensePlate={setLicensePlate}
                textInputHasSpecialCharacters={textInputHasSpecialCharacters}
                setTextInputHasSpecialCharacters={setTextInputHasSpecialCharacters}
                textInputHasSpaces={textInputHasSpaces}
                setTextInputHasSpaces={setTextInputHasSpaces}
            />
        </div>
    );

    const stateDropdownDivContainer = (
        <div className={styles.inputControlWidth}>
            <StateDropdownSelector
                label={formatMessage({ id: 'checkInPage.stateSelectorDropdownLabel' })}
                dataSource={stateDropdownOptions}
                state={state}
                setState={setState}
                dropDownErrorMessage={dropDownErrorMessage}
                setDropdownErrorMessage={setDropdownErrorMessage}
                errorMessageText={formatMessage({
                    id: 'checkInPage.stateSelectorErrorMessageHelperText',
                })}
            />
        </div>
    );

    const offerNotFoundDivContainer = (
        <div className={styles.inputControlWidth}>
            <ErrorMessageLabel
                errorMessage={formatMessage({ id: 'checkInPage.findMyOfferNotFoundErrorMessage' })}
            />
        </div>
    );

    const storeHoursClosingSoonSectionDivContainer = (
        <div className={styles.inputControlWidth} style={{ marginTop: '20px' }}>
            <StoreHoursCardContainer
                storeHours={storeData}
                storeHoursLabel={formatMessage({ id: `storeHoursPage.storeHoursLabel` })}
                openTodayLabel={formatMessage({ id: `storeHoursPage.openTodayLabel` })}
                message={formatMessage({ id: `errorPage.checkInDisabled.storeClosingSoonMessage` })}
                icon={ExclamationImage}
            />
        </div>
    );

    const storeHoursClosedSectionDivContainer = (
        <div className={styles.inputControlWidth} style={{ marginTop: '20px' }}>
            <StoreHoursCardContainer
                storeHours={storeData}
                storeHoursLabel={formatMessage({ id: `storeHoursPage.storeHoursLabel` })}
                openTodayLabel={formatMessage({ id: `storeHoursPage.openTodayLabel` })}
                message={formatMessage({ id: `errorPage.checkInDisabled.storeClosedMessage` })}
                icon={ExclamationImage}
            />
        </div>
    );

    return (
        <div className={styles.contentWrapper}>
            <div className={styles.carImageBackground} />
            <div className={styles.container}>
                <CarTitle title={formatMessage({ id: 'checkInPage.title' })} />
                <CarLogoImage carImage={CarImage} />
                <div className={styles.inputControlWidth}>
                    <IOCheckInPageDataInputSwitcher
                        title={formatMessage({ id: 'checkInPage.dataInputSwitcherTitle' })}
                        option1Label={formatMessage({
                            id: 'checkInPage.dataInputSwitcherOption1Label',
                        })}
                        option2Label={formatMessage({
                            id: 'checkInPage.dataInputSwitcherOption2Label',
                        })}
                        source={checkInOfferSource}
                        setSource={setCheckInOfferSource}
                        hasNoActiveOffers={!offerWasFound}
                    />
                </div>

                {checkInOfferSource === checkInOfferRequestSource.OFFER_CODE
                    ? offerCodeTextInputDivContainer
                    : licensePlateTextInputDivContainer}

                {checkInOfferSource === checkInOfferRequestSource.LICENSE_PLATE
                    ? stateDropdownDivContainer
                    : null}

                <div className={styles.inputControlWidth}>
                    <StoreSelector
                        label={formatMessage({ id: 'checkInPage.storeSelectorLabel' })}
                        dataSource={stores}
                        dropdownErrorMessage={formatMessage({
                            id: 'checkInPage.storeSelectorErrorMessageHelperText',
                        })}
                        setIsLoadingStore={setIsLoadingStore}
                    />
                </div>

                {!offerWasFound ? offerNotFoundDivContainer : null}

                {storeStatus === StoreStatus.Open
                    ? null
                    : storeStatus === StoreStatus.ClosingSoon
                    ? storeHoursClosingSoonSectionDivContainer
                    : storeHoursClosedSectionDivContainer}

                <div className={styles.findMyOfferButtonContainer}>
                    <Button
                        id="find-my-offer-button"
                        onClick={() => setIsPendingSubmit(true)}
                        disabled={isFindMyOfferButtonDisabled()}
                        className={styles.findMyOfferButton}
                        level="primary"
                    >
                        {formatMessage({ id: 'checkInPage.findMyOfferButtonLabel' })}
                    </Button>
                </div>
            </div>
        </div>
    );
}
