import {OFFER_CODE_VALID_LENGTHS} from "../constants/inputValidationConstants";
import { useIntl } from 'react-intl';

const VALID_STORE_ID_LENGTH = 4;

// TODO:
// 1. refactor validateOptionalString and validateRequiredString (break down, remove duplicated logic)
// 2. add internationalization - go through useValidation

export function validateRequiredString(value, name, minLength, maxLength, requiredMessage) {
    if (!value?.trim())
        if (requiredMessage)
            return requiredMessage;
        else
            return `${name} must be entered.`;

    var length = value.length;

    if (length == null || length == 0) {
        return `${name} must be entered.`;
    } else if (minLength != null && maxLength != null) {
        if (minLength === maxLength)
            if (length != minLength)
                return `${name} must be ${minLength} characters.`;
            else
                return null;
        else if (length < minLength || length > maxLength)
            return `${name} must be between ${minLength} and ${maxLength} characters.`;
        else
            return null;
    } else if (minLength != null) {
        if (length < minLength)
            return `${name} must be at least ${minLength} characters.`;
        else
            return null;
    } else if (maxLength != null) {
        if (length > maxLength)
            return `${name} must be less than ${maxLength} characters.`;
        else
            return null;
    } else
        return null;
}

const validateField = (callback, errorMessage) => actualFieldValue => callback(actualFieldValue) ? null : errorMessage;

export const useValidation = () => {
    const { formatMessage } = useIntl();
    return {
        validateOfferCode: validateField(
            code => OFFER_CODE_VALID_LENGTHS.includes(code.length),
            formatMessage({ id: 'validation.offerCodeInvalid' })
        ),
        validateRequired: validateField(
            x => x || x === 0 || (typeof x === 'string' && x.trim()),
            formatMessage({ id: 'validation.requiredField' })
        ),
    };
};

export function validateOptionalString(value, name, minLength, maxLength) {
    if (!value?.trim())
        return null;

    var length = value.length;

    if (length == null || length == 0) {
        return `${name} must be entered.`;
    } else if (minLength != null && maxLength != null) {
        if (minLength === maxLength)
            if (length != minLength)
                return `${name} must be ${minLength} characters.`;
            else
                return null;
        else if (length < minLength || length > maxLength)
            return `${name} must be between ${minLength} and ${maxLength} characters.`;
        else
            return null;
    } else if (minLength != null) {
        if (length < minLength)
            return `${name} must be at least ${minLength} characters.`;
        else
            return null;
    } else if (maxLength != null) {
        if (length > maxLength)
            return `${name} must be less than ${maxLength} characters.`;
        else
            return null;
    } else
        return null;
}


export function validateEmail(email, requiredMessage, invalidMessage) {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email == '' || email == null || email == undefined)
        if (requiredMessage)
            return requiredMessage;
        else
            return 'Email address must be entered.';
    else if (!emailRegex.test(email))
        if (invalidMessage)
            return invalidMessage;
        else
            return 'Please enter a valid email address.';
    else
        return null;
}

export const isValidPhoneNumber = phoneNumber => {
    const phoneRegex = /^\(?([2-9][0-9]{2})\)?[-. ]?([2-9](?!11)[0-9]{2})[-. ]?([0-9]{4})$/;
    const numbersOnlyPhoneNumber = phoneNumber?.replace(/[^0-9]/g, '');
    return phoneRegex.test(numbersOnlyPhoneNumber);
};

export function validatePhoneNumber(phoneNumber, requiredMessage, invalidMessage) {
    if (!phoneNumber)
        if (requiredMessage)
            return requiredMessage;
        else
            return 'Phone number must be entered.';
    else if (!isValidPhoneNumber(phoneNumber)) {
        if (invalidMessage)
            return invalidMessage;
        else
            return 'Please enter a valid phone number';
    }else
        return null;
}

export function validateSpecialCharacters(textInput, invalidMessage) {
    // regex validates for any special characters/symbols and spaces
    const cleanStringRegEx = /^(\d|\w)+$/;

    if (!cleanStringRegEx.test(textInput)) {
        return invalidMessage;
    } else {
        return null;
    }
}

export function validateForSpaces(textInput, invalidMessage) {
    // regex validates for any spaces found in text input
    const cleanStringRegEx = /^\S*$/;

    if (!cleanStringRegEx.test(textInput)) {
        return invalidMessage;
    } else {
        return null;
    }
}

export function validateStoreId(storeId) {
    // Verify that storeId is between 1000-9999 with no alpha characters
    return !!storeId && storeId.length == VALID_STORE_ID_LENGTH && !isNaN(storeId);
}

export function validateOfferCodeId(offerCodeId) {
    return OFFER_CODE_VALID_LENGTHS.includes(offerCodeId.length);
}
