import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SalesforceAppointment } from '/src/interfaces/SalesforceAppointment';
import { useMediaQuery } from '/src/utils/mediaQueries/useMediaQueries';
import { StoreData } from '/src/interfaces/StoreData';
import styles from './AppointmentDetailsCard.module.scss';
import { formatPhoneNumber } from '/src/utils';
import IconCall from '@kmx/legos-icons-essentials/assets/svg/call.svg';

interface Props {
    appointment: SalesforceAppointment;
    // children to be inserted at the end of the card's content
    children: JSX.Element | JSX.Element[];
    store: StoreData;
    onClickAddress?: () => void;
}

export default ({ appointment, children, store, onClickAddress }: Props) => {
    const { isDesktop } = useMediaQuery();
    const { appointmentDatetime } = appointment;

    const directionsUrl = 'https://www.google.com/maps/dir/?api=1&destination=' +
          encodeURI(`${store.addressLine1}, ${store.stateAbbreviation} ${store.zipCode}`);

    return (
        <hzn-card
            elevation={isDesktop ? 'none' : 'medium'}
            padding="medium"
            class={styles.container}
            border-radius="medium"
        >
            <hzn-stack space="medium">
                <hzn-heading as="h2" size="small" align="center">
                    <FormattedMessage id="pci.confirm.detailsTitle" />
                </hzn-heading>
                <hzn-stack space="small">
                    <div className={styles.detailsRow}>
                        <hzn-text class={styles.label} tone="subdued">
                            <FormattedMessage id="common.date" />:
                        </hzn-text>
                        <hzn-text weight="bold" size="small">
                            {appointmentDatetime?.toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            })}
                        </hzn-text>
                    </div>
                    <div className={styles.detailsRow}>
                        <hzn-text class={styles.label} tone="subdued">
                            <FormattedMessage id="common.time" />:
                        </hzn-text>
                        <hzn-text weight="bold" size="small">
                            {appointmentDatetime?.toLocaleTimeString('en-US', {
                                hour: 'numeric',
                                minute: '2-digit',
                                timeZoneName: 'short',
                            })}
                        </hzn-text>
                    </div>
                </hzn-stack>
                <hr className={styles.dashed} />

                <div className={styles.detailsRow}>
                    <hzn-text class={styles.label} tone="subdued">
                        <FormattedMessage id="common.store" />:
                    </hzn-text>
                    <hzn-text tone="brand" weight="bold" size="small">
                        <div className={styles.storeRow} onClick={onClickAddress}>
                            <a href={directionsUrl} target="_blank">
                                {store.addressLine1},<br />
                                {store.city}, {store.stateAbbreviation} {store.zipCode}
                            </a>
                        </div>
                        <div className={styles.storeRow}>
                            <div className={styles.iconWrapper}>
                                <IconCall />
                            </div>
                            <a href={`tel: ${store.phoneLocal}`}>
                                {formatPhoneNumber(store.phoneLocal)}
                            </a>
                        </div>
                    </hzn-text>
                </div>
                <hr className={styles.dashed} />

                {children}
            </hzn-stack>
        </hzn-card>
    );
};
