import React from 'react';
import AppointmentCalendar from '../../assets/appointment-calendar.svg';
import styles from './AppointmentInfo.module.scss';
import { FormattedMessage } from 'react-intl';
import { StoreData } from '../../interfaces/StoreData';
import cx from 'classnames';

interface IProps {
    withHeader: boolean;
    appointmentDatetime: Date;
    store: StoreData;
}

export default function AppointmentInfo({ appointmentDatetime, store, withHeader }: IProps) {
    const formattedDateTime = appointmentDatetime.toLocaleDateString('en-us', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    });
    const directionsUrl =
        'https://www.google.com/maps/dir/?api=1&destination=' +
        encodeURI(`${store.addressLine1}, ${store.stateAbbreviation} ${store.zipCode}`);

    return (
        <div className={styles.appointmentCard}>
            <AppointmentCalendar />
            {withHeader && (
                <p className="kmx-typography--display-2">
                    <FormattedMessage id="appointmentDetails.header" />
                </p>
            )}
            <p
                className={
                    withHeader
                        ? cx('kmx-typography--body-2', styles.addressLineWithHeader)
                        : 'kmx-typography--display-2'
                }
            >
                {formattedDateTime}
                <br />
                {store.name}
            </p>
            <p>
                {/* do NOT change link's id - it's used in analytics */}
                <a id="directions-link" href={directionsUrl}>
                    <FormattedMessage id="appointmentDetails.directions" />
                </a>
            </p>
        </div>
    );
}
