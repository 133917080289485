import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { trackStep } from '../utils/adobe/analytics';
import IOSteps from './steps/IOSteps';
import { mapStoresData, mapExteriorColors } from '../utils/transformData';
import IOActiveOffersPage from '../containers/IOActiveOffersPage/IOActiveOffersPage';
import IOCheckInPage from '../containers/IOCheckInPage/IOCheckInPage';
import IOCompletePage from '../containers/IOCompletePage/IOCompletePage';
import IOConfirmOfferPage from '../containers/IOConfirmOfferPage/IOConfirmOfferPage';
import IOCustomerInfoPage from '../containers/IOCustomerInfoPage/IOCustomerInfoPage';
import IOVehicleInfoPage from '../containers/IOVehicleInfoPage/IOVehicleInfoPage';
import IOExpressCompletePage from '../containers/IOExpressCompletePage/IOExpressCompletePage';
import IOLandingPage from '../containers/IOLandingPage/IOLandingPage';
import ParkingSpotNumberPage from '../containers/ParkingSpotNumberPage/ParkingSpotNumberPage';
import {
    OfferContext,
    useNavigation,
    useErrorHandling,
    useStore
} from '../context';
import IOQuickCompletionPage from '../containers/IOPicsyCompletePage/IOQuickCompletionPage';
import { getStoresData, getExteriorColorsData } from '../api/sharedApiCalls';
import {
    getValidatedStoreIdFromUrl,
    getOfferCodeFromUrl,
    INVALID_OFFER_CODE,
    INVALID_STORE_ID,
} from '../utils/urlHelpers';
import unifiedSteps from './steps/unifiedSteps';
import { StoreType } from "../interfaces/StoreData";
import { setFsDetails } from "../utils/fullstory";

export default function InstantOffer() {

    const { step, setStep, getPreviousStep } = useNavigation();

    const { offer, setOffer } = useContext(OfferContext);
    const { offers } = useContext(OfferContext);

    const [stores, setStores] = useState([]);
    const [exteriorColors, setExteriorColors] = useState([]);
    const { setError } = useErrorHandling();
    const [contextStoreData] = useStore();

    const runInStoreIOCheckInWorkFlow = () => {
        const storeId = getValidatedStoreIdFromUrl();
        const offerCode = getOfferCodeFromUrl();
        if (offerCode && storeId) {
            if (offerCode !== INVALID_OFFER_CODE && storeId !== INVALID_STORE_ID) {
                setOffer((prevState) => ({ ...prevState, offerCode: offerCode }));
                setStep(IOSteps.CheckInPage);
            } else {
                setError.general('Invalid url params', { additionalInfo: { offerCode, storeId } });
            }
        } else {
            setStep(
                getPreviousStep(1) === unifiedSteps.UnifiedAppointmentPage
                    ? IOSteps.CheckInPage
                    : IOSteps.LandingPage,
            );
        }
    };

    useEffect(() => {
        if (step) {
            trackStep(step.description);
        }
    }, [step]);

    useEffect(() => {
        runInStoreIOCheckInWorkFlow();
        // preload stores data and exterior colors data. This should not block content visualization
        Promise.all([getStoresData(), getExteriorColorsData()])
            .then(([storesData, exteriorColorsData]) => {
                if (storesData !== null) {
                    const mappedStoresData = mapStoresData(storesData);
                    setStores(mappedStoresData);
                } else {
                    setError.general('Stores data is null');
                }

                if (exteriorColorsData !== null) {
                    const mappedExteriorColorsData = mapExteriorColors(exteriorColorsData);
                    setExteriorColors(mappedExteriorColorsData);
                } else {
                    setError.general("Couldn't fetch exterior colors (null)");
                }
            })
            .catch((error) => {
                setError.general('Failed processing stores or exterior colors', { error });
            });
    }, []);

    useEffect( () => {
        setFsDetails({
            acquisitionChannel: 'Sign',
            storeNumber: contextStoreData?.id,
            appointmentType: 'IO',
            storeType: contextStoreData?.storeFormat,
        });
    }, []);

    function renderStep() {
        switch (step) {
        case IOSteps.ParkingSpotNumberPage:
            return <ParkingSpotNumberPage />;
        case IOSteps.CheckInPage:
            return (
                <IOCheckInPage
                    stores={stores}
                />
            );
        case IOSteps.ActiveOffersPage:
            return <IOActiveOffersPage offers={offers} />;
        case IOSteps.ConfirmSingleOfferPage:
            return <IOConfirmOfferPage offerValue={offer} />;
        case IOSteps.CustomerInfoPage:
            return <IOCustomerInfoPage />;
        case IOSteps.VehicleInfoPage:
            return <IOVehicleInfoPage exteriorColors={exteriorColors} />;
        case IOSteps.CompletePage:
            return <IOCompletePage />;
        case IOSteps.ExpressCheckInTraditionalStoreCompletePage:
        case IOSteps.ExpressCheckInXFStoreCompletePage:
            return <IOExpressCompletePage />;
        case IOSteps.PicsyCheckInStoreCompletePage:
            return <IOQuickCompletionPage storeType={StoreType.Traditional} reasonOfCompletion="picsy" />;
        case IOSteps.AlreadyVerifiedTraditionalPage:
            return <IOQuickCompletionPage storeType={StoreType.Traditional} reasonOfCompletion="alreadyVerified" />;
        case IOSteps.PicsyCheckInXFSStoreCompletePage:
            return <IOQuickCompletionPage storeType={StoreType.XF} reasonOfCompletion="picsy" />;
        case IOSteps.AlreadyVerifiedXFPage:
            return <IOQuickCompletionPage storeType={StoreType.XF} reasonOfCompletion="alreadyVerified" />;
        case IOSteps.LandingPage:
        default:
            return <IOLandingPage />;
        }
    }

    return renderStep();
}

InstantOffer.propTypes = {
    title: PropTypes.string,
    storeIdInUrl: PropTypes.bool,
};
