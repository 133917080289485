import { useIntl } from 'react-intl';
import { Validate } from 'react-hook-form';

/** @return true if valid */
type ValidatorCallback = (value: string) => boolean;

type ValidatorDefinition = (...args: any) => ValidatorCallback;

export type FieldValidators = Record<string, ValidatorCallback>;

export const useFieldValidation = () => {
    const { formatMessage } = useIntl();
    return {
        applyValidators:
            (validators: FieldValidators): Validate<string> =>
            (value) =>
                Object.entries(validators)
                    .map(
                        ([messageId, callback]) =>
                            // validating should yield true if ok; false or an error string if not ok
                            callback(value) || formatMessage({ id: messageId }),
                    )
                    // return the first error message (or true if all good)
                    .reduce((acc, crt) => (acc === true ? crt : acc), true),
    };
};

export const required: ValidatorDefinition = () => (v) => Boolean(v?.trim());

export const maxLen: ValidatorDefinition = (len: number) => (v) => Boolean(v.length <= len);
export const minLen: ValidatorDefinition = (len: number) => (v) => Boolean(v.length >= len);

export const pattern: ValidatorDefinition = (regex:  RegExp) => v => regex.test(v);
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const emailPattern: ValidatorDefinition = () => v => emailRegex.test(v);
const phoneRegex = /^\(?([2-9][0-9]{2})\)?[-. ]?([2-9](?!11)[0-9]{2})[-. ]?([0-9]{4})$/;
export const phonePattern: ValidatorDefinition = () => v => phoneRegex.test(v);
