import React, {  } from 'react';
import styles from  './EpuVehicle.module.scss';
import { Card } from '@kmx/legos-react-card';
import classNames from "classnames";
import { useIntl } from "react-intl";
import { VehicleSummary } from '../../interfaces/Vehicle';
import { ExternalRoutes } from '../../constants/env';

export default function EpuVehicle({ year, make, model, stockNumber, trim }: VehicleSummary) {
    const carImageUrl=`${ExternalRoutes.Images}/vehicles/${stockNumber}/1.jpg?width=100`;
    const { formatMessage } = useIntl();
    return (
        <Card flat={false} className={styles.carCard}>
            <img src={carImageUrl}  alt='car-image' />
            <h3 className={classNames("kmx-typography--display-2", styles.yearMake)}>
                {year} {make} {model}
            </h3>
            <h3 className={classNames("kmx-typography--display-2", styles.model)}>
                {trim}
            </h3>
            <div className={classNames("kmx-typography--display-1", styles.visitReason)}>
                {formatMessage({id: 'unified.visitReason.onlinePurchase'})}
            </div>
        </Card>
    );
}
