import { useState, useEffect } from 'react';
import { getSmartAddress } from '../api/sharedApiCalls';
import Address from '../interfaces/address';
import {useIntl} from "react-intl";

const defaultAddress = {
    addressLine1: '',
    addressLine2: '',
    city: '',
    county: '',
    state: '',
    zipCode: '',
};

export const useSmartAddress = (address: string) => {
    const [smartAddress, setSmartAddress] = useState<Address>(defaultAddress);
    const [smartAddressError, setSmartAddressError] = useState<string>('');
    const [isSmartAddressFetching, setIsSmartAddressFetching] = useState(false);

    const { formatMessage } = useIntl();

    const addressNotFoundText = formatMessage({id: 'useSmartAddress.addressNotFound'});
    const searchErrorText = formatMessage({id: 'useSmartAddress.searchError'});

    const handleGetSmartAddress = async () => {
        try {
            const smartAddress = await getSmartAddress(address);
            if (
                smartAddress.name &&
                smartAddress.city &&
                smartAddress.county &&
                smartAddress.state &&
                smartAddress.zip
            ) {
                setSmartAddress({
                    addressLine1: `${smartAddress.primaryNumber || ''} ${smartAddress.preDirectional || ''} ${
                        smartAddress.name || ''
                    } ${smartAddress.suffix || ''} ${smartAddress.postDirectional || ''}`
                        .trim()
                        .replace(/\s{2,}/, ' '),
                    addressLine2: `${smartAddress.secondaryIdentifier || ''} ${smartAddress.secondaryNumber || ''}`
                        .trim()
                        .replace(/\s{2,}/, ' '),
                    city: smartAddress.city,
                    county: smartAddress.county,
                    state: smartAddress.state,
                    zipCode: smartAddress.zip.toString().padStart(5, '0'),
                    searchTerm: address,
                });
                setSmartAddressError('');
            } else {
                setSmartAddress(defaultAddress);
                setSmartAddressError(addressNotFoundText);
            }
        } catch (error: any) {
            if (error?.response?.status == '404') {
                setSmartAddress(defaultAddress);
                setSmartAddressError(addressNotFoundText);
            } else {
                setSmartAddress(defaultAddress);
                setSmartAddressError(searchErrorText);
            }
        }
    };

    useEffect(() => {
        if (address) {
            setIsSmartAddressFetching(true);
            handleGetSmartAddress().finally(() => setIsSmartAddressFetching(false));
        } else {
            setIsSmartAddressFetching(false);
            setSmartAddress(defaultAddress);
        }
        // TODO: effect cleanup (low priority; sometimes produces a warning)
    }, [address]);

    return { smartAddress, smartAddressError, isSmartAddressFetching };
};
