import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from  './ExteriorColorSelector.module.scss';
import { logFsEvent, buildEventName } from '../../utils/fullstory';
import { fullstoryConstants } from '../../constants/fullstory';
import ValidatedSelect from '../ValidatedSelect/ValidatedSelect';
import { OfferContext } from '../../context';

export default function ExteriorColorSelector(props) {
    const {
        label, dataSource, dropdownErrorMessage,
        exteriorColor, setExteriorColor,
        setIsValid
    } = props;

    const { offer, setOffer } = useContext(OfferContext);

    useEffect(() => {
        setIsValid(exteriorColorIsValid);
    }, [offer]);

    const [exteriorColorIsValid, setExteriorColorIsValid] = useState(false);

    useEffect(() => {
        setOffer(prevState => ({...prevState, exteriorColorCode: exteriorColor}));

        if (exteriorColor != '' && exteriorColor != null && exteriorColor.length != 0)
            setExteriorColorIsValid(true);
        else
            setExteriorColorIsValid(false);
    }, [exteriorColor]);

    const onFocus = () => {
        logFsEvent(buildEventName(fullstoryConstants.vehiclePage.name,
            fullstoryConstants.vehiclePage.events.exteriorColorDropdown));
    };

    return (
        <div id='exterior-color-area'>
            <legend className={styles.label} id='exterior-color-label'>{label}</legend>
            <ValidatedSelect
                options={dataSource}
                value={exteriorColor}
                disabled={false}
                onChange={event => setExteriorColor(event.target.value)}
                onFocus={() => onFocus()}
                id="exterior-color"
                name="exterior-color"
                displayHelperText={true}
                dropdownErrorMessage={dropdownErrorMessage} />
        </div>
    );
}

ExteriorColorSelector.propTypes = {
    label: PropTypes.string.isRequired,
    dataSource: PropTypes.array.isRequired,
    dropdownErrorMessage: PropTypes.string.isRequired,
    exteriorColor: PropTypes.string.isRequired,
    setExteriorColor: PropTypes.func.isRequired,
    setIsValid: PropTypes.func.isRequired
};
