import { removeNonNumericCharacters } from '../utils/index';
import { answer } from '../constants/yesNo';
import { NOT_APPLICABLE } from '../constants/parkingSpots';
import { hadSalesforceAppointment } from './salesforce';
import {
    AppraisalAppointment,
    AppraisalAppointmentBackendFormat
} from '../interfaces/AppraisalAppointment';
import {
    SalesforceAppointment,
    SalesforceAppointmentId
} from '../interfaces/SalesforceAppointment';
import { OfferInfo } from '/src/interfaces/OfferInfo';
import {
    ExteriorColor,
    ExteriorColorBackendFormat
} from '/src/interfaces/ExteriorColor';

export function mapAppraisalAppointmentData(appointmentData: AppraisalAppointmentBackendFormat): AppraisalAppointment {
    return !appointmentData ? null : {
        firstName: appointmentData.firstName ?? '',
        lastName: appointmentData.lastName ?? '',
        phoneNumber: appointmentData.phoneNumber ?? '',
        email: appointmentData.email ?? '',
        address: {street: '', cityStateZip: ''},
        apartment: '',
        code: appointmentData.appraisalCode,
    };
}

export function mapSalesforceAppointmentData(
    appointmentData,
    phoneNumber = '',
    storeNumber?: string
): SalesforceAppointment {
    return !appointmentData ? null : {
        phoneNumber: phoneNumber,
        appointmentId: appointmentData.appointmentId ?? '',
        appointmentTypes: appointmentData.appointmentTypes ?? [],
        appointmentDatetime: new Date(appointmentData.appointmentDatetime),
        workOrderStatus: appointmentData.workOrderStatus,
        stockNumber: appointmentData.stockNumber,
        make: appointmentData.vehicleSpecifications?.make,
        model: appointmentData.vehicleSpecifications?.model,
        trim: appointmentData.vehicleSpecifications?.trim,
        year: appointmentData.vehicleSpecifications?.year,
        mileage: appointmentData.vehicleSpecifications?.mileage,
        isSold: appointmentData.vehicleSpecifications?.isSold,
        price: appointmentData.vehicleSpecifications?.price,
        storeNumber: storeNumber ?? appointmentData.storeId?.toString() ?? '',
        customerName: appointmentData.customerName ?? '',
        description: '',
        hadSalesforceAppointment: hadSalesforceAppointment(appointmentData.appointmentTypes),
        crmId: appointmentData.crmId,
        ciamId: appointmentData.ciamId,
        ciId: appointmentData.ciId,
        preCheckInData: appointmentData.preCheckInData,
    } as SalesforceAppointment;
}

// maps OfferInfo to a backend format (for verification)
export const mapOfferData = (offer: OfferInfo, appointmentId: SalesforceAppointmentId) => !offer ? null : {
    appointmentId,
    storeNumber: parseInt(offer.storeNumber),
    storeFormat: offer.storeType,
    firstName: `${offer.firstName?.trim()}`,
    lastName: `${offer.lastName?.trim()}`,
    email: `${offer.email}`,
    phoneNumber: `${removeNonNumericCharacters(offer.phoneNumber)}`,
    exteriorColorCode: offer.exteriorColorCode,
    originalInstantOfferAmount: offer.valuation,
    vin: `${offer.vin}`,
    vehicleYear: `${offer.year}`,
    vehicleMake: `${offer.make?.trim()}`,
    vehicleModel: `${offer.model?.trim()}`,
    instantOfferId: `${offer.quoteId}`,
    instantOfferCode: `${offer.code}`,
    address: `${offer.address.street} ${offer.apartment != '' && offer.apartment != ' ' ? offer.apartment : ''} ${offer.address.cityStateZip}`,
    hasSafetyConcerns: offer.isVehicleSafeToDrive !== answer.YES,
    isPlanningToSell: offer.intentionToSell === answer.YES,
    isTradeIn: offer.intentionToSell === answer.YES ? offer.isVehicleTradeIn === answer.YES : null,
    isMakingPayments: offer.intentionToSell === answer.YES ? offer.isMakingPayments === answer.YES : null,
    customerParkingSpot: offer.customerParkingSpot !== undefined ? offer.customerParkingSpot : NOT_APPLICABLE,
    licensePlate: offer.licensePlate,
};

export function mapStoresData(storesData) {
    return storesData?.map(store => { return { value: store.id, label: store.name }; });
}

export function mapExteriorColors(exteriorColorsData: ExteriorColorBackendFormat[]): ExteriorColor[] {
    return exteriorColorsData?.map(color => { return { value: color.code, label: color.text }; });
}

export function mapAddressSuggestions(addressSuggestionData) {
    if (addressSuggestionData?.length != 0) {
        return addressSuggestionData?.map(suggestion => {
            const street = `${suggestion?.street ?? ''} ${suggestion?.secondary ?? ''}`;
            const cityStateZip = `${suggestion?.city ?? ''}, ${suggestion?.state ?? ''} ${suggestion?.zipCode ?? ''}`;
            const value = `${street}\n${cityStateZip}`;
            const label = `${street} ${cityStateZip}`;
            return { value: value, label: label, street: street, cityStateZip: cityStateZip};
        });
    } else {
        return null;
    }
}
