import React from 'react';
import { TextField } from '@kmx/legos-react-text-field';
import { Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import {
    FieldValidators,
    useFieldValidation
} from '/src/components/inputFields/validators';

interface Props {
    name: string;
    control: Control<any>;
    labelId: string;
    validators?: FieldValidators;
    textFieldProps?: any; // type TextFieldProps is not exported by legos, see text-field.d.ts
    transformDisplay?: (value: string) => string;
}
const defaultProps = {
    transformDisplay: (value) => value,
    validators: {},
};

export default function InputText(props: Props) {
    const { formatMessage } = useIntl();
    const { applyValidators } = useFieldValidation();

    const { name, control, textFieldProps, labelId, transformDisplay, validators } = {
        ...defaultProps,
        ...props,
    };

    return (
        <Controller
            name={name}
            control={control}
            rules={{
                validate: applyValidators(validators),
            }}
            render={({ field, fieldState }) => {
                const { value } = field;

                const validationStatus =
                    (fieldState.error?.message && 'invalid') ||
                    (Object.values(validators).every((cb) => cb(value)) && 'valid') ||
                    undefined;

                return (
                    <TextField
                        {...{ ...field, ref: null }} // `field` contains `ref` (not used), producing a warning
                        label={formatMessage({
                            id: labelId,
                        })}
                        helperText={fieldState.error?.message || ' '}
                        validationStatus={validationStatus}
                        value={value}
                        type="text"
                        inputMode="text"
                        onChange={(event) => {
                            field.onChange(transformDisplay(event.target.value));
                        }}
                        {...textFieldProps}
                    />
                );
            }}
        />
    );
}
