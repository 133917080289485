import React, { useEffect, useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './IOVehicleInfoPage.module.scss';
import './IOVehicleInfoPage.scss';
import { logFs, fsEvents } from '/src/utils/fullstory';
import { useIntl } from 'react-intl';
import { useNavigation, OfferContext, useCheckIn, useSpinner } from '/src/context';
import IOSteps from '../../flows/steps/IOSteps';
import { Button } from '@kmx/legos-react-button';
import { Steps } from '@kmx/acquisitions-steps';
import VehicleInfo from '../../components/VehicleInfo/VehicleInfo';
import { sendToSalesforce, sendIOV } from '/src/api/sharedApiCalls';
import intentionToSellOptions from '../../constants/intentionToSell';
import vehicleDriveabilityOptions from '../../constants/vehicleDriveability';
import { CONFLICT_STATUS_CODE } from '/src/constants/api';
import { trackIntentionToSell } from '../../utils/adobe/analytics';
import useInitVisuals from '../../hooks/useInitVisuals';
import { useErrorHandling } from '/src/context';
import { ExteriorColor } from '/src/interfaces/ExteriorColor';

interface Props {
    exteriorColors: ExteriorColor[];
}

export default function IOVehicleInfoPage({ exteriorColors }: Props) {
    const { formatMessage } = useIntl();

    const { offer } = useContext(OfferContext);
    const [checkInInfo] = useCheckIn();
    const { setStep } = useNavigation();
    const [, showSpinner] = useSpinner();
    const { setError } = useErrorHandling();

    // handling color: "Unknown" not allowed
    const unknownColorName = 'Unknown';
    const knownColors = exteriorColors.filter(({ label }) => label !== unknownColorName);
    // make sure that the color is in the allowed list:
    const validatedOfferColorCode =
        knownColors.find(({ value }) => value === offer?.specifications?.exteriorColorCode)?.value ?? '';
    const [exteriorColor, setExteriorColor] = useState(validatedOfferColorCode);

    const [intentionToSell, setIntentionToSell] = useState('');
    const [driveability, setDriveability] = useState('');
    const [isMakingPayments, setIsMakingPayments] = useState('');
    const [isVehicleTradeIn, setIsVehicleTradeIn] = useState('');
    const [vehicleInfoIsValid, setVehicleInfoIsValid] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);

    useInitVisuals();

    useEffect(() => {
        setSubmitDisabled(!vehicleInfoIsValid);
    }, [vehicleInfoIsValid]);

    // track whether the user selects color first or at all
    const hasChangedColor = useRef(false);
    const hasChangedOtherBeforeColor = useRef(false);
    const hasTrackedUnchangedColor = useRef(false);
    useEffect(() => {
        if (exteriorColor) {
            hasChangedColor.current = true;
        }
        if ((intentionToSell || driveability) && !hasChangedColor.current) {
            hasChangedOtherBeforeColor.current = true;
        }
    }, [exteriorColor, intentionToSell, driveability]);

    const setIntentionToSellAndTrack = (value) => {
        trackIntentionToSell(value);
        setIntentionToSell(value);
    };

    const onSubmit = async () => {
        logFs(fsEvents.vehiclePage.submitButton);
        if (hasChangedOtherBeforeColor.current) {
            logFs(fsEvents.vehiclePage.colorSelectedAfterOthers);
        }
        showSpinner(true);

        sendToSalesforce(checkInInfo).catch((error) => {
            setError.general('Failed sending check-in to Salesforce', {
                error,
                additionalInfo: { checkInInfo },
            });
        });

        sendIOV(offer, checkInInfo.appointmentId)
            .then(() => {
                setStep(IOSteps.CompletePage);
            })
            .catch((error) => {
                if (error.statusCode === CONFLICT_STATUS_CODE) {
                    setError.duplicateSubmission('Failed sending offer');
                } else {
                    setError.general('Failed sending offer', {
                        error,
                        additionalInfo: { offer },
                    });
                }

                setStep(IOSteps.ErrorPage);
            });
    };

    const stepDefinition = [
        {
            id: 'vehicle-info',
            className: 'vehicle-info-step',
            header: (
                <div id="vehicleStepHeader">
                    {formatMessage({ id: 'vehicleInfoPage.vehicleStepLabel' })}
                </div>
            ),
            body: (
                <VehicleInfo
                    vehicleInfo={offer}
                    exteriorColorOptions={knownColors}
                    intentionToSellOptions={intentionToSellOptions}
                    vehicleDriveabilityOptions={vehicleDriveabilityOptions}
                    exteriorColor={exteriorColor}
                    setExteriorColor={setExteriorColor}
                    intentionToSell={intentionToSell}
                    setIntentionToSell={setIntentionToSellAndTrack}
                    driveability={driveability}
                    setDriveability={setDriveability}
                    isMakingPayments={isMakingPayments}
                    setIsMakingPayments={setIsMakingPayments}
                    isVehicleTradeIn={isVehicleTradeIn}
                    setIsVehicleTradeIn={setIsVehicleTradeIn}
                    setIsValid={setVehicleInfoIsValid}
                />
            ),
            autoProceedOnComplete: false,
            onAttemptStepExpand: (stepIsDisabled) =>
                new Promise<void>((resolve, reject) => {
                    if (stepIsDisabled) reject();
                    else resolve();
                }),
        },
    ];

    return (
        <div className={styles.container}>
            <h4 className={classNames('kmx-typography--display-4', styles.header)}>
                {formatMessage({ id: 'vehicleInfoPage.header' })}
            </h4>
            <div className={styles.steps}>
                <Steps stepDefinition={stepDefinition} hostControlledStepExpansion={true} />
            </div>
            <div
                onClick={() => {
                    // if color is unchanged, the button is disabled and doesn't run its onClick,
                    // but the event propagates -> tracking in the parent
                    if (!hasChangedColor.current && !hasTrackedUnchangedColor.current) {
                        logFs(fsEvents.vehiclePage.colorNotSelected);
                        hasTrackedUnchangedColor.current = true;
                    }
                }}
            >
                {/* do NOT change button's ID, it's used in Adobe */}
                <Button
                    className={styles.primary}
                    level="primary"
                    disabled={submitDisabled}
                    onClick={onSubmit}
                    type="button"
                    id="vehicle-page--submit"
                >
                    {formatMessage({ id: 'vehicleInfoPage.submitButton' })}
                </Button>
            </div>
        </div>
    );
}
