import React from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from 'react-modal';
import IconClose from '@kmx/legos-icons-essentials/assets/svg/close.svg';
import { FormattedMessageWithLink } from '/src/utils/intlHelpers';
import { ExternalRoutes } from '/src/constants/env';
import { useMediaQuery } from '/src/utils/mediaQueries/useMediaQueries';
import styles from './Questions.module.scss';
import commonStyles from '/src/common.module.scss';
import cx from "classnames";
import DocumentList from "/src/flows/PreCheckIn/v2/ItemsToBring/DocumentList";
import useAppointmentAndStore from "/src/api/useAppointmentAndStore";
import { formatPhoneNumber } from '/src/utils';

interface Props {
    eventTrackers: {
        faq: () => void;
        docs: () => void;
        phone: () => void;
    };
}

export default ({ eventTrackers }: Props) => {
    const { store } = useAppointmentAndStore();
    const { isMobile } = useMediaQuery();
    const [isDocumentsModalOpen, setIsDocumentsModalOpen] = React.useState(false);
    const closeDocuments = () => setIsDocumentsModalOpen(false);
    const openDocuments = () => setIsDocumentsModalOpen(true);

    return (
        <div className={styles.container}>
            <hzn-stack space="large" align-x={isMobile ? 'start' : 'center'}>
                <hzn-heading
                    as="h3"
                    size="small"
                    style={{ marginTop: 0, marginBottom: 0 }}
                    align={isMobile ? 'left' : 'center'}
                >
                    <FormattedMessage id="preCheckIn.questions.title" />
                </hzn-heading>
                <hzn-stack space="base" align-x={isMobile ? 'start' : 'center'}>
                    <hzn-text tone="subdued">
                        <FormattedMessageWithLink
                            id="preCheckIn.questions.faq"
                            url={`${ExternalRoutes.Www}/buying-online#buyonlinefaq`}
                            onClick={eventTrackers.faq}
                        />
                    </hzn-text>

                    <hzn-text tone="subdued">
                        <FormattedMessageWithLink
                            id="pci.questions.documentsLink"
                            onClick={() => {
                                eventTrackers.docs();
                                openDocuments();
                            }}
                        />
                        <Modal
                            isOpen={isDocumentsModalOpen}
                            onRequestClose={closeDocuments}
                            className={cx(styles.dialog, commonStyles.modalWindow)}
                            overlayClassName={commonStyles.modalOverlay}
                            preventScroll={true}
                        >
                            <div className={styles.documentsHeader}>
                                <hzn-heading size="medium">
                                    <FormattedMessage id="pci.questions.documentsHeading" />
                                </hzn-heading>
                                <IconClose onClick={closeDocuments} className={styles.iconClose} />
                            </div>
                            <DocumentList />
                        </Modal>
                    </hzn-text>

                    {store?.phoneLocal && <hzn-text tone="subdued">
                        <FormattedMessageWithLink
                            id="preCheckIn.questions.phone"
                            url={`tel:${store.phoneLocal}`}
                            onClick={eventTrackers.phone}
                            linkText={formatPhoneNumber(store.phoneLocal)}
                        />
                    </hzn-text>}
                </hzn-stack>
            </hzn-stack>
        </div>
    );
};
