export default [
    {
        label: '1',
        value: '1',
    },
    {
        label: '2',
        value: '2',
    },
    {
        label: '3',
        value: '3',
    },
    {
        label: '4',
        value: '4',
    },
    {
        label: '5',
        value: '5',
    },
    {
        label: "I'm not in a numbered spot",
        value: "N/A"
    }
];

export const NOT_APPLICABLE = 'N/A';
