import { useEffect } from "react";
import useAppointmentAndStore from "/src/api/useAppointmentAndStore";
import { AdobeTrackerOptions, trackStep } from "/src/utils/adobe/analytics";

// adds eVar89 as appointmentId before setting the usual vars
const useAdobePci = (step: string, options?: AdobeTrackerOptions) => {
    const { appointment } = useAppointmentAndStore();
    useEffect(() => {
        if (appointment?.appointmentId) {
            trackStep(step, {
                ...options,
                eventVars: {
                    ...options?.eventVars,
                    eVar89: appointment.appointmentId,
                },
            });
        }
    }, [appointment]);
}
export default useAdobePci;
