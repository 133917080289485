import React, {useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import styles from './VehiclePayments.module.scss';
import { RadioButton } from '@kmx/legos-react-radio-button';
import { OfferContext } from '../../context';
import { logFsEvent, buildEventName } from '../../utils/fullstory';
import { fullstoryConstants } from '../../constants/fullstory';

export default function VehiclePayments(props) {
    const { label, dataSource, setIsMakingPayments, isMakingPayments, setIsValid} = props;
    const { setOffer } = useContext(OfferContext);

    useEffect(() => {
        setOffer(prevState => ({...prevState, isMakingPayments: isMakingPayments}));

        if (isMakingPayments !== '' && isMakingPayments !== null && isMakingPayments?.length !== 0) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [isMakingPayments]);

    const onFocus = () => {
        logFsEvent(buildEventName(fullstoryConstants.vehiclePage.name,
            fullstoryConstants.vehiclePage.events.paymentsChoice));
    };

    return (
        <fieldset className={"kmx-flex-wrapper"} test-id="vehicle-payment-options">
            <legend className={styles.label}>{label}</legend>
            {dataSource.map((option, index) =>
                <RadioButton
                    key={`vehicle-payment-${index}`}
                    id={`vehicle-payment-${index}`}
                    checked={isMakingPayments === option?.value}
                    label={option?.label}
                    name='vehicle-payment'
                    onChange={event => setIsMakingPayments(event.target.value)}
                    onFocus={() => onFocus()}
                    value={option?.value}/>
            )}
        </fieldset>
    );
}

VehiclePayments.propTypes = {
    label: PropTypes.string.isRequired,
    dataSource: PropTypes.array.isRequired,
    setIsMakingPayments: PropTypes.func.isRequired,
    isMakingPayments: PropTypes.string.isRequired,
    setIsValid: PropTypes.func.isRequired
};
