import React from 'react';
import { useIntl } from 'react-intl';
import styles from './PersonalItemsAlert.module.scss';

export default () => {
    const { formatMessage } = useIntl();

    return (
        <div className={styles.container}>
            <hzn-alert
                show={true}
                tone="info"
                heading={formatMessage({ id: 'pci.common.personalItems.alert.heading' })}
                sub-heading={formatMessage({ id: 'pci.common.personalItems.alert.subHeading' })}
            />
        </div>
    )
};
