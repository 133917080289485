import React from 'react';
import styles from './VehicleCard.module.scss';
import { VehicleSummary } from '/src/interfaces/Vehicle';
import { ExternalRoutes } from '/src/constants/env';
import { formatCurrencyWithoutCents, formatMileage } from '/src/utils';

export default function VehicleCard({
    year,
    make,
    model,
    stockNumber,
    trim,
    mileage,
    price,
}: VehicleSummary) {
    const carImageUrl = `${ExternalRoutes.Images}/vehicles/${stockNumber}/1.jpg?width=100`;
    return (
        <hzn-card elevation="flat" border-radius="medium" class={styles.card} padding="none">
            <img src={carImageUrl} alt="car-image" />
            <hzn-stack space="small">
                <hzn-text tone="info">
                    {year} {make}
                </hzn-text>
                <hzn-text tone="interactive">
                    {model} {trim}
                </hzn-text>
                <hzn-heading tone="neutral" size="small">
                    {formatCurrencyWithoutCents(price)} *
                    <div className={styles.smallBar} />
                    {formatMileage(mileage, true, true)}
                </hzn-heading>
            </hzn-stack>
        </hzn-card>
    );
}
