import React from 'react';
import cx from 'classnames';
import Logo from '../../assets/CarmaxLogo.svg';
import styles from './Header.module.scss';
import PropTypes from "prop-types";

export default function Header({ isSplashPage }) {
    return (
        <header className={isSplashPage ? styles.splashPage : cx("kmx-mini-header", styles.regularPage)}>
            <div className={isSplashPage ? styles.splashPositioner : "kmx-mini-header--logo-center kmx-mini-header--no-action-content"}>
                <Logo />
            </div>
        </header>
    );
}

Header.propTypes = {
    isSplashPage: PropTypes.bool,
};
