import React from 'react';
import PropTypes from 'prop-types';
import styles from './StoreHoursMessage.module.scss';

export default function StoreHoursMessage(props) {
    const { icon,  message } = props;

    return (
        <div className={styles.storeHoursMessageContainer} test-id='store-hours-message'>
            <div style={{ margin: 'auto 0 auto 8px'}}>
                <img src={icon} height="32" width="32" />
            </div>
            <div style={{ padding: '12px', width: '85%', textAlign: 'left', fontSize: '16px', color: '#6B0A0A'}} className={"kmx-typography--body-1"}>
                <span><strong>{message}</strong></span>
            </div>
        </div>
    );
}

StoreHoursMessage.propTypes = {
    icon: PropTypes.any.isRequired,
    message: PropTypes.string.isRequired
};
