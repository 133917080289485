import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ActiveOffersTitle.module.scss';

export default function ActiveOffersTitle(props) {
    const { title, subtitle } = props;

    return (
        <>
            <div className={styles.activeOffersTitleContainer}>
                <h3 className={classNames(styles.activeOffersTitle, "kmx-typography--display-4")} id='active-offers-title'>
                    {title}
                </h3>
            </div>
            <div className={styles.AativeOffersSubtitleContainer}>
                <h4 className={classNames(styles.activeOffersSubtitle, "kmx-typography--body-3")} id='active-offers-subtitle'>
                    {subtitle}
                </h4>
            </div>
        </>
    );
}

ActiveOffersTitle.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired
};

