import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './Item.module.scss';
import {
    DOWN_PAYMENT_OPTIONS,
    DownPayment,
    ItemValueDefinition,
    RenderComponentProps,
} from '/src/flows/PreCheckIn/v2/ItemsToBring/constants';
import { SalesforceAppointment } from '/src/interfaces/SalesforceAppointment';

const DisplayValue = ({
    value,
    appointment,
}: {
    value: ItemValueDefinition;
    appointment: SalesforceAppointment;
}) => {
    const messageIds = typeof value === 'function' ? value(appointment) : value;
    return !messageIds?.length ? null : (
        <>
            {messageIds.map((val, i) => (
                <span key={val}>
                    <FormattedMessage id={val} />
                    {i !== messageIds.length - 1 && ', '}
                </span>
            ))}
        </>
    );
};
export const PaymentItem = ({ label, appointment }: RenderComponentProps) => {
    const { formatMessage } = useIntl();

    const supportedDownPayments = [
        DownPayment.Debit,
        DownPayment.Check,
        DownPayment.Cash,
        DownPayment.LienHolderCheck,
        DownPayment.BankDraft,
        DownPayment.ePay,
    ];
    const downPaymentTypes = appointment?.preCheckInData?.downPaymentTypes?.filter((dp) =>
        (supportedDownPayments as string[]).includes(dp),
    )?.map((dp) => DOWN_PAYMENT_OPTIONS[dp]);

    const typesAsText = downPaymentTypes?.length
        ? downPaymentTypes.map(dpDef => dpDef.value)
        : ['pci.items.payment.unknown'];

        return (
            <Item label={label} value={typesAsText} appointment={appointment}>
                {downPaymentTypes?.length ?
                    <hzn-stack>
                        {downPaymentTypes?.map((dpDef) => (
                            <hzn-alert
                                key={dpDef.value}
                                show={!!dpDef.alert}
                                tone="info"
                                heading={formatMessage({ id: dpDef.alert.heading })}
                                sub-heading={
                                    dpDef.alert.subHeading &&
                                    formatMessage({ id: dpDef.alert.subHeading })
                                }
                            />
                         ))}
                    </hzn-stack> : null
                }
            </Item>
        );
};

export const TitleItem = ({ label, appointment }: RenderComponentProps) => {
    const { formatMessage } = useIntl();
    const numberOfTitleHolders = appointment?.preCheckInData?.numberOfTitleHolders ?? 2;
    const isPaidOff = appointment?.preCheckInData?.isPaidOff ?? null;
    const payoffInfo =
        (isPaidOff === null && ['pci.items.title.payoff.unknown']) ||
        (isPaidOff === false && ['pci.items.title.payoff.notDone']) ||
        [];

    return (
        <Item
            label={label}
            value={['pci.items.title.title.value', ...payoffInfo]}
            appointment={appointment}
        >
            <hzn-alert
                show={numberOfTitleHolders > 1 || undefined}
                tone="info"
                heading={formatMessage({ id: 'pci.items.title.multiple.heading' })}
                sub-heading={formatMessage({ id: 'pci.items.title.multiple.subHeading' })}
            />
        </Item>
    );
};

const Item = ({ label, value, children, appointment }: RenderComponentProps) => {
    return (
        <div className={styles.item}>
            <div className={styles.itemGrid}>
                <hzn-text variant="caption" tone="subdued">
                    <FormattedMessage id={label} />:
                </hzn-text>
                <hzn-text variant="text" className={styles.value}>
                    <DisplayValue value={value} appointment={appointment} />
                </hzn-text>
            </div>
            <div className={styles.children}>{children}</div>
        </div>
    );
};

export default Item;
