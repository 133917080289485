import React, { useMemo } from 'react';
import { matchPath, useHistory } from 'react-router-dom';
import { getAppointmentIdFromUrl } from '/src/utils/urlHelpers'

export const pciSteps = {
    v1: {
        info: {
            stepNumber: 1,
            name: 'PCI Info',
            path: '/pci-info',
            adobe: 'EPU',
        },
        arrived: {
            stepNumber: 2,
            name: 'PCI Arrived',
            path: '/pci-arrived',
            adobe: "I'm here",
        },
    },
    v2: {
        welcome: {
            stepNumber: 1,
            name: 'PCI Welcome',
            path: '/pre/:appointmentId/welcome',
            adobe: 'Start',
        },
        items: {
            stepNumber: 2,
            name: 'PCI Items',
            path: '/pre/:appointmentId/items-to-bring',
            adobe: 'Bring',
        },
        details: {
            stepNumber: 3,
            name: 'PCI Appointment Details',
            path: '/pre/:appointmentId/appointment-details',
            adobe: 'Appointment Details',
        },
        directions: {
            stepNumber: 4,
            name: 'PCI Directions',
            path: '/pre/:appointmentId/details',
            adobe: 'Confirmed',
        },
        parking: {
            stepNumber: 5,
            name: 'PCI Parking Details',
            path: '/pre/:appointmentId/parking',
            adobe: "On my way",
        },
        complete: {
            stepNumber: 6,
            name: 'PCI Complete',
            path: '/pre/:appointmentId/complete',
            adobe: "I'm Here",
        },
        postCheckIn: {
            stepNumber: null,
            name: 'PCI Post Check-in',
            path: '/pre/:appointmentId/post-check-in',
            adobe: 'Post Check-in',
        },
        missed: {
            stepNumber: null,
            name: 'PCI Missed Appointment',
            path: '/pre/:appointmentId/missed',
            adobe: 'Missed Appointment',
        },
        postPurchase: {
            stepNumber: null,
            name: 'PCI Post Purchase',
            path: '/pre/:appointmentId/post-purchase',
            adobe: 'Post Purchase',
        },
    },
};

// steps as sorted arrays, for convenience and speed
export const pciV1StepArray = Object.values(pciSteps.v1).sort(
    (a, b) => a.stepNumber - b.stepNumber,
);
export const pciV2StepArray = Object.values(pciSteps.v2).sort(
    (a, b) => (a.stepNumber ?? 999) - (b.stepNumber ?? 999),
);
export const pciAllStepArray = [...pciV1StepArray, ...pciV2StepArray];

// notes:
// - when not on PCI flow, currentStep and goToNextStep are undefined (useful in routing)
// - goToNextStep is also undefined if versions === 'all'
export const usePciSteps = (versions: 'v1' | 'v2' | 'all' = 'v2') => {
    const history = useHistory();
    const appointmentId = getAppointmentIdFromUrl();
    const currentPath = history.location.pathname;
    const pciStepArray = { all: pciAllStepArray, v2: pciV2StepArray, v1: pciV1StepArray }[versions];

   return useMemo(() => {
        const currentStepIndex = pciStepArray.findIndex((route) => {
            return matchPath(currentPath, {
                path: route.path,
                exact: true,
                strict: false,
            });
        });
        const currentStep = pciStepArray[currentStepIndex];
        const goToNextStep =
            versions !== 'all' &&
            0 <= currentStepIndex &&
            currentStepIndex < pciStepArray.length - 1
                ? () => {
                      history.push(
                          pciStepArray[currentStepIndex + 1].path.replace(
                              ':appointmentId',
                              appointmentId,
                          ),
                      );
                  }
                : undefined;

        return { goToNextStep, currentStep };
    }, [currentPath]);
};
