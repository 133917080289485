// The config values are used only in events that can be triggered after
// the configuration was loaded, so we don't need React contexts.

import { BaseConfiguration, Configuration } from '/src/interfaces/Configuration';
import { isKioskUX } from "../utils/kioskMode";

let config = {} as Configuration;

export const setConfiguration = (configuration: BaseConfiguration) => {
    const configWithDefaults: BaseConfiguration = {
        carmaxBaseUrl: 'https://www.carmax.com/',
        ...configuration,
    }
    config = {
        ...configWithDefaults,
        sellMyCarUrl: (storeId, zipCode = '') =>
            configWithDefaults.carmaxBaseUrl +
            `sell-my-car/partners/in-store?storeId=${storeId}` +
            (zipCode && `&zipCode=${zipCode}`) +
            `&kiosk=${isKioskUX() ? 'true' : 'false'}`,
    };
};

// configuration's props are read-only
export const configuration = new Proxy({} as Configuration, {
    get(target, prop) {
        return config[prop];
    },
});
