export enum WorkOrderStatus {
    New = 'New',
    Requested = 'Requested',
    Confirmed = 'Confirmed',
    Scheduled = 'Scheduled',
    Assigned = 'Assigned',
    InProgress = 'In Progress',
    Delayed = 'Delayed',
    Completed = 'Completed',
    Closed = 'Closed',
    Canceled = 'Canceled'
}
