/** @deprecated
 * Enums are a better fit, use enum UnifiedSteps in ../steps.ts.
 * For tracking it's already safe to use the enum.
 * For navigation, a gradual replacement is probably risky, better to replace all at once (TODO)
 */
const unifiedSteps = {
    UnifiedSplashPage: Symbol("Unified Splash Page"),
    DoYouHaveAppointment: Symbol("Unified Do-you-have-appointment Page"),
    UnifiedLandingPage: Symbol("Unified Landing Page"),
    UnifiedAppointmentPage: Symbol("Unified Appointment Page"),
    UnifiedGetOfferPage: Symbol("Unified Get Offer Page"),
    UnifiedCustomerInfoPage: Symbol('Unified Customer Info Page'),
    UnifiedCompletePage: Symbol("Unified Complete Page"),
    ErrorPage: Symbol("Error Page")
};

export default unifiedSteps;
