import React, { FC } from 'react';
import cx from 'classnames';
import styles from './CheckButton.module.scss';
import IconCheck from '@kmx/legos-icons-essentials/assets/svg/check.svg';

export default function CheckButton({
    label,
    isChecked,
    className,
    onClick,
    Icon,
}: {
    label: string;
    isChecked: boolean;
    // allows adding more styles to the container
    className?: string;
    onClick: () => void;
    Icon?: FC;
}) {
    return (
        <div
            className={cx('kmx-button', 'kmx-button--tertiary', 'kmx-button--raised', styles.button, className, {
                [styles.buttonChecked]: isChecked,
                [styles.buttonWithIcon]: !!Icon,
            })}
            onClick={onClick}
        >
            {isChecked && (
                <div className={styles.checkmark}>
                    <IconCheck />
                </div>
            )}
            {!!Icon && <div className={styles.icon}>{<Icon />}</div>}
            {label}
        </div>
    );
}
