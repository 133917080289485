import React from 'react';
import PropTypes from 'prop-types';
import styles from './ErrorPageHeader.module.scss';

export default function ErrorPageHeader(props) {
    const { title, subtitle, image } = props;

    return (
        <div>
            <div><img src={image} className={styles.icon} /></div>
            <div className={styles.title}><h4 className={"kmx-typography--display-4"}>{title}</h4></div>
            {subtitle && <div className={styles.subtitle}><h5 className={"kmx-typography--body-3"}>{subtitle}</h5></div>}
        </div>
    );
}

ErrorPageHeader.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    image: PropTypes.any.isRequired
};
