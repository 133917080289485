import { useCheckIn, OfferContext } from '../../context';
import React, { useContext, useEffect, useState } from 'react';

import CustomerInfoFields from '../CustomerInfoFields/CustomerInfoFields';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

export default function UnifiedCustomerContact({
    setIsValid,
    onCompleteChange
}) {
    const { formatMessage } = useIntl();
    const { offer } = useContext(OfferContext);
    const [, setCheckInInfo] = useCheckIn();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState(offer?.phoneNumber ?? '');

    const [contactInfoValid, setContactInfoValid] = useState(false);

    useEffect(() => {
        setIsValid(contactInfoValid);
        onCompleteChange(contactInfoValid);
    }, [contactInfoValid]);

    useEffect(() => {
        setCheckInInfo(prevState => ({...prevState, firstName }));
    }, [firstName]);

    useEffect(() => {
        setCheckInInfo(prevState => ({...prevState, lastName}));
    }, [lastName]);

    useEffect(() => {
        setCheckInInfo(prevState => ({...prevState, phoneNumber}));
    }, [phoneNumber]);


    return (
        <div id="unifiedCustomerContactBody">
            <h2 className='kmx-typography--display-3'>{formatMessage({id: 'customerContactPage.customerStepLabel'})}</h2>
            <CustomerInfoFields
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                setIsValid={setContactInfoValid}
            />
        </div>
    );
}

UnifiedCustomerContact.propTypes = {
    setIsValid: PropTypes.func.isRequired,
    onCompleteChange: PropTypes.any,
};
