import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from  './IntentionToSell.module.scss';
import { logFsEvent, buildEventName } from '../../utils/fullstory';
import { fullstoryConstants } from '../../constants/fullstory';
import { RadioButton } from '@kmx/legos-react-radio-button';
import { OfferContext } from '../../context';
import { answer } from '../../constants/yesNo';

export default function IntentionToSell(props) {
    const {
        label, dataSource,
        intention, setIntention,
        setIsMakingPaymentsValid,
        setIsVehicleTradeInValid,
        setIsValid
    } = props;

    const { setOffer } = useContext(OfferContext);

    useEffect(() => {
        setOffer(prevState => ({...prevState, intentionToSell: intention}));

        if (intention != '' && intention != null && intention.length != 0)
            setIsValid(true);
        else
            setIsValid(false);

        if (intention === answer.NO) {
            setIsMakingPaymentsValid(true);
            setIsVehicleTradeInValid(true);
        }

    }, [intention]);

    const onFocus = () => {
        logFsEvent(buildEventName(fullstoryConstants.vehiclePage.name,
            fullstoryConstants.vehiclePage.events.intentionToSellChoice));
    };

    return (
        <fieldset className={"kmx-flex-wrapper"}>
            <legend className={styles.label} id='intention-to-sell-label'>{label}</legend>
            {dataSource.map((option, index) =>
                <RadioButton
                    key={`intention-${index}`}
                    id={`intention-${index}`}
                    checked={intention === option?.value}
                    label={option?.label}
                    name='intention-to-sell'
                    onChange={event => setIntention(event.target.value)}
                    onFocus={() => onFocus()}
                    value={option?.value}/>
            )}
        </fieldset>
    );
}

IntentionToSell.propTypes = {
    label: PropTypes.string.isRequired,
    dataSource: PropTypes.array.isRequired,
    intention: PropTypes.string.isRequired,
    setIntention: PropTypes.func.isRequired,
    setIsMakingPaymentsValid: PropTypes.func.isRequired,
    setIsVehicleTradeInValid: PropTypes.func.isRequired,
    setIsValid: PropTypes.func.isRequired
};
