import useDataFetch from '../hooks/useDataFetch';
import { SalesforceAppointment } from '../interfaces/SalesforceAppointment';
import { getFeatureFlagsData, getSalesforceAppointmentById, getStoreData } from './sharedApiCalls';
import { StoreData } from '../interfaces/StoreData';
import { getAppointmentIdFromUrl } from '../utils/urlHelpers';
import { useEffect, useState } from 'react';
import { useErrorHandling } from '/src/context/errorHandling';
import { StoreFeature } from '/src/interfaces/StoreFeature';

interface AppointmentStoreFeaturesData {
    isFetchComplete: boolean;
    hasFetchedAppointment: boolean;
    hasFetchedStore: boolean;
    hasFetchedFeatures: boolean;
    isDataInvalid: boolean;
    appointment?: SalesforceAppointment;
    store?: StoreData;
    features?: StoreFeature;
}

// TODO: caching ended up cumbersome in reactive context, consider moving it to the API-call layer
const storeCache = {};
const appointmentCache = {};
const featuresCache = {};

export default (shouldFetchFeatures = false) => {
    const [cache, setCache] = useState<AppointmentStoreFeaturesData>({
        isFetchComplete: false,
        hasFetchedAppointment: false,
        hasFetchedStore: false,
        hasFetchedFeatures: false,
        isDataInvalid: false,
    });
    const appointmentId = getAppointmentIdFromUrl();

    // flags - if true, the api calls are not executed (caching will be used)
    const [hasStartedAppointment, setHasStartedAppointment] = useState(false);
    const [hasStartedStoreAndFeatures, setHasStartedStoreAndFeatures] = useState(false);
    const isAppointmentCached = !!appointmentCache[appointmentId];

    const { data: appointmentFresh, isFetchComplete: hasFetchedAppointmentNow } =
        useDataFetch<SalesforceAppointment>({
            callback: getSalesforceAppointmentById,
            args: [appointmentId, true],
            guard: () => !hasStartedAppointment && !isAppointmentCached,
        });
    const hasFetchedAppointment = hasFetchedAppointmentNow || isAppointmentCached;
    const appointment = appointmentCache[appointmentId] || appointmentFresh;

    useEffect(() => {
        setHasStartedAppointment(true);
    }, []);
    useEffect(() => {
        if (hasFetchedAppointment) {
            setHasStartedStoreAndFeatures(true);
        }
    }, [hasFetchedAppointment]);

    const storeId = appointment?.storeNumber;
    const isStoreCached = !!storeCache[storeId];

    const { data: storeFresh, isFetchComplete: hasFetchedStoreNow } = useDataFetch<StoreData>({
        callback: getStoreData,
        args: [storeId],
        guard: () =>
            !hasStartedStoreAndFeatures && hasFetchedAppointment && !!storeId && !isStoreCached,
        deps: [hasFetchedAppointment],
    });
    const hasFetchedStore = hasFetchedStoreNow || isStoreCached;
    const store = storeCache[storeId] || storeFresh;

    const areFeaturesCached = !!featuresCache[storeId];
    const { data: featuresFresh, isFetchComplete: hasFetchedFeaturesNow } =
        useDataFetch<StoreFeature>({
            callback: getFeatureFlagsData,
            args: [storeId],
            guard: () =>
                shouldFetchFeatures && // if !shouldFetchFeatures, do not make the api call, ever
                !hasStartedStoreAndFeatures &&
                hasFetchedAppointment &&
                !!storeId &&
                !areFeaturesCached,
            deps: [hasFetchedAppointment],
        });
    const hasFetchedFeatures = hasFetchedFeaturesNow || areFeaturesCached;
    const features = featuresCache[storeId] || featuresFresh;

    const isFetchComplete =
        hasFetchedAppointment && hasFetchedStore && (!shouldFetchFeatures || hasFetchedFeatures);

    const isAppointmentInvalid = hasFetchedAppointment && !appointment.appointmentId;
    const isStoreInvalid = hasFetchedStore && !store.id;
    const areFeaturesInvalid = hasFetchedFeatures && !features;
    const isDataInvalid = isAppointmentInvalid || isStoreInvalid || areFeaturesInvalid;

    const { setError } = useErrorHandling();
    // note: useEffect allows the parent component to finish rendering if needed
    useEffect(() => {
        if (isDataInvalid) {
            setError.general('Invalid pre-check-in data', {
                additionalInfo: { store, appointment },
            });
        }
    }, [isDataInvalid]);

    useEffect(() => {
        if (hasFetchedAppointmentNow && !isAppointmentCached)
            appointmentCache[appointmentId] = isAppointmentInvalid ? null : appointment;
        if (hasFetchedStoreNow && !isStoreCached)
            storeCache[storeId] = isStoreInvalid ? null : store;
        if (hasFetchedFeaturesNow && !areFeaturesCached)
            featuresCache[storeId] = areFeaturesInvalid ? null : features;
        setCache({
            isFetchComplete,
            hasFetchedAppointment,
            hasFetchedStore,
            hasFetchedFeatures,
            isDataInvalid,
            appointment,
            features,
            store,
        });
    }, [isFetchComplete, appointmentId]);

    return cache;
};
