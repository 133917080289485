import React, { useEffect } from 'react';
import { TextField } from '@kmx/legos-react-text-field';
import { Control, Controller } from 'react-hook-form';
import { isValidPhoneNumber } from '/src/utils/validators';
import { formatPhoneNumber } from '/src/utils/index';
import { useIntl } from 'react-intl';

interface Props {
    control: Control<any>;
    textFieldProps?: any; // type TextFieldProps is not exported by legos, see text-field.d.ts
    /** @deprecated Unnecessary, use formState.isValid */
    validStateSetter?: (isValid: boolean) => void;
}

export default function InputPhone({ control, textFieldProps, validStateSetter }: Props) {
    const { formatMessage } = useIntl();
    return (
        <Controller
            name="phone"
            control={control}
            rules={{
                required: formatMessage({
                    id: 'unified.landingPage.phoneNumberRequiredErrorMessage',
                }),
                validate: (value) => {
                    return (
                        isValidPhoneNumber(formatPhoneNumber(value)) ||
                        formatMessage({
                            id: 'unified.landingPage.phoneNumberInvalidErrorMessage',
                        })
                    );
                },
            }}
            render={({ field, fieldState }) => {
                const formattedPhoneNumber = formatPhoneNumber(field.value);

                const validationStatus =
                    (fieldState.error?.message && 'invalid') ||
                    (isValidPhoneNumber(formattedPhoneNumber) && 'valid') ||
                    undefined;

                useEffect(() => {
                    if (validStateSetter) {
                        validStateSetter(validationStatus === 'valid');
                    }
                });

                return (
                    <TextField
                        {...{ ...field, ref: null }} // `field` contains `ref` (not used), producing a warning
                        label={formatMessage({
                            id: 'unified.landingPage.phoneNumberLabel',
                        })}
                        type="text"
                        inputMode="numeric"
                        inputProps={{ pattern: '[0-9]*' }}
                        helperText={fieldState.error?.message || ' '}
                        validationStatus={validationStatus}
                        value={formattedPhoneNumber}
                        onChange={(event) => {
                            field.onChange(
                                formatPhoneNumber(event.target.value).replace(/[^0-9]/g, ''),
                            );
                        }}
                        {...textFieldProps}
                    />
                );
            }}
        />
    );
}
