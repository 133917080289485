// Adobe Analytics tracking functions
import IOSteps from '../../flows/steps/IOSteps';
import { UnifiedSteps } from '../../flows/steps';
import { useEffect } from 'react';
import makeDirectCall from './makeDirectCall';
import { ErrorPages } from '/src/interfaces/errors';
import { pciSteps } from '../../flows/PreCheckIn/pciSteps';

const INTENTION_TO_SELL_EVENT_ID = 'IntentionToSellEvent';
const VISTOR_COOKIE = 'KmxVisitor_0';
const MYKMX_COOKIE = 'KmxMyKmx_0';
const SESSION_COOKIE = 'KmxSession_0';

const appraisalChannel = 'In Store Appraisal';
const checkInChannel = 'Check-In';
const preCheckInSubsection = 'Pre-Check-In'; // deprecated, legacy
const pciEpuSubsection = 'Pre-Check-In:EPU';
const unifiedSubsection = 'Store';
const icoSubsection = 'ICO';
const checkInSubsection = 'Check In';

// step -> [channel? ,subsection, page]
// if channel misses, the default will be added
const stepToDigitalData = {
    [IOSteps.CheckInPage.description]: [appraisalChannel, icoSubsection, 'Offer Info'],
    [IOSteps.CheckInNoActiveOffers.description]: [
        appraisalChannel,
        icoSubsection,
        'No Active Offers',
    ],
    [IOSteps.ActiveOffersPage.description]: [appraisalChannel, icoSubsection, 'Choose Offer'],
    [IOSteps.ConfirmSingleOfferPage.description]: [
        appraisalChannel,
        icoSubsection,
        'Pre Verification Offer',
    ],
    [IOSteps.CustomerInfoPage.description]: [appraisalChannel, icoSubsection, 'Contact Info'],
    [IOSteps.VehicleInfoPage.description]: [appraisalChannel, icoSubsection, 'Vehicle Info'],
    [IOSteps.CompletePage.description]: [appraisalChannel, checkInSubsection, 'Complete:IO'],
    [IOSteps.ExpressCheckInTraditionalStoreCompletePage.description]: [
        appraisalChannel,
        checkInSubsection,
        'Complete:EDO',
    ],
    [IOSteps.ExpressCheckInXFStoreCompletePage.description]: [
        appraisalChannel,
        checkInSubsection,
        'Complete:EDO',
    ],
    [IOSteps.PicsyCheckInStoreCompletePage.description]: [
        appraisalChannel,
        checkInSubsection,
        'Complete:Picsy',
    ],
    [IOSteps.PicsyCheckInXFSStoreCompletePage.description]: [
        appraisalChannel,
        checkInSubsection,
        'Complete:Picsy',
    ],
    [IOSteps.AlreadyVerifiedTraditionalPage.description]: [
        appraisalChannel,
        checkInSubsection,
        'Complete:Already Verified',
    ],
    [IOSteps.AlreadyVerifiedXFPage.description]: [
        appraisalChannel,
        checkInSubsection,
        'Complete:Already Verified',
    ],
    ...Object.values(pciSteps.v1).reduce(
        (acc, specs) => ({
            ...acc,
            [specs.name]: [checkInChannel, preCheckInSubsection, specs.adobe],
        }),
        {},
    ),
    ...Object.values(pciSteps.v2).reduce(
        (acc, specs) => ({ ...acc, [specs.name]: [checkInChannel, pciEpuSubsection, specs.adobe] }),
        {},
    ),
    [ErrorPages.general]: [appraisalChannel, checkInSubsection, 'Error'],
    [ErrorPages.checkInDisabled]: [appraisalChannel, checkInSubsection, 'Not Available'],
    [ErrorPages.duplicateSubmission]: [appraisalChannel, checkInSubsection, 'Already Submitted'],
    [UnifiedSteps.Splash]: [checkInChannel, unifiedSubsection, 'Splash'],
    [UnifiedSteps.DoYouHaveAppointment]: [
        checkInChannel,
        unifiedSubsection,
        'Appointment or Walk-in',
    ],
    [UnifiedSteps.Landing]: [checkInChannel, unifiedSubsection, 'Phone Number'],
    [UnifiedSteps.InvalidPhoneNumber]: [checkInChannel, unifiedSubsection, 'Phone Number Invalid'],
    [UnifiedSteps.Appointment]: [
        [checkInChannel, unifiedSubsection, 'Appointment'],
        [checkInChannel, unifiedSubsection, 'Walk-In'],
    ],
    [UnifiedSteps.GetOffer]: [checkInChannel, unifiedSubsection, 'Get Offer'],
    [UnifiedSteps.CustomerInfo]: [checkInChannel, unifiedSubsection, 'Contact Info'],
    [UnifiedSteps.Complete]: [
        [checkInChannel, unifiedSubsection, 'Complete:EPU'],
        [checkInChannel, unifiedSubsection, 'Complete:Sales'],
    ],
};
const defaultSubsectionAndPage = [appraisalChannel, checkInSubsection, 'Start'];

// IMPORTANT: when updating this type, remember to update the code that sets the variables
// in rule "Predigested Event" on Adobe's platform
type EventVarNames = 'prop1' | 'prop3' | 'prop4' | 'prop5' | 'prop6' | 'pageName' | 'eVar89';
export type EventVars = { [key in EventVarNames]?: string }

export interface AdobeTrackerOptions {
    variant?: number;
    eventVars?: EventVars;
}

export function trackStep(step, options: AdobeTrackerOptions = {}) {
    const { variant, eventVars } = options;

    const [channel, subsection, page] =
        (variant === undefined ? stepToDigitalData[step] : stepToDigitalData[step][variant]) ??
        defaultSubsectionAndPage;
    setDigitalData(step, channel, subsection, page, eventVars);
    makeDirectCall('PageChange');
}

export const useAdobeTracking = (step: string, options?: AdobeTrackerOptions) => {
    useEffect(() => {
        trackStep(step, options);
    }, []);
};

export function trackSelectedOffer(selectedOffer) {
    window.digitalData = window.digitalData || {};
    window.digitalData.year = selectedOffer.year;
    window.digitalData.make = selectedOffer.make;
    window.digitalData.model = selectedOffer.model;
    window.digitalData.valuation = selectedOffer.valuation;
}

export function trackIntentionToSell(intentionToSell) {
    window.digitalData = window.digitalData || {};
    window.digitalData.intentionToSell = intentionToSell;
    if (window._satellite) {
        window._satellite.track(INTENTION_TO_SELL_EVENT_ID);
    }
}

function getCookie(cookieName) {
    const name = cookieName + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }

    return '';
}

function getCookieObject(cookieName) {
    const cookieObj = {} as Record<string, string>;
    const cookie = getCookie(cookieName) || '';

    cookie.split('&').forEach(function (keyValue) {
        const keyValues = keyValue.split('=');
        if (keyValues[0]) {
            cookieObj[keyValues[0]] = keyValues[1] !== 'undefined' && keyValues[1];
        }
    });

    return cookieObj;
}

function setDigitalData(
    step: string,
    channel: string,
    subsection: string,
    page: string,
    eventVars: EventVars,
) {
    const visitorCookie = getCookieObject(VISTOR_COOKIE);
    const sessionCookie = getCookieObject(SESSION_COOKIE);
    const myKMXCookie = getCookieObject(MYKMX_COOKIE);

    const pageID = `${channel}:${subsection}`;
    const pageName = `${pageID}:${page}`;

    window.digitalData = {
        step,
        page: {
            pageInfo: {
                channel,
                pageName,
                pageID: pageName,
                subsection: pageID,
                destinationUrl: document.location.toString(),
                referringURL: document.referrer,
            },
        },
        pageInstanceId: pageID,
        user: [
            {
                attributes: {
                    visitorID: visitorCookie.VisitorID || null,
                    isFirstTimeVisitor: (visitorCookie.IsFirstVisit || '').toLowerCase() || null,
                    nearestStoreId: (visitorCookie.StoreId || '').toLowerCase() || null,
                    nearestStoreZip: (visitorCookie.Zip || '').toLowerCase() || null,
                    usingStoreProxy: visitorCookie.UsingStoreProxy,
                    testSegment: {
                        logOdds: sessionCookie.logOdds,
                        logOddsA: sessionCookie.logOddsA,
                        logOddsI: sessionCookie.logOddsI,
                        userInSegment: '',
                    },
                    akamaiCity: null, //'Winchester',
                    akamaiLatitude: null, // 39.2077,
                    akamaiLongitude: null, // -78.2566,
                    akamaiZip: null, //'22602'
                },
                profile: [{ profileID: myKMXCookie.userid || null }],
            },
        ],
        eventVars: {
            ...window.digitalData?.eventVars,
            ...eventVars,
        },
        version: '1.0',
    };
}
