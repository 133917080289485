import React from 'react';
import PropTypes from 'prop-types';
import styles from './CompletedPageAccordion.module.scss';
import { useIntl } from 'react-intl';
import { AccordionSection } from '../AccordionSection/AccordionSection';
import { accordionTypes } from '../../constants/pageTypes';

export default function CompletedPageAccordion(props) {
    const { formatMessage } = useIntl();
    const { title } = props;

    return (
        <div className={styles['accordion-container']} id='accordion-container'>
            <div className={styles.title}>
                <h4 className={"kmx-typography--display-4"}>
                    {title}
                </h4>
            </div>
            <div>
                <AccordionSection
                    title={formatMessage({id: 'completePage.faqQuestion1'})}
                    sectionId='faq-question-1'
                    type={accordionTypes.CompletePage}>
                    <p id="faqAnswer1" className={"kmx-typography--body-2"}>
                        {formatMessage({id: 'completePage.faqAnswer1'})}
                    </p>
                </AccordionSection>
                <AccordionSection
                    title={formatMessage({id: 'completePage.faqQuestion2'})}
                    sectionId='faq-question-2'
                    type={accordionTypes.CompletePage}>
                    <p id="faqAnswer2" className={"kmx-typography--body-2"}>
                        {formatMessage({id: 'completePage.faqAnswer2'})}
                    </p>
                    <ul className={styles['faq-list']}>
                        <li className={styles['faq-list-item']}>{formatMessage({id: 'completePage.faqAnswer2.1'})}</li>
                        <li className={styles['faq-list-item']}>{formatMessage({id: 'completePage.faqAnswer2.2'})}</li>
                        <li className={styles['faq-list-item']}>{formatMessage({id: 'completePage.faqAnswer2.3'})}</li>
                        <li className={styles['faq-list-item']}>{formatMessage({id: 'completePage.faqAnswer2.4'})}</li>
                    </ul>
                </AccordionSection>
                <AccordionSection
                    title={formatMessage({id: 'completePage.faqQuestion3'})}
                    sectionId='faq-question-3'
                    type={accordionTypes.CompletePage}>
                    <p id="faqAnswer3" className={"kmx-typography--body-2"}>
                        {formatMessage({id: 'completePage.faqAnswer3'})}
                    </p>
                </AccordionSection>
            </div>
        </div>
    );
}

CompletedPageAccordion.propTypes = {
    title: PropTypes.string.isRequired
};
