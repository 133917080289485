import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './OfferInfoCard.module.scss';
import { logFsEvent, buildEventName } from '../../utils/fullstory';
import { fullstoryConstants } from '../../constants/fullstory';
import IconCheck from '@kmx/legos-icons-material-system/assets/svg/check.svg';

export default function OfferInfoCard(props) {
    const { offer, title, body, offerPrice, setSelectedOffer, offerInfoCardIsSelected } = props;

    const handleOfferInfoCardClick = () => {
        setSelectedOffer({ code: offer?.code });

        logFsEvent(buildEventName(fullstoryConstants.activeOffersPage.name,
            fullstoryConstants.activeOffersPage.events.selectedOffer));
    };

    return (
        <div onClick={() => handleOfferInfoCardClick()} className={classNames(styles.selectionCard, {[styles.selected]: offerInfoCardIsSelected})}>
            <div style={{width: '100%', borderBottom: '1px solid #dedfe0'}}>
                <h1 className={classNames(styles.title, "kmx-typography--emphasis")}>
                    {title}
                </h1>
            </div>
            <div style={{display: 'flex', width: '100%'}}>
                <div style={{border: 'none', flexBasis: '150%', padding: '0 0.5rem 0 1rem'}}>
                    <h3 className={styles.vehicleBody}>
                        {body}
                    </h3>
                </div>
                <div style={{border: 'none', padding: '0 1rem 0 0.5rem'}}>
                    <h3 className={styles.offerPrice}>
                        {offerPrice}
                    </h3>
                </div>
            </div>

            <div className={styles.check} id='offer-card-check'>
                <IconCheck />
            </div>
        </div>
    );
}

OfferInfoCard.propTypes = {
    offer: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    offerPrice: PropTypes.string.isRequired,
    setSelectedOffer: PropTypes.func.isRequired,
    offerInfoCardIsSelected: PropTypes.bool.isRequired
};
