import React, { useEffect, useState } from 'react';
import isEqual from 'fast-deep-equal/es6';
import CheckButton from '../CheckButton/CheckButton';
import CheckButtonGroupOption from '../../../interfaces/CheckButtonGroupOption';

type GenericOption = CheckButtonGroupOption<string | number>;

export default function CheckButtonGroup({
    options,
    onChange,
    className,
    buttonClassName,
}: {
    // keys are the actual option values, values are labels
    options: GenericOption[];
    onChange: (options: GenericOption[]) => void;
    // allows adding more styles to the container
    className?: string;
    buttonClassName?: string;
}) {
    const [internalOptions, setInternalOptions] = useState<GenericOption[]>([]);
    useEffect(() => {
        const didOptionsChange = !isEqual(new Set(options), new Set(internalOptions));
        if (didOptionsChange) {
            setInternalOptions(options);
        }
    }, [options]);

    return (
        <div className={className}>
            {internalOptions.map(({ value, label, isChecked, icon }) => (
                <CheckButton
                    key={value}
                    label={label}
                    isChecked={isChecked}
                    onClick={() => {
                        const newOptions = internalOptions.map((o) =>
                            o.value !== value ? o : { ...o, isChecked: !o.isChecked },
                        );
                        setInternalOptions(newOptions);
                        onChange(newOptions);
                    }}
                    className={buttonClassName}
                    Icon={icon}
                />
            ))}
        </div>
    );
}
