import React, { useCallback, useState } from 'react';
import styles from './PageLayout.module.scss';

interface Props {
    header: JSX.Element;
    content: JSX.Element;
    footer?: JSX.Element;
}

// Helper to set states based on a dom element's height
// Why use getBoundingClientRect: the content is scaled on the tablet,
// but css `transform: scale()` isn't changing the element flow (i.e. not use the scaled height)
// and then the footer would overlap with the content.
// There's really no pure-css solution to this problem (because the content height is dynamic),
// apart from `zoom` which is not standard (and not supported by firefox).
const getElementHandler = (stateSetter) => (node) => {
    // getBoundingClientRect returns the already-scaled dimensions
    if (!window.Cypress && node) {
        new ResizeObserver(() => stateSetter(node.getBoundingClientRect().height)).observe(node);
    }
};
const useElementHandler = (stateSetter) => useCallback(getElementHandler(stateSetter), []);

// Arranges the given elements in the page and scales up the content for wider viewports (tablets)
export default function PageLayout({ header, content, footer }: Props) {
    const [headerHeight, setHeaderHeight] = useState<number>(0);
    const handleHeader = useElementHandler(setHeaderHeight);
    const [contentHeight, setContentHeight] = useState<number>(0);
    const handleContent = useElementHandler(setContentHeight);
    const [footerHeight, setFooterHeight] = useState<number>(0);
    const handleFooter = useElementHandler(setFooterHeight);

    return (
        <div
            className={styles.pageLayout}
            style={{ height: footer ? headerHeight + contentHeight : '100%' }}
        >
            <div className={styles.headerWrapper} ref={handleHeader}>
                {header}
            </div>
            <div
                className={styles.contentWrapper}
                ref={handleContent}
                style={{
                    // make sure the content fills the page when footer misses
                    // (important for splash because of the background color)
                    height: footer ? 'auto' : '100%',
                }}
            >
                {content}
            </div>
            {footer && (
                <div
                    ref={handleFooter}
                    className={styles.footerWrapper}
                    style={{
                        // sticky footer: at bottom or after (a long) content
                        top: Math.max(
                            window.innerHeight - footerHeight,
                            contentHeight + headerHeight,
                        ),
                    }}
                >
                    {footer}
                </div>
            )}
        </div>
    );
}
