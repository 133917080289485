import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Props as FormattedMessageProps } from 'react-intl/src/components/message';

type FlatMessages = Record<string, string>;

// webpack will create chunks for the different languages
export const importMessages = (locale: string = 'en'): Promise<FlatMessages> =>
    (locale === 'es' ? import('../lang/es') : import('../lang/en')).then(
        (module) => module?.default,
    );

export const FormattedMessageEmphasis = (props: FormattedMessageProps) => (
    <FormattedMessage
        {...props}
        values={{
            i: (...chunks) => <i>{chunks}</i>,
        }}
    />
);

interface FormattedMessageWithLinkProps extends FormattedMessageProps {
    url?: string;
    onClick?: () => void;
    linkText?: string;
}
export const FormattedMessageWithLink = (props: FormattedMessageWithLinkProps) => (
    <FormattedMessage
        {...props}
        values={{
            a: (...chunks) => (
                <hzn-text-link
                    onClick={props.onClick}
                    href={props.url ?? 'javascript:'}
                >
                    {props.linkText ?? chunks}
                </hzn-text-link>
            ),
        }}
    />
);
