import { pciSteps } from '/src/flows/PreCheckIn/pciSteps';
import makeDirectCall from '/src/utils/adobe/makeDirectCall';

// NAMES OF SUBSECTIONS AND PAGES

enum Subsections {
    Unified = 'Check-In:Store',
    ICO = 'In Store Appraisal:ICO',
    PreCheckIn = 'Check-In:Pre-Check-In:EPU',
    // CheckIn = 'In Store Appraisal:Check In',
}

enum PagesUnified {
    Splash = 'Splash',
    DoYouHaveAppointment = 'Appointment or Walk-in',
    PhoneNumber = 'Phone Number',
    PhoneNumberInvalid = 'Phone Number Invalid',
    Appointment = 'Appointment',
    WalkIn = 'Walk-In',
    CustomerInfo = 'Contact Info',
    GetOffer = 'Get Offer',
}
// This Proxy keeps the keys of PagesUnified, but provides the complete page qualifier (incl. channel, subsection).
const pagesUnified = new Proxy({} as { [key in keyof typeof PagesUnified]: string }, {
    get: (target, prop: string) => `${Subsections.Unified}:${PagesUnified[prop]}`,
});

enum PagesICO {
    OfferInfo = 'Offer Info',
    NoActiveOffers = 'No Active Offers',
}
const pagesIco = new Proxy({} as { [key in keyof typeof PagesICO]: string }, {
    get: (target, prop: string) => `${Subsections.ICO}:${PagesICO[prop]}`,
});

const pagesPciV2 = new Proxy({} as { [key in keyof typeof pciSteps.v2]: string }, {
    get: (target, prop: string) => `${Subsections.PreCheckIn}:${pciSteps.v2[prop].adobe}`,
});

// sets a field in digitalData that will be used exactly as-is to set properties in adobe,
// then uses a "Direct Call" event (PredigestedEvent)
const t = (completePageName: string, linkName: string) => () => {
    if (!window._satellite) {
        return;
    }
    const pageTokens = completePageName.split(':');
    const channel = pageTokens[0];
    const subsection = `${channel}:${pageTokens[1]}`;
    // _satellite.track isn't synchronous, 2 subsequent calls will result in
    // digitalData being overwritten (by the latest call).
    // This timeout cedes control to the tracker to finish its stuff
    setTimeout(() => {
        window.digitalData = window.digitalData || {};
        window.digitalData.eventVars = {
            ...window.digitalData.eventVars || {},
            pageName: completePageName,
            prop1: subsection,
            prop3: completePageName,
            prop4: linkName,
            prop5: completePageName,
            prop6: `${completePageName}|${linkName}|${subsection}`,
        };
        makeDirectCall('PredigestedEvent');
    }, 0);
};

// HELPERS FOR TRACKING MORE COMPLEX CASES (changing page names)
const tUnifiedAppointment = (linkName: string) => (hasAppointment: boolean) =>
    t(hasAppointment ? pagesUnified.Appointment : pagesUnified.WalkIn, linkName)();

const tIOCheckIn = (linkName: string) => (hasNoActiveOffers: boolean) =>
    t(hasNoActiveOffers ? pagesIco.NoActiveOffers : pagesIco.OfferInfo, linkName)();

const tUnifiedPhone = (linkName: string) => (isNumberInvalid: boolean) =>
    t(isNumberInvalid ? pagesUnified.PhoneNumberInvalid : pagesUnified.PhoneNumber, linkName)();

const tPciComplete = (linkName: string) => (isPostCheckIn: boolean) =>
    t(isPostCheckIn ? pagesPciV2.postCheckIn : pagesPciV2.complete, linkName)();

// THE ACTUAL EVENT SPECS
// note: always use the proxy (e.g. pagesUnified) instead of the source enum (e.g PagesUnified)!
const trackEvent = {
    unified: {
        splash: {
            click2: t(pagesUnified.Splash, 'GET STARTED'),
        },
        doYouHaveAppointment: {
            click2: (hasAppointment: boolean) =>
                t(
                    pagesUnified.DoYouHaveAppointment,
                    `Do you have an appointment today? ${hasAppointment ? 'yes' : 'no'}`,
                )(),
            click3: t(pagesUnified.DoYouHaveAppointment, 'NEXT'),
        },
        phoneNumber: {
            click2: tUnifiedPhone('NEXT'),
            click3: tUnifiedPhone('BACK'),
        },
        appointment: {
            click2: tUnifiedAppointment('Test drive a car'),
            click3: tUnifiedAppointment('Explore financing'),
            click4: tUnifiedAppointment('Get an offer on my car'),
            click5: tUnifiedAppointment('Redeem my online offer'),
            click6: tUnifiedAppointment('NEXT'),
            click7: tUnifiedAppointment('BACK'), // TODO: no back button
            click8: tUnifiedAppointment('Browse'),
        },
        getOffer: {
            click2: t(pagesUnified.GetOffer, 'Send SMS'),
            click3: t(pagesUnified.GetOffer, 'Continue here'),
        },
        customerInfo: {
            click2: t(pagesUnified.CustomerInfo, 'NEXT'),
            click3: t(pagesUnified.CustomerInfo, 'BACK'), // TODO: no back button
        },
    },
    instantOffer: {
        checkIn: {
            click2: tIOCheckIn('OFFER CODE'),
            click3: tIOCheckIn('LICENCE PLATE'),
            click4: tIOCheckIn('FIND MY OFFER'),
        },
    },
    preCheckin: {
        welcome: {
            click2: t(pagesPciV2.welcome, 'WHAT TO BRING'),
            click3: t(pagesPciV2.welcome, 'FAQ'),
            click4: t(pagesPciV2.welcome, 'PHONE'),
            click5: t(pagesPciV2.welcome, 'DOCUMENTS'),
            click6: t(pagesPciV2.welcome, 'RESCHEDULE'),
        },
        itemsToBring: {
            click2: t(pagesPciV2.items, 'APPOINTMENT DETAILS'),
            click3: t(pagesPciV2.items, 'FAQ'),
            click4: t(pagesPciV2.items, 'PHONE'),
            click5: t(pagesPciV2.items, 'DOCUMENTS'),
            click6: t(pagesPciV2.items, 'RESCHEDULE'),
        },
        confirm: {
            click2: t(pagesPciV2.details, 'CONFIRM MY APPOINTMENT'),
            click3: t(pagesPciV2.details, 'DIRECTIONS'),
            click4: t(pagesPciV2.details, 'STORE PHONE'),
            click5: t(pagesPciV2.details, 'DOCUMENTATION'),
            click6: t(pagesPciV2.details, 'RESCHEDULE'),
            click7: t(pagesPciV2.details, 'FAQ'),
            click8: t(pagesPciV2.details, 'PHONE'),
            click9: t(pagesPciV2.details, 'DOCUMENTS'),
        },
        directions: {
            click2: t(pagesPciV2.directions, "I'M ON MY WAY"),
            click3: t(pagesPciV2.directions, 'FAQ'),
            click4: t(pagesPciV2.directions, 'PHONE'),
            click5: t(pagesPciV2.directions, 'DOCUMENTS'),
            click6: t(pagesPciV2.directions, 'MAP'),
            click7: t(pagesPciV2.directions, 'RESCHEDULE'),
        },
        parking: {
            click2: t(pagesPciV2.parking, "I'M HERE"),
            click3: t(pagesPciV2.parking, 'FAQ'),
            click4: t(pagesPciV2.parking, 'PHONE'),
            click5: t(pagesPciV2.parking, 'DOCUMENTS'),
            click6: t(pagesPciV2.parking, 'RESCHEDULE'),
        },
        complete: {
            click2: tPciComplete('FAQ'),
            click3: tPciComplete('PHONE'),
            click4: tPciComplete('DOCUMENTS'),
        },
        missed: {
            click2: t(pagesPciV2.missed, 'FAQ'),
            click3: t(pagesPciV2.missed, 'PHONE'),
            click4: t(pagesPciV2.missed, 'DOCUMENTS'),
        },
        postPurchase: {
            click2: t(pagesPciV2.postPurchase, 'FAQ'),
            click3: t(pagesPciV2.postPurchase, 'PHONE'),
            click4: t(pagesPciV2.postPurchase, 'DOCUMENTS'),
        },
    },
};

export default trackEvent;
