import React from 'react';
import useInitVisuals from '/src/hooks/useInitVisuals';
import pciCommonStyles from '/src/flows/PreCheckIn/v2/common/pciCommon.module.scss';
import { FormattedMessage } from 'react-intl';
import { formatPhoneNumber } from '/src/utils';
import { useMediaQuery } from '/src/utils/mediaQueries/useMediaQueries';
import trackEvent from '/src/utils/adobe/trackEvent';
import Questions from '/src/flows/PreCheckIn/v2/common/Questions/Questions';
import { pciSteps } from '/src/flows/PreCheckIn/pciSteps';
import { fsEvents } from '/src/utils/fullstory';
import {
    useAdobePci,
    useFullstoryPci,
} from '/src/flows/PreCheckIn/v2/common/hooks';
import { FormattedMessageWithLink } from "/src/utils/intlHelpers";
import useAppointmentAndStore from "/src/api/useAppointmentAndStore";

const MissedAppointment = () => {
    useInitVisuals();
    const { isDesktop } = useMediaQuery();
    const { store, hasFetchedStore } = useAppointmentAndStore();

    useAdobePci(pciSteps.v2.missed.name);
    useFullstoryPci( fsEvents.pciMissed);

    return hasFetchedStore && (
        <div className={pciCommonStyles.pciPageLayout}>
            <hzn-card
                border-radius="medium"
                elevation={isDesktop ? 'none' : 'medium'}
                padding="medium"
            >
                <hzn-stack space="large" align-x="center">
                    <hzn-heading as="h1" size="large" align="center">
                        <FormattedMessage id="pci.missed.heading" />
                    </hzn-heading>
                    <hzn-text align={isDesktop ? 'center' : 'left'}>
                        <FormattedMessageWithLink
                            id="pci.missed.info"
                            url={`tel:${store.phoneLocal}`}
                            linkText={formatPhoneNumber(store.phoneLocal)}
                        />
                    </hzn-text>
                    {isDesktop && <div style={{ height: 40 }} />}
                    <Questions
                        eventTrackers={{
                            faq: () => trackEvent.preCheckin.missed.click2(),
                            phone: () => trackEvent.preCheckin.missed.click3(),
                            docs: () => trackEvent.preCheckin.missed.click4(),
                        }}
                    />
                </hzn-stack>
            </hzn-card>
        </div>
    );
};

export default MissedAppointment;
