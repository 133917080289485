import { useEffect, useState } from 'react';

export const useMediaQuery = () => {
    if (!window) return {};

    const [isPortraitTablet, setIsPortraitTablet] = useState<boolean>();
    const handleTablet = (mql) => setIsPortraitTablet(mql.matches);
    useEffect(() => {
        const tabletMediaQueryList = window.matchMedia(
            '(min-width: 768px) and (max-aspect-ratio: 1/1)',
        );
        handleTablet(tabletMediaQueryList);
        tabletMediaQueryList.addEventListener('change', handleTablet);
    }, []);

    const [isDesktop, setIsDesktop] = useState<boolean>();
    const handleDesktop = (mql) => setIsDesktop(mql.matches);
    useEffect(() => {
        const desktopMediaQueryList = window.matchMedia('(min-width: 1025px)');
        handleDesktop(desktopMediaQueryList);
        desktopMediaQueryList.addEventListener('change', handleDesktop);
    }, []);

    const [isMobile, setIsMobile] = useState<boolean>();
    const handleMobile = (mql) => setIsMobile(mql.matches);
    useEffect(() => {
        const mobileMediaQueryList = window.matchMedia('(max-width: 767px)');
        handleMobile(mobileMediaQueryList);
        mobileMediaQueryList.addEventListener('change', handleMobile);
    }, []);

    return { isPortraitTablet, isDesktop, isMobile };
};
