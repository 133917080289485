import { getKioskModeFromUrl } from './urlHelpers';
import { logFs, fsEvents } from "./fullstory";

const isKioskUX = () => {
    const kioskParamValue = getKioskModeFromUrl();

    // All kiosks will have query param &kiosk=true, rely on that, not user agent
    try {
        return JSON.parse(kioskParamValue);
    } catch (err) {
        logFs(fsEvents.info.invalidUrlArg, { kiosk: kioskParamValue });
        return false;
    }
};

export { isKioskUX };
