import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './StoreSelector.module.scss';
import { logFs, fsEvents, addFsDetails } from '../../utils/fullstory';
import ValidatedSelect from '../ValidatedSelect/ValidatedSelect';
import { OfferContext, useNavigation, useStore } from '/src/context';
import { HTTP_STATUS, apiRoutes } from '/src/constants/api';
import IOSteps from '../../flows/steps/IOSteps';
import useDataFetch from '../../hooks/useDataFetch';
import { validateStoreId } from '../../utils/validators';
import { getFromUrl } from '../../utils/urlHelpers';
import { StoreData, StoreGeneric } from '/src/interfaces/StoreData';

interface Props {
    label: string;
    dataSource: StoreGeneric[];
    dropdownErrorMessage: string;
    setIsLoadingStore: (isLoading: boolean) => void;
}

export default function StoreSelector({
    label,
    dataSource,
    dropdownErrorMessage,
    setIsLoadingStore,
}: Props) {
    const isStoreInUrl = useRef(!!getFromUrl.validatedStore()); // ref: calculating it only once

    const { setStep } = useNavigation();
    const { setOffer } = useContext(OfferContext);
    const [store, setStore] = useStore();
    const [storeId, setStoreId] = useState(store?.id ?? '');
    const [dataFetchUrl, setDataFetchUrl] = useState('');
    const { data: storeData, status: fetchStatus } = useDataFetch<StoreData>({
        url: dataFetchUrl,
        guard: () => !isStoreInUrl.current,
    });

    useEffect(() => {
        setOffer((prevState) => ({ ...prevState, storeNumber: storeId }));

        if (validateStoreId(storeId)) {
            setDataFetchUrl(apiRoutes.storeDataApi(storeId));
        }
    }, [storeId]);

    useEffect(() => {
        setIsLoadingStore(fetchStatus === HTTP_STATUS.FETCHING);
        if (fetchStatus === HTTP_STATUS.SUCCESS) {
            setStore(storeData);
            addFsDetails({
                storeNumber: storeData.id,
                storeType: storeData.storeFormat,
            });
        } else if (fetchStatus === HTTP_STATUS.ERROR) {
            setStep(IOSteps.ErrorPage);
        }
    }, [storeData, fetchStatus]);

    const onFocus = () => {
        logFs(fsEvents.checkInPage.storeDropdown);
    };

    // show selector only if storeId not in url
    return isStoreInUrl.current ? null : (
        <div id="store-selector-area">
            <div className={styles.label}>
                <h4>{label}</h4>
            </div>
            <div>
                <ValidatedSelect
                    options={dataSource}
                    value={storeId}
                    onChange={(event) => setStoreId(event.target.value)}
                    onFocus={() => onFocus()}
                    id="ico-store"
                    name="store"
                    displayHelperText={true}
                    dropdownErrorMessage={dropdownErrorMessage}
                />
            </div>
        </div>
    );
}
