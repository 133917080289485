import React from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from  './CustomerAddressInfo.module.scss';
import { useIntl } from 'react-intl';
import AddressDetails from "../AddressDetails/AddressDetails";

export default function CustomerAddressInfo({setAddressInfoIsValid})
{
    const { formatMessage } = useIntl();

    const addressLabel = formatMessage({id: 'customerInfoPage.addressLabel'});
    const addressRequiredErrorMessage = formatMessage({id: 'customerInfoPage.addressRequiredErrorMessage'});

    const { control, watch, setValue, clearErrors } = useForm({
        mode: 'onBlur',
        defaultValues: {
            mailingAddress: '',
        },
    });

    return (
        <div>
            <fieldset className={classNames(styles['form-field'], styles['address'])} id='address'>
                <AddressDetails
                    control={control}
                    name="mailingAddress"
                    setValue={setValue}
                    clearErrors={clearErrors}
                    setAddressInfoIsValid={setAddressInfoIsValid}
                    watch={watch}
                    label={addressLabel}
                    rules={{ required: addressRequiredErrorMessage }}
                />
            </fieldset>
        </div>
    );
}

CustomerAddressInfo.propTypes = {
    setAddressInfoIsValid: PropTypes.func.isRequired
};
