import { useEffect } from "react";
import { useSpinner } from "../context";

export default function useInitVisuals(shouldHideSpinner = true) {
    const [, showSpinner] = useSpinner();
    useEffect(() => {
        showSpinner(!shouldHideSpinner);
        window.scrollTo(0,0);
    }, [shouldHideSpinner]);
}

