import { useEffect } from "react";
import { isKioskUX } from "../utils/kioskMode";
import flows from "../flows/flows";
import { noop } from "../constants/general";
import { BASE_URL, getAnonymizedSearch } from "../utils/urlHelpers";

// in kiosk mode: redirect to splash page after 15s
export const useSplashRedirection = () => {
    useEffect(() => {
        if (!isKioskUX()) {
            return noop; // 1. do nothing; 2. noop = no cleanup needed
        }
        const tid = setTimeout(() =>  {
            window.location.replace(BASE_URL + flows.Unified + getAnonymizedSearch());
        }, 15000);

        return () => {
            clearTimeout(tid);
        };
    }, []);
};
