import React from 'react';
import styles from './UnifiedSplashPage.module.scss';
import { useIntl } from 'react-intl';
import { logFs, fsEvents } from '../../utils/fullstory';
import { useNavigation, useFeatures } from '../../context';
import unifiedSteps from '../../flows/steps/unifiedSteps';
import { Button } from '@kmx/legos-react-button';
import { featureFlagNames } from '../../constants/featureFlags';
import { useAdobeTracking } from '/src/utils/adobe/analytics';
import KioskImage from '../../assets/in-store-purchase.svg';
import { useEnterToMoveOn } from '../../hooks/useEnterToMoveOn';
import useInitVisuals from '../../hooks/useInitVisuals';
import { useErrorHandling } from '../../context';
import trackEvent from '/src/utils/adobe/trackEvent';
import { UnifiedSteps } from '../../flows/steps';

export default function UnifiedSplashPage() {
    const { formatMessage } = useIntl();
    const { setStep } = useNavigation();
    const { setError } = useErrorHandling();
    const [features] = useFeatures();

    useInitVisuals();
    useAdobeTracking(UnifiedSteps.Splash);

    const onClick = () => {
        trackEvent.unified.splash.click2();
        if (!features[featureFlagNames.IsSelfCheckInEnabled]) {
            setError.checkInDisabled('Self-check-in not enabled');
        } else {
            setStep(unifiedSteps.DoYouHaveAppointment);
        }
        logFs(fsEvents.unifiedSplashPage.getStarted);
    };

    useEnterToMoveOn(onClick, [features, setStep, setError]);

    return (
        <div className={styles.container}>
            <Button className={styles.button} id="splash--get-started" onClick={onClick}>
                {formatMessage({ id: 'landingPage.button' })}
            </Button>
            <div className={styles.imageContainer}>
                <KioskImage />
            </div>
        </div>
    );
}
