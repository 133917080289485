import React from 'react';
import PropTypes from 'prop-types';
import {parseMilitaryTime, toStandardTime} from '../../utils/index';

export default function StoreHoursInfo(props) {
    const { storeHours, storeHoursLabel, openTodayLabel } = props;

    const storeHoursIsValid = storeHours !== undefined && storeHours?.hoursToday?.startHour !== undefined &&
        storeHours?.hoursToday?.endHour !== undefined;

    const openingTime = storeHoursIsValid ? toStandardTime(parseMilitaryTime(storeHours?.hoursToday?.startHour)) : null;
    const closingTime = storeHoursIsValid ? toStandardTime(parseMilitaryTime(storeHours?.hoursToday?.endHour)) : null;

    return (
        <div style={{textAlign: 'left'}} className={"kmx-typography--body-1"} test-id='store-hours-info'>
            <div><span><strong>{storeHoursLabel}</strong></span></div>
            <div><span>{openTodayLabel} {openingTime} - {closingTime}</span></div>
        </div>
    );
}

StoreHoursInfo.propTypes = {
    storeHours: PropTypes.object.isRequired,
    storeHoursLabel: PropTypes.string.isRequired,
    openTodayLabel: PropTypes.string.isRequired
};
