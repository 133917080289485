import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './PCIInfoPage.module.scss';
import IconBuy from '@kmx/legos-icons-brand/assets/svg/drivers-license.svg';
import IconTrade from '@kmx/legos-icons-brand/assets/svg/car-price-tag.svg';
import CheckSign from '@kmx/legos-icons-essentials/assets/svg/check.svg';
import { FormattedMessageEmphasis } from "/src/utils/intlHelpers";

export enum RequirementsType {
    Buy,
    Trade,
}

const RequirementsCard = ({ reqType }: { reqType: RequirementsType }) => {
    const { formatMessage } = useIntl();
    const ImageComponent = images[reqType];
    return (
        <div className={styles.requirementsCard}>
            <div className={styles.requirementsHeader}>
                <p className={styles.requirementsTitle}>
                    <FormattedMessage id={messages[reqType].title} />
                </p>
                <ImageComponent />
            </div>
            <ul>
                {messages[reqType].items.map((item, index) => (
                    <li key={index}>
                        <CheckSign />
                        <div>
                            {item.hasEmphasis ? (
                                <div className={styles.requirementsItemHtml}>
                                    <FormattedMessageEmphasis id={item.text} />
                                </div>
                            ) : (
                                <div className={styles.requirementsItemText}>
                                    <FormattedMessage id={item.text} />
                                </div>
                            )}
                            {item.details && (
                                <div className={styles.requirementsItemDetails}>
                                    <FormattedMessage id={item.details} />
                                </div>
                            )}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

const messages = {
    [RequirementsType.Buy]: {
        title: 'preCheckIn.requirements.buy.header',
        items: [
            {
                text: 'preCheckIn.requirements.buy.item1',
                hasEmphasis: true,
            },
        ],
    },
    [RequirementsType.Trade]: {
        title: 'preCheckIn.requirements.trade.header',
        items: [
            {
                text: 'preCheckIn.requirements.trade.item1',
                details: 'preCheckIn.requirements.trade.item1Details',
            },
            {
                text: 'preCheckIn.requirements.trade.item2',
            },
            {
                text: 'preCheckIn.requirements.trade.item3',
            },
            {
                text: 'preCheckIn.requirements.trade.item4',
            },
        ],
    },
};

const images = {
    [RequirementsType.Buy]: IconBuy,
    [RequirementsType.Trade]: IconTrade,
};

export default RequirementsCard;
