const IOSteps = {
    LandingPage: Symbol('Landing Page'),
    ParkingSpotNumberPage: Symbol('Parking Spot Number Page'),
    CheckInPage: Symbol('CheckIn Page'),
    CheckInNoActiveOffers: Symbol('No Active Offers'), // used only by adobe tracking
    ActiveOffersPage: Symbol('Active Offers Page'),
    ConfirmSingleOfferPage: Symbol('Confirm Single Offer Page'),
    CustomerInfoPage: Symbol('Customer Info Page'),
    VehicleInfoPage: Symbol('Vehicle Info Page'),
    CompletePage: Symbol('CheckIn Completed Page'),
    ExpressCheckInTraditionalStoreCompletePage: Symbol('Express Checkin Complete Page'),
    ExpressCheckInXFStoreCompletePage: Symbol('Express Checkin Complete Page'),
    PicsyCheckInStoreCompletePage: Symbol('Picsy Checkin Complete Page'),
    PicsyCheckInXFSStoreCompletePage: Symbol('Express Picsy Checkin Complete Page'),
    AlreadyVerifiedTraditionalPage: Symbol('Checkin Complete Page, Already Verified (Traditional)'),
    AlreadyVerifiedXFPage: Symbol('Checkin Complete Page, Already Verified (XF)'),
    ErrorPage: Symbol('Error Page'),
};

export default IOSteps;
