import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { pciSteps, usePciSteps } from '/src/flows/PreCheckIn/pciSteps';
import useInitVisuals from '/src/hooks/useInitVisuals';
import { useMediaQuery } from '/src/utils/mediaQueries/useMediaQueries';
import useAdobePci from '/src/flows/PreCheckIn/v2/common/hooks/useAdobePci';
import { FormattedMessage } from 'react-intl';
import trackEvent from '/src/utils/adobe/trackEvent';
import Questions from '/src/flows/PreCheckIn/v2/common/Questions/Questions';
import ImgEpuSign from '/src/assets/precheckin/EpuSign.png';
import ImgEpuSignProp from '/src/assets/precheckin/EpuSignProp.png';
import ImgEpuParkingLot from '/src/assets/precheckin/EpuParkingLot.png';
import styles from './ParkingPage.module.scss';
import RescheduleLink from '/src/flows/PreCheckIn/v2/common/Reschedule/Reschedule';
import { fsEvents, logFs } from '/src/utils/fullstory';
import useAppointmentAndStore from '/src/api/useAppointmentAndStore';
import useFullstoryPci from '/src/flows/PreCheckIn/v2/common/hooks/useFullstoryPci';
import { postTripEvent } from '/src/api/sharedApiCalls';
import pciCommonStyles from '/src/flows/PreCheckIn/v2/common/pciCommon.module.scss';
import commonStyles from '/src/common.module.scss';

const Parking = () => {
    const { goToNextStep } = usePciSteps();
    const { appointment, store, isFetchComplete } = useAppointmentAndStore();
    const [hasLoadedImageCount, setHasLoadedImageCount] = useState(0);
    const { isDesktop } = useMediaQuery();

    useEffect(() => {
        // in (the unlikely) case that the user resizes a desktop window and enters mobile design:
        // resetting the counter to 1 (the sign image is already loaded)
        setHasLoadedImageCount((prev) => (prev === 0 ? 0 : 1));
    }, [isDesktop]);

    useInitVisuals(isFetchComplete && hasLoadedImageCount >= 2);

    useAdobePci(pciSteps.v2.parking.name);
    useFullstoryPci(fsEvents.pciParking);

    const shouldShowCta =
        appointment?.appointmentDatetime?.toDateString() === new Date().toDateString();

    const onClickContinue = () => {
        trackEvent.preCheckin.parking.click2();
        postTripEvent({
            appointmentId: appointment.appointmentId,
            storeNumber: store.id,
            ciamId: appointment.ciamId,
            hasCustomerArrived: true,
        }).catch(() => logFs(fsEvents.pciParking.tripUpdateFailed));
        goToNextStep();
    };

    // note: isDesktop is calculated in a matchMedia listener. To avoid fetching the mobile image
    // when in desktop mode - not rendering anything until isDesktop is calculated
    const epuImage = isDesktop !== undefined && (
        <div
            className={cx(
                styles.imageWrapper,
                isDesktop ? styles.imageDesktop : styles.imageMobile,
            )}
        >
            <img
                className={styles.epuSign}
                src={ImgEpuSign}
                onLoad={() => setHasLoadedImageCount((prev) => prev + 1)}
                alt="Express Pick-up Sign"
            />
            <img
                className={isDesktop ? styles.epuParkingLot : styles.epuSignProp}
                src={isDesktop ? ImgEpuParkingLot : ImgEpuSignProp}
                onLoad={() => setHasLoadedImageCount((prev) => prev + 1)}
                alt={isDesktop ? 'EPU Sign Prop' : 'EPU Parking Lot'}
            />
        </div>
    );

    return (
        isDesktop !== undefined && (
            <div className={pciCommonStyles.pciPageLayout}>
                <hzn-card
                    border-radius="medium"
                    elevation={isDesktop ? 'none' : 'medium'}
                    padding="medium"
                >
                    <hzn-stack space="large" align-x="center">
                        <hzn-heading as="h1" size="large" align="center">
                            <FormattedMessage id="pci.parking.heading" />
                        </hzn-heading>
                        {shouldShowCta && (
                            <>
                                <hzn-heading
                                    as="h3"
                                    size="xsmall"
                                    align={isDesktop ? 'center' : 'left'}
                                >
                                    <FormattedMessage id="pci.parking.info2" />
                                </hzn-heading>

                                <div onClick={onClickContinue} className={commonStyles.buttonContainer}>
                                    {/* note: null is not accidental, full-width works like in pure html attr */}
                                    <hzn-button full-width={isDesktop ? null : true}>
                                        <FormattedMessage id="pci.parking.cta" />
                                    </hzn-button>
                                </div>
                            </>
                        )}

                        <RescheduleLink
                            adobeTracker={trackEvent.preCheckin.parking.click6}
                            fullstoryPage={fsEvents.pciParking}
                        />

                        <hzn-text
                            align={isDesktop ? 'center' : 'left'}
                            weight='bold'
                        >
                            {/*| 'brand-accent' | 'inverse' | 'neutral' | 'subdued';*/}
                            <FormattedMessage id="pci.parking.info1" />
                        </hzn-text>
                        {epuImage}

                        <Questions
                            eventTrackers={{
                                faq: trackEvent.preCheckin.parking.click3,
                                phone: trackEvent.preCheckin.parking.click4,
                                docs: trackEvent.preCheckin.parking.click5,
                            }}
                        />
                    </hzn-stack>
                </hzn-card>
            </div>
        )
    );
};

export default Parking;
