import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import CheckMarkIcon from '../../assets/check-circle.png';
import styles from './UnifiedCompletePage.module.scss';
import useInitVisuals from '../../hooks/useInitVisuals';
import { storeTypes } from '../../constants/storeTypes';
import { useCheckIn, useNavigation } from '/src/context';
import classNames from 'classnames';
import EpuVehicle from '../../components/EpuVehicle';
import { useSplashRedirection } from '../../hooks/useSplashRedirection';
import { useAdobeTracking } from '../../utils/adobe/analytics';
import { PreCheckInSteps, UnifiedSteps } from '/src/flows/steps';
import { fsEvents, logFs } from '../../utils/fullstory';
import { SalesForceVisitReasons } from '/src/interfaces/SalesForceVisitReasons';

export default function UnifiedCompletePage() {
    const { formatMessage } = useIntl();

    const [checkInInfo] = useCheckIn();
    const isXf = checkInInfo.storeFormat === storeTypes.XF;
    const { isEPU, appointmentTypes } = checkInInfo;

    const { getPreviousStep } = useNavigation();
    if (getPreviousStep() !== PreCheckInSteps.PCIArrived) {
        useSplashRedirection();
    }

    useInitVisuals();
    useAdobeTracking(UnifiedSteps.Complete, { variant: isEPU ? 0 : 1 });
    useEffect(() => {
        logFs(fsEvents.unifiedCompletePage.pageView);
    }, []);

    const waitOrBrowseMessageReasons = [SalesForceVisitReasons.Browse, SalesForceVisitReasons.TestDrive, SalesForceVisitReasons.Financing];

    const isOnlyGettingOffer = appointmentTypes.length === 1  && !!(appointmentTypes.find(appointmentType => appointmentType === SalesForceVisitReasons.Appraisal));
    const showWaitOrBrowseMessage = !!waitOrBrowseMessageReasons.filter(visitReason => appointmentTypes.find(appointmentType => appointmentType === visitReason))?.length;

    const nonEpuMessageId = showWaitOrBrowseMessage ?
        'unified.completePage.instructionsWaitOrBrowse' :
        isOnlyGettingOffer ?
            'unified.completePage.instructionsWait' :
            'unified.completePage.instructionsWait';

    return (
        <div className={styles.container}>
            <div>
                <div>
                    <img src={CheckMarkIcon} className={styles.checkmarkIcon} id="checkmark-icon" />
                </div>
                <div id="unified-complete-page-title" className={styles.title}>
                    <h4 className="kmx-typography--display-5">
                        {formatMessage({ id: 'unified.completePage.header' })}
                    </h4>
                </div>
            </div>
            <div id="unified-complete-page-subtitle">
                <div
                    className={classNames('kmx-typography--body-2', {
                        [styles.instructionsEpu]: isEPU,
                    })}
                >
                    {isEPU ? (
                        isXf ? (
                            <p className={styles.instructionsEpuXf}>
                                {formatMessage({ id: 'unified.completePage.instructionsEpuXf' })}
                            </p>
                        ) : (
                            <ul>
                                <li>
                                    {formatMessage({
                                        id: 'unified.completePage.instructionsEpuTrad1',
                                    })}
                                </li>
                                <li>
                                    {formatMessage({
                                        id: 'unified.completePage.instructionsEpuTrad2',
                                    })}
                                </li>
                            </ul>
                        )
                    ) : (
                        formatMessage({ id: nonEpuMessageId })
                    )}
                </div>
            </div>
            {isEPU && <EpuVehicle {...checkInInfo} />}
        </div>
    );
}
