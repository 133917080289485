import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { pciSteps, usePciSteps } from "/src/flows/PreCheckIn/pciSteps";
import InstantOffer from './InstantOffer';
import Unified from '../flows/Unified';
import { featureFlagNames } from '../constants/featureFlags';
import flows from '../flows/flows';
import { isKioskUX } from '../utils/kioskMode';
import unifiedSteps from '../flows/steps/unifiedSteps';
import IOSteps from '../flows/steps/IOSteps';
import PCIInfoPage from '/src/flows/PreCheckIn/v1/PCIInfoPage';
import PCIArrivedPage from '/src/flows/PreCheckIn/v1/PCIArrivedPage';
import { useFeatures, useNavigation } from '../context';
import { useErrorHandling } from '/src/context/errorHandling';
import {
    getOfferCodeFromUrl,
    INVALID_OFFER_CODE,
    getValidatedStoreIdFromUrl,
} from '../utils/urlHelpers';
import PciRouting from "/src/flows/PreCheckIn/PciRouting";

export default function Routes() {
    const { setFlowAndStep } = useNavigation();
    const { setError } = useErrorHandling();
    const [features] = useFeatures();
    const [hasProcessedFeatures, setHasProcessedFeatures] = useState(false);
    const { currentStep: currentPciStep } = usePciSteps('all');

    // establish navigation flow (mostly) based on features
    useEffect(() => {
        setHasProcessedFeatures(true);
        if (!!currentPciStep) {
            return; // let ReactRouter deal with the PCI flow
        }
        const offerCode = getOfferCodeFromUrl();
        if (offerCode && offerCode !== INVALID_OFFER_CODE) {
            setFlowAndStep(flows.InstantOffer, IOSteps.CheckInPage);
            return;
        }
        if (isKioskUX()) {
            const storeId = getValidatedStoreIdFromUrl();
            if (!storeId || isNaN(storeId)) {
                setError.missingStoreId();
            } else {
                setFlowAndStep(flows.Unified, unifiedSteps.UnifiedSplashPage);
            }
            return;
        }
        if (Object.keys(features).length === 0) {
            // features not fetched: if there was a failure in fetching features,
            // we only want that fetch-error displayed. Without this safeguard, there'll be a
            // transitory state where "checkInDisabled" error can also be reported below
            return;
        }
        if (features[featureFlagNames.IsUnifiedCheckInEnabled]) {
            setFlowAndStep(flows.Unified, unifiedSteps.UnifiedLandingPage);
            return;
        }
        if (features[featureFlagNames.IsSelfCheckInEnabled]) {
            setFlowAndStep(flows.InstantOffer, IOSteps.LandingPage);
            return;
        }
        setError.checkInDisabled('Both self- and unified-check-in are disabled');
    }, [features]);

    return (
        hasProcessedFeatures &&(
            <Switch>
                <Route path="/pre" component={PciRouting} />
                <Route path={pciSteps.v1.info.path}>
                    <PCIInfoPage />
                </Route>
                <Route path={pciSteps.v1.arrived.path}>
                    <PCIArrivedPage />
                </Route>
                <Route path={flows.Unified}>
                    <Unified />
                </Route>
                <Route path={flows.InstantOffer}>
                    <InstantOffer />
                </Route>
            </Switch>
        )
    );
}
