import React, { useState, createContext, ReactChildren, useContext, Provider } from 'react';
import { trackStep } from '/src/utils/adobe/analytics';
import { logFs, fsEvents } from '/src/utils/fullstory';
import { noop } from '../constants/general';
import { useSpinner } from '/src/context/index';
import { ErrorTypes, ErrorPages } from '/src/interfaces/errors';
import { RequestCancelledError } from '/src/interfaces/errors';

interface ErrorMetaData {
    // should show the redirection button
    canRedirectToCheckIn?: boolean;
    // add any kind of additional info
    additionalInfo?: Object;
    error?: Error;
}

// this pattern avoids double imports (no need to import the enum)
type ErrorSetter = Record<ErrorTypes, (message?: string, metaData?: ErrorMetaData) => void>;

interface ErrorHandling {
    error?: ErrorTypes;
    setError: ErrorSetter;
}

// exported for testing purposes; generally, use the hook - useErrorHandling
export const ErrorHandlingContext = createContext<ErrorHandling>({
    setError: noop,
});

export const errorSideEffects = (
    errorType: ErrorTypes,
    message: string = '',
    meta?: ErrorMetaData,
) => {
    const errorInfo = {
        message,
        ...(meta?.error ? { error: `${meta.error.name}: ${meta.error.message}` } : {}),
        ...(meta?.additionalInfo ? { info: meta.additionalInfo } : {}),
    };
    (window.location.hostname === 'localhost' ? console.trace : console.error)(
        `Error (${errorType})`,
        errorInfo,
    );

    logFs(fsEvents.errorPage.errorPageLoaded, {
        error_type: errorType,
        ...errorInfo,
    });
    // adobe
    trackStep(
        (errorType === ErrorTypes.checkInDisabled && ErrorPages.checkInDisabled) ||
        (errorType === ErrorTypes.duplicateSubmission && ErrorPages.duplicateSubmission) ||
        ErrorPages.general,
    );
};

export const ErrorHandlingProvider = (({ children }: { children: ReactChildren }) => {
    const [error, setErrorState] = useState<ErrorTypes | undefined>();
    const [, showSpinner] = useSpinner();

    const setError = new Proxy({} as ErrorSetter, {
        get: function(target, errorType: ErrorTypes) {
            return (message?: string, meta?: ErrorMetaData) => {
                // update: it should now be very unlikely to get the following error, because we're retrying failed fetches
                // TODO: check fullstory around 2023-01 and, if the message below is not found, remove this code
                if (meta?.error instanceof RequestCancelledError) {
                    logFs(fsEvents.info.requestCancelled, { message });
                    // no refreshing while testing (it breaks current suite)
                    if (!window.Cypress) {
                        // Chances 99.9% that user hit refresh/stop: refresh instead of showing error.
                        // In the unlikely case that the error is triggered by a sudden server break,
                        // it's acceptable to refresh into "site can't be reached"
                        document.location.reload();
                    }
                } else {
                    setErrorState(errorType);
                    showSpinner(false);
                    errorSideEffects(errorType, message, meta);
                }
            };
        }
    });

    return (
        <ErrorHandlingContext.Provider
            value={{
                error,
                setError,
            }}
            children={children}
        />
    );
}) as unknown as Provider<ErrorHandling>;

export const useErrorHandling = () => {
    const contextValue = useContext(ErrorHandlingContext);
    return contextValue;
};
