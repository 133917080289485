import React, {useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import styles from './VehicleTradeIn.module.scss';
import { RadioButton } from '@kmx/legos-react-radio-button';
import { OfferContext } from '../../context';
import { logFsEvent, buildEventName } from '../../utils/fullstory';
import { fullstoryConstants } from '../../constants/fullstory';

export default function VehicleTradeIn(props) {
    const { label, dataSource, setIsVehicleTradeIn, isVehicleTradeIn, setIsValid } = props;
    const { setOffer } = useContext(OfferContext);

    useEffect(() => {
        setOffer(prevState => ({...prevState, isVehicleTradeIn: isVehicleTradeIn}));

        if (isVehicleTradeIn !== '' && isVehicleTradeIn !== null && isVehicleTradeIn?.length !== 0) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [isVehicleTradeIn]);

    const onFocus = () => {
        logFsEvent(buildEventName(fullstoryConstants.vehiclePage.name,
            fullstoryConstants.vehiclePage.events.tradeInChoice));
    };

    return (
        <fieldset className={"kmx-flex-wrapper"} test-id="vehicle-trade-in-options">
            <legend className={styles.label}>{label}</legend>
            {dataSource.map((option, index) =>
                <RadioButton
                    key={`vehicle-tradein-${index}`}
                    id={`vehicle-tradein-${index}`}
                    checked={isVehicleTradeIn === option?.value}
                    label={option?.label}
                    name='vehicle-tradein'
                    onChange={event => setIsVehicleTradeIn(event.target.value)}
                    onFocus={() => onFocus()}
                    value={option?.value}/>
            )}
        </fieldset>
    );
}

VehicleTradeIn.propTypes = {
    label: PropTypes.string.isRequired,
    dataSource: PropTypes.array.isRequired,
    setIsVehicleTradeIn: PropTypes.func.isRequired,
    isVehicleTradeIn: PropTypes.string.isRequired,
    setIsValid: PropTypes.func.isRequired
};
