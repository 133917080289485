import React, { Provider } from 'react';
import { Dispatch, createContext } from 'react';
import { CheckIn } from '../interfaces/CheckIn';
import { noop } from '../constants/general';
import createStatefulContext from './createStatefulContext';
import { NavigationContext, NavigationProvider, useNavigation } from './NavigationContext';
import { ErrorHandlingProvider, useErrorHandling } from './errorHandling';
import { ComposeStatefulProviders } from './compose';
import { StoreData } from '/src/interfaces/StoreData';
import { OfferInfo } from '/src/interfaces/OfferInfo';

export { NavigationContext, NavigationProvider, useNavigation };
export { ErrorHandlingProvider, useErrorHandling };

// TODO: refactor OfferContext to manage its state internally
export const OfferContext = createContext({
    offer: undefined as OfferInfo,
    setOffer: noop as Dispatch<any>,
    offers: [],
    setOffers: noop as Dispatch<any[]>,
});

const [CheckInProvider, useCheckIn, CheckInContext] = createStatefulContext<CheckIn>({} as CheckIn, 'CheckIn');
export { CheckInProvider, useCheckIn, CheckInContext };

const [StoreProvider, useStore, StoreContext] = createStatefulContext<StoreData>(null as StoreData, 'Store');
export { StoreProvider, useStore, StoreContext };

const [SpinnerProvider, useSpinner] = createStatefulContext<boolean>(true, 'Spinner');
export { SpinnerProvider, useSpinner };

const [FeatureProvider, useFeatures, FeatureContext] = createStatefulContext<any>({}, 'Feature');
export { FeatureProvider, useFeatures, FeatureContext };

export const GlobalStateProvider = ({ children }) => (
    <ComposeStatefulProviders
        providers={[
            // important: ErrorHandling must be after Spinner, because it uses it
            SpinnerProvider,
            ErrorHandlingProvider,
            NavigationProvider as unknown as Provider<any>,
            CheckInProvider,
            StoreProvider,
            FeatureProvider,
        ]}
        children={children}
    />
);
