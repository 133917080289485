import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './IOCheckInPageDataInputSwitcher.module.scss';
import { logFsEvent, buildEventName } from '../../utils/fullstory';
import { fullstoryConstants } from '../../constants/fullstory';
import { Tab, TabBar } from '@kmx/acquisitions-tab-bar';
import { checkInOfferRequestSource } from '../../constants/offer';
import trackEvent from '../../utils/adobe/trackEvent';

export default function IOCheckInPageDataInputSwitcher(props) {
    const [activeTab, setActiveTab] = useState(checkInOfferRequestSource.OFFER_CODE);
    const { source, setSource, title, option1Label, option2Label, hasNoActiveOffers } = props;

    useEffect(() => {
        if (source === undefined || source?.length <= 0) {
            setActiveTab(checkInOfferRequestSource.OFFER_CODE);
            setSource(checkInOfferRequestSource.OFFER_CODE);
        } else {
            setActiveTab(source);
            setSource(source);
        }
    }, []);

    const handleTabStateInputChange = (state) => {
        setActiveTab(state);
        setSource(state);

        if (state === checkInOfferRequestSource.OFFER_CODE) {
            logFsEvent(
                buildEventName(
                    fullstoryConstants.checkInPage.name,
                    fullstoryConstants.checkInPage.events.offerCodeToggle,
                ),
            );
            trackEvent.instantOffer.checkIn.click2(hasNoActiveOffers);
        }
        if (state === checkInOfferRequestSource.LICENSE_PLATE) {
            logFsEvent(
                buildEventName(
                    fullstoryConstants.checkInPage.name,
                    fullstoryConstants.checkInPage.events.licensePlateToggle,
                ),
            );
            trackEvent.instantOffer.checkIn.click3(hasNoActiveOffers);
        }
    };

    return (
        <div className={styles.dataInputSwitcherContainer}>
            <div className="kmx-typography--body-2">
                <h4>{title}</h4>
            </div>
            <div>
                <TabBar>
                    <Tab
                        id={checkInOfferRequestSource.OFFER_CODE}
                        onClick={() =>
                            handleTabStateInputChange(checkInOfferRequestSource.OFFER_CODE)
                        }
                        active={activeTab === checkInOfferRequestSource.OFFER_CODE}
                    >
                        {option1Label}
                    </Tab>
                    <Tab
                        id={checkInOfferRequestSource.LICENSE_PLATE}
                        onClick={() =>
                            handleTabStateInputChange(checkInOfferRequestSource.LICENSE_PLATE)
                        }
                        active={activeTab === checkInOfferRequestSource.LICENSE_PLATE}
                    >
                        {option2Label}
                    </Tab>
                </TabBar>
            </div>
        </div>
    );
}

IOCheckInPageDataInputSwitcher.propTypes = {
    source: PropTypes.string.isRequired,
    setSource: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    hasNoActiveOffers: PropTypes.bool,
    option1Label: PropTypes.string.isRequired,
    option2Label: PropTypes.string.isRequired,
};
