import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { logFsEvent, buildEventName } from '../../utils/fullstory';
import { fullstoryConstants } from '../../constants/fullstory';
import { TextField } from '@kmx/legos-react-text-field';
import { checkInOfferRequestSource } from '../../constants/offer';
import {
    validateRequiredString,
    validateSpecialCharacters,
    validateForSpaces,
    useValidation
} from '../../utils/validators';
import {
    LICENSE_PLATE_TEXT_INPUT_FIELD_MIN_LENGTH,
    LICENSE_PLATE_TEXT_INPUT_FIELD_MAX_LENGTH,
} from '../../constants/inputValidationConstants';
import { formatOfferCode } from '../../utils';

export default function CheckInTextInput(props) {
    const {
        offerCode, setOfferCode,
        licensePlate, setLicensePlate,
        source,
        specialCharactersValidationErrorMessage,
        spacesFoundInTextInputValidationErrorMessage,
        textInputHasSpecialCharacters,
        textInputHasSpaces,
        setTextInputHasSpecialCharacters,
        setTextInputHasSpaces
    } = props;
    const { validateRequired, validateOfferCode } = useValidation();

    const [textInput, setTextInput] = useState('');
    const [textInputWasTouched, setTextInputWasTouched] = useState(false);

    const onFocus = () => {
        if (source === checkInOfferRequestSource.OFFER_CODE) {
            logFsEvent(buildEventName(fullstoryConstants.checkInPage.name,
                fullstoryConstants.checkInPage.events.offerCodeTextbox));
        } else if (source === checkInOfferRequestSource.LICENSE_PLATE) {
            logFsEvent(buildEventName(fullstoryConstants.checkInPage.name,
                fullstoryConstants.checkInPage.events.licensePlateTextbox));
        }
    };

    useEffect(() => {
        if (source === checkInOfferRequestSource.OFFER_CODE) {
            if (offerCode !== undefined && offerCode.length > 0) {
                setTextInput(formatOfferCode(offerCode));
                setTextInputWasTouched(true);
                getValidationError();
            } else {
                setTextInput('');
                setTextInputWasTouched(false);
            }
        } else if (source === checkInOfferRequestSource.LICENSE_PLATE) {
            if (licensePlate !== undefined && licensePlate.length > 0) {
                setTextInput(licensePlate);
                setTextInputWasTouched(true);
                getValidationError();
            } else {
                setTextInput('');
                setTextInputWasTouched(false);
            }
        }
    }, [offerCode, licensePlate]);

    const getValidationError = () => {
        if (props?.source === checkInOfferRequestSource.OFFER_CODE) {
            if (textInputHasSpecialCharacters) {
                return validateSpecialCharacters(offerCode, specialCharactersValidationErrorMessage);
            } else {
                return validateRequired(offerCode) || validateOfferCode(offerCode);
            }
        } else {
            if (textInputHasSpaces) {
                return validateForSpaces(licensePlate, spacesFoundInTextInputValidationErrorMessage);
            } else {
                return validateRequiredString(licensePlate,
                    'License plate',
                    LICENSE_PLATE_TEXT_INPUT_FIELD_MIN_LENGTH,
                    LICENSE_PLATE_TEXT_INPUT_FIELD_MAX_LENGTH,
                    props?.requiredValidationErrorMessage);
            }
        }
    };

    const handleInputChange = (e) => {

        if (props?.source === checkInOfferRequestSource.OFFER_CODE) {
            setTextInputHasSpecialCharacters(false);
            setTextInput(formatOfferCode(e.target.value));
            setOfferCode(formatOfferCode(e.target.value));
        } else if (props?.source === checkInOfferRequestSource.LICENSE_PLATE) {
            if (e.target.value !== '' && e.target.value !== null && validateForSpaces(e.target.value, spacesFoundInTextInputValidationErrorMessage) !== null) {
                setTextInputHasSpaces(true);
            } else {
                setTextInputHasSpaces(false);
            }

            setTextInput(e.target.value);
            setLicensePlate(e.target.value);
        }
    };

    return (
        <TextField onChange={handleInputChange}
            label={props.label}
            value={textInput}
            onBlur={() => setTextInputWasTouched(true)}
            onFocus={() => onFocus()}
            validationStatus={!textInputWasTouched ? null : getValidationError() ? "invalid" : "valid"}
            helperText={getValidationError()}
            maxLength={props?.textInputFieldMaxLength}/>
    );
}

CheckInTextInput.propTypes = {
    label: PropTypes.string.isRequired,
    requiredValidationErrorMessage: PropTypes.string.isRequired,
    specialCharactersValidationErrorMessage: PropTypes.string,
    spacesFoundInTextInputValidationErrorMessage: PropTypes.string,
    textInputHasSpecialCharacters: PropTypes.bool.isRequired,
    textInputHasSpaces: PropTypes.bool.isRequired,
    setTextInputHasSpecialCharacters:PropTypes.func.isRequired,
    setTextInputHasSpaces: PropTypes.func.isRequired,
    textInputFieldMaxLength: PropTypes.number.isRequired,
    source: PropTypes.string.isRequired,
    setSource: PropTypes.func.isRequired,
    offerCode: PropTypes.string,
    setOfferCode: PropTypes.func,
    licensePlate: PropTypes.string,
    setLicensePlate: PropTypes.func
};
