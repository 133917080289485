export enum UnifiedSteps {
    Splash = 'Unified Splash Page',
    DoYouHaveAppointment = 'Unified Do-you-have-appointment Page',
    Landing = 'Unified Landing Page',
    InvalidPhoneNumber = 'Unified Invalid Phone Number', // same as Landing, used only for adobe
    GetOffer = 'Unified Get Offer Page',
    Appointment = 'Unified Appointment Page',
    CustomerInfo = 'Unified Customer Info Page',
    Complete = 'Unified Complete Page',
}

/** @deprecated Use pciSteps object */
export enum PreCheckInSteps {
    PCIInfo = 'PCI Info',
    PCIArrived = 'PCI Arrived',
}
