// Scroll to the top of an element, smoothly if supported by the browser.
// "target" can be either an element object or a string query selector.
// Usage:
//    scrollToElement('#AppointmentForm');
//    scrollToElement(document.getElementById('AppointmentForm'));
//    scrollToElement(document.querySelector('#AppointmentForm'));
//    scrollToElement(document.querySelector('input.invalid'));
export const scrollToElement = (target) => {
    if (target != null) {
        let element;

        if (typeof target === 'string') {
            // get target element by string query selector
            element = document.querySelector(target);
        }
        else if (typeof target.getBoundingClientRect === 'function') {
            element = target;
        }

        // Scroll to the element
        if (element != null) {
            const scrollPos = element.getBoundingClientRect().y + window.scrollY;
            if ('scrollBehavior' in document.documentElement.style) {
                // Smooth scrolling if supported
                window.scrollTo({ left: 0, top: scrollPos, behavior: 'smooth' });
            }
            else {
                // Non-smooth scrolling, for Edge/IE/Safari
                element.scrollIntoView();
            }
        }
    }
};
