import React, { useContext } from 'react';
import styles from './IOCompletePage.module.scss';
import { useIntl } from 'react-intl';
import CompletedPageAccordion from '../../components/CompletedPageAccordion/CompletedPageAccordion';
import CompletedPageHeader from '../../components/CompletedPageHeader/CompletedPageHeader';
import CheckMarkIcon from '../../assets/check-circle.png';
import CarmaxVehicle from '../../assets/CarmaxVehicle.png';
import { OfferContext } from '/src/context';
import { storeTypes } from '../../constants/storeTypes';
import { useSplashRedirection } from '../../hooks/useSplashRedirection';
import useInitVisuals from '../../hooks/useInitVisuals';
import { isKioskUX } from '../../utils/kioskMode';

export default function IOCompletePage() {
    const { formatMessage } = useIntl();
    const { offer } = useContext(OfferContext);
    const isKiosk = isKioskUX();
    const isXF = offer?.storeType === storeTypes.XF;

    useInitVisuals();
    useSplashRedirection();

    const subtitlesList = isKiosk
        ? isXF
            ? [
                  // xf, kiosk
                  formatMessage({ id: 'completePage.kiosk.subtitle.XF' }),
              ]
            : [
                  // traditional, kiosk
                  formatMessage({ id: 'completePage.kiosk.subtitle1' }),
                  formatMessage({ id: 'completePage.kiosk.subtitle2' }),
              ]
        : isXF
        ? [
              // xf, mobile
              formatMessage({ id: 'completePage.subtitle1.XF' }),
              formatMessage({ id: 'completePage.subtitle2.XF' }),
          ]
        : [
              // traditional, mobile
              formatMessage({ id: 'completePage.subtitle1' }),
              formatMessage({ id: 'completePage.subtitle2' }),
              formatMessage({ id: 'completePage.subtitle3' }),
          ];

    return (
        <div className={styles.container}>
            <CompletedPageHeader
                checkmarkIcon={CheckMarkIcon}
                title={formatMessage({ id: 'completePage.header' })}
                subtitles={subtitlesList}
                carImage={CarmaxVehicle}
            />
            <CompletedPageAccordion title={formatMessage({ id: 'completePage.faqTitle' })} />
        </div>
    );
}
