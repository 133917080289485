import React from 'react';
import PropTypes from 'prop-types';
import styles from './StoreHoursCardContainer.module.scss';
import StoreHoursAddress from './StoreHoursAddress';
import StoreHoursInfo from './StoreHoursInfo';
import StoreHoursMessage from './StoreHoursMessage';

export default function StoreHoursCardContainer(props) {
    const { storeHours, message, icon, storeHoursLabel, openTodayLabel } = props;

    return (
        storeHours &&
        <div className={styles.storeHoursCardContainer}>
            <div style={{border: 'none', width: '41%', padding: '4%'}}>
                <StoreHoursAddress storeHours={storeHours} />
            </div>
            <div style={{border: 'none', width: '40%', padding:'4%', borderLeft: '1px solid lightgray'}}>
                <StoreHoursInfo
                    storeHours={storeHours}
                    storeHoursLabel={storeHoursLabel}
                    openTodayLabel={openTodayLabel} />
            </div>
            {icon && message &&
            <div>
                <hr/>
                <StoreHoursMessage
                    icon={icon}
                    message={message} />
            </div>}
        </div>
    );
}

StoreHoursCardContainer.propTypes = {
    storeHours: PropTypes.object,
    storeHoursLabel: PropTypes.string.isRequired,
    openTodayLabel: PropTypes.string.isRequired,
    message: PropTypes.string,
    icon: PropTypes.any
};
