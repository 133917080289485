import React from 'react';
import { FormattedMessage } from 'react-intl';
import { fsEvents } from '/src/utils/fullstory';
import { useMediaQuery } from '/src/utils/mediaQueries/useMediaQueries';
import useAdobePci from '/src/flows/PreCheckIn/v2/common/hooks/useAdobePci';
import trackEvent from '/src/utils/adobe/trackEvent';
import Questions from '/src/flows/PreCheckIn/v2/common/Questions';
import styles from './ItemsToBringPage.module.scss';
import pciCommonStyles from '../common/pciCommon.module.scss';
import PersonalItemsAlert from '/src/flows/PreCheckIn/v2/common/PersonalItemsAlert/PersonalItemsAlert';
import { pciSteps, usePciSteps } from '/src/flows/PreCheckIn/pciSteps';
import RescheduleLink from '/src/flows/PreCheckIn/v2/common/Reschedule/Reschedule';
import useFullstoryPci from '/src/flows/PreCheckIn/v2/common/hooks/useFullstoryPci';
import DocumentList from '/src/flows/PreCheckIn/v2/ItemsToBring/DocumentList';
import useAppointmentAndStore from "/src/api/useAppointmentAndStore";
import useInitVisuals from '/src/hooks/useInitVisuals';

export default () => {
    const { goToNextStep } = usePciSteps();
    const { isDesktop } = useMediaQuery();
    const { appointment, hasFetchedAppointment } = useAppointmentAndStore();
    useInitVisuals(hasFetchedAppointment);
    useAdobePci(pciSteps.v2.items.name);
    useFullstoryPci(fsEvents.pciItemsToBring);

    const onClickContinue = () => {
        trackEvent.preCheckin.itemsToBring.click2();
        goToNextStep();
    };

    return hasFetchedAppointment && (
        <div className={pciCommonStyles.pciPageLayout}>
            <hzn-card
                class={styles.main}
                border-radius="medium"
                elevation={isDesktop ? 'none' : 'flat'}
                padding="medium"
            >
                <hzn-stack space="large" align-x="center">
                    <hzn-heading as="h1" align="center" size="large" class={styles.heading}>
                        <FormattedMessage id="pci.items.heading" />
                    </hzn-heading>

                    <DocumentList />

                    {appointment?.preCheckInData?.isTradeIn !== false && <PersonalItemsAlert />}

                    <div onClick={onClickContinue} className={styles.ctaContainer}>
                        <hzn-button full-width={isDesktop ? null : true}>
                            <FormattedMessage id="pci.items.continueCta" />
                        </hzn-button>
                    </div>

                    <RescheduleLink
                        adobeTracker={trackEvent.preCheckin.itemsToBring.click6}
                        fullstoryPage={fsEvents.pciItemsToBring}
                    />

                    <Questions
                        eventTrackers={{
                            faq: trackEvent.preCheckin.itemsToBring.click3,
                            phone: trackEvent.preCheckin.itemsToBring.click4,
                            docs: trackEvent.preCheckin.itemsToBring.click5,
                        }}
                    />
                </hzn-stack>
            </hzn-card>
        </div>
    );
};
