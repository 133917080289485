import React from 'react';
import cx from 'classnames';
import styles from './GeneralWarning.module.scss';
import IconError from '@kmx/legos-icons-material-system/assets/svg/error.svg';

export default function GeneralWarning({ title, description }: { title: string; description: string }) {
    return (
        <div className={styles.container}>
            <IconError />
            <div>
                <div className="kmx-typography--display-1">{title}</div>
                <div className={cx('kmx-typography--body-1', 'kmx-blue-900')}>{description}</div>
            </div>
        </div>
    );
}
