import React from 'react';
import * as FullStory from '@fullstory/browser';
import { fsAppName, fsEventNames as fsEvents, fsOrgId } from '../constants/fullstory';
import { StoreType } from '/src/interfaces/StoreData';

export { fsEvents }; // for convenience: we can then use only one import file

/** @deprecated Use fsEvents + logFs */
const buildEventName = (pageName, eventName) => {
    return `${fsAppName} - ${pageName} - ${eventName}`;
};

// track only in production, to avoid unnecessary spam
const shouldTrack = () =>
    [
        'localhost', // local
        'azurewebsites.net', // dev
        'qa.carmax.com', // qa
    ].every((envSignature) => !window.location.hostname.includes(envSignature));

/** @deprecated Use fsEvents + logFs  */
const logFsEvent = (name: string, params?: object) => {
    try {
        const fsParams =
            fsDetails || params ? { ...mapFsDetailsToParams(fsDetails), ...params } : undefined;
        if (shouldTrack()) {
            FullStory.event(name, fsParams);
        } else {
            // eslint-disable-next-line no-console
            console.debug.apply(null, ['FullStory event:', name, ...(fsParams ? [fsParams] : [])]);
        }
    } catch (error) {
        // debug problems in tracking (fullstory does record console.error)
        console.error('Fullstory tracking failed', error);
    }
};

const logFs = (eventName: string, params?: object, noAppPrefix = false) => {
    const name = noAppPrefix ? eventName : `${fsAppName} - ${eventName}`;
    logFsEvent(name, params);
};

// Does NOT record when in devMode. Param `devMode` is used in tests
const initFsClient = (devMode = false) => {
    FullStory.init({
        orgId: fsOrgId,
        devMode: devMode || !shouldTrack(),
    });
};

// manage details logged into FS
interface FullstoryDetails {
    acquisitionChannel: 'SMS' | 'Kiosk' | 'Sign';
    storeNumber: string;
    storeType: StoreType;
    appointment?: 'Yes' | 'No';
    appointmentType?: 'IO' | 'EPU';
    visitReason?: string; // Browse, Test Drive, Financing, Get Offer, Redeem Offer, EPU
}

let fsDetails: FullstoryDetails;

// this is the actual format sent to fullstory (FullstoryDetails is only used throughout the app)
interface FullstoryParams extends Omit<FullstoryDetails, 'storeType'> {
    storeType: 'XF' | 'Traditional';
}
const mapFsDetailsToParams = (details: FullstoryDetails): FullstoryParams => details && ({
    ...details,
    storeType: details.storeType === StoreType.XF ? 'XF' : details.storeType,
});

/**
 * @param options.overwritePreExisting Defaults to false
 */
export const setFsDetails = (
    details: FullstoryDetails,
    options: { overwritePreExisting: boolean } = { overwritePreExisting: false },
) => {
    if (options.overwritePreExisting || !fsDetails) {
        fsDetails = details;
    }
};

export const addFsDetails = (details: Partial<FullstoryDetails>) =>
    (fsDetails = { ...fsDetails, ...details });

export { buildEventName, logFsEvent, initFsClient, logFs };
