export function formatCurrencyWithCents(value) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    });

    return formatter.format(value);
}

export function formatCurrencyWithoutCents(value) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    return formatter.format(value);
}

export function formatMileage(value, roundToK = false, addMiSuffix = false) {
    const suffix = addMiSuffix ? ' mi' : '';
    if (roundToK) {
        const rounded = Math.round(value / 1000);
        return isNaN(rounded) ? undefined : `${rounded}K${suffix}`;
    } else {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'decimal'
        });
        return formatter.format(value) + suffix;
    }
}

export function formatDate(value) {
    const formatter = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });

    return formatter.format(value);
}

export function parseMilitaryTime(original) {
    const timeArray = original.split(":");
    return timeArray[0] + timeArray[1];
}

export function toStandardTime(militaryTime) {
    var hours24 = parseInt(militaryTime.substring(0, 2),10);
    var hours = ((hours24 + 11) % 12) + 1;
    var amPm = hours24 > 11 ? ' pm' : ' am';
    var minutes = militaryTime.substring(2);

    return hours + ':' + minutes + amPm;
}

export function removeNonNumericCharacters(input) {
    return input ? input.replace(/\D/g, '') : null;
}

export function formatPhoneNumber(input) {
    const inputStart = 0;
    const areaEndCentralOfficeStart = 3;
    const centralOfficeEndUserStart = 6;
    const inputEnd = 10;
    const firstParanthesis = 4;
    const secondParantheis = 7;

    if (!input) {
        return input;
    }
    // Strip all characters from the input except digits
    let formattedInput = removeNonNumericCharacters(input);
    // Trim the remaining input to ten characters, to preserve phone number format
    formattedInput = formattedInput.substring(inputStart, inputEnd);
    // Based upon the length of the string, we add formatting as necessary
    const size = formattedInput.length;
    const areaCode = formattedInput.substring(inputStart, areaEndCentralOfficeStart);
    const centralOfficeCode = formattedInput.substring(areaEndCentralOfficeStart, centralOfficeEndUserStart);
    const userCode = formattedInput.substring(centralOfficeEndUserStart, inputEnd);
    if (size < firstParanthesis) {
        formattedInput = `(${formattedInput}`;
    } else if (size < secondParantheis) {
        formattedInput = `(${areaCode}) ${centralOfficeCode}`;
    } else {
        formattedInput = `(${areaCode}) ${centralOfficeCode}-${userCode}`;
    }
    return formattedInput;
}

export function formatOfferCode(input) {
    if (!input) {
        return input;
    }

    // remove any non alphanumeric characters
    return input.replace(/[^a-zA-Z\d:-]+/g, '');
}
