import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './CompletedPageHeader.module.scss';

export default function CompletedPageHeader({ checkmarkIcon, title, subtitles, carImage }) {
    const listStyleType = subtitles.length > 1 ? 'disc' : 'none';
    return (
        <div>
            <div>
                <img src={checkmarkIcon} className={styles.checkmarkIcon} id="checkmark-icon" />
            </div>
            <div id="complete-page-title" className={styles.title}>
                <h4 className={'kmx-typography--display-5'}>{title}</h4>
            </div>
            {subtitles && (
                <ul id="complete-page-subtitles" className={classNames(styles.subtitleContainer)}>
                    {subtitles.map((item, index) => (
                        <li
                            key={`subtitle-${index}`}
                            style={{
                                listStyleType,
                            }}
                        >
                            {item}
                        </li>
                    ))}
                </ul>
            )}

            {carImage ? (
                <div>
                    <img src={carImage} className={styles.carimage} />
                </div>
            ) : null}
        </div>
    );
}

CompletedPageHeader.propTypes = {
    checkmarkIcon: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    subtitles: PropTypes.array,
    carImage: PropTypes.any,
};
