import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from  './VehicleInfo.module.scss';
import { useIntl } from 'react-intl';
import ExteriorColorSelector from '../ExteriorColorSelector/ExteriorColorSelector';
import IntentionToSell from '../IntentionToSell/IntentionToSell';
import VehicleDriveability from '../VehicleDriveability/VehicleDriveability';
import VehiclePayments from '../VehiclePayments/VehiclePayments';
import VehicleTradeIn from '../VehicleTradeIn/VehicleTradeIn';
import vehiclePaymentsOptions from '../../constants/vehiclePayments';
import vehicleTradeInOptions from '../../constants/vehicleTradeIn';
import { OfferContext } from '../../context';
import { formatMileage } from '../../utils';
import { answer } from '../../constants/yesNo';

export default function VehicleInfo(props) {
    const { formatMessage } = useIntl();
    const { offer } = useContext(OfferContext);

    const {
        vehicleInfo,
        exteriorColorOptions,
        intentionToSellOptions,
        driveability, setDriveability,
        vehicleDriveabilityOptions,
        exteriorColor, setExteriorColor,
        intentionToSell, setIntentionToSell,
        isMakingPayments, setIsMakingPayments,
        isVehicleTradeIn, setIsVehicleTradeIn,
        setIsValid,
        onCompleteChange
    } = props;

    const [exteriorColorIsValid, setExteriorColorIsValid] = useState(false);
    const [intentionToSellIsValid, setIntentionToSellIsValid] = useState(false);
    const [driveabilityIsValid, setDriveabilityIsValid] = useState(false);
    const [isMakingPaymentsValid, setIsMakingPaymentsValid] = useState(false);
    const [isVehicleTradeInValid, setIsVehicleTradeInValid] = useState(false);

    const vehiclePaymentsQuestionDivContainer = <div id='vehicle-payments-selector'>
        <VehiclePayments label={formatMessage({id: 'vehicleInfoPage.vehiclePaymentsRadioLabel'})}
            dataSource={vehiclePaymentsOptions}
            setIsMakingPayments={setIsMakingPayments}
            isMakingPayments={isMakingPayments}
            setIsValid={setIsMakingPaymentsValid} />
    </div>;

    const vehicleTradeInQuestionDivContainer = <div id='vehicle-trade-in-selector'>
        <VehicleTradeIn label={formatMessage({id: 'vehicleInfoPage.vehicleTradeInRadioLabel'})}
            dataSource={vehicleTradeInOptions}
            setIsVehicleTradeIn={setIsVehicleTradeIn}
            isVehicleTradeIn={isVehicleTradeIn}
            setIsValid={setIsVehicleTradeInValid} />
    </div>;

    useEffect(() => {
        const isValid = exteriorColorIsValid && intentionToSellIsValid && driveabilityIsValid && isMakingPaymentsValid && isVehicleTradeInValid;
        setIsValid(isValid);
        onCompleteChange(isValid);
    }, [exteriorColorIsValid, intentionToSellIsValid, driveabilityIsValid, isMakingPaymentsValid, isVehicleTradeInValid]);


    return (
        <div id="vehicleInfoBody">
            <h2 className='kmx-typography--display-3'>{formatMessage({id: 'vehicleInfoPage.vehicleStepLabel'})}</h2>
            <form>
                <div className={styles['vehicle-info']}>
                    <p className={classNames('kmx-typography--body-2', styles['vehicle-title'])}>{vehicleInfo?.year} {vehicleInfo?.make} {vehicleInfo?.model}</p>
                    <ul test-id='vehicle-info-list' className={classNames('kmx-typography--list-unstyled', styles['vehicle-list'])}>
                        {vehicleInfo?.trim && <li>{formatMessage({id: 'vehicleInfoPage.styleLabel'})}: {vehicleInfo.trim}</li>}
                        {vehicleInfo?.transmission && <li>{formatMessage({id: 'vehicleInfoPage.transmissionLabel'})}: {vehicleInfo.transmission}</li>}
                        {vehicleInfo?.drive && <li>{formatMessage({id: 'vehicleInfoPage.driveLabel'})}: {vehicleInfo.drive}</li>}
                        {vehicleInfo?.mileage && <li>{formatMessage({id: 'vehicleInfoPage.mileageLabel'})}: {formatMileage(vehicleInfo.mileage)}</li>}
                    </ul>
                </div>
                <div className={styles.questionnaireContainer}>
                    <div id='exterior-color-selector'>
                        <ExteriorColorSelector
                            label={formatMessage({id: 'vehicleInfoPage.colorSelectorLabel'})}
                            dataSource={exteriorColorOptions}
                            dropdownErrorMessage={formatMessage({id: 'vehicleInfoPage.colorSelectorErrorMessage'})}
                            exteriorColor={exteriorColor}
                            setExteriorColor={setExteriorColor}
                            setIsValid={setExteriorColorIsValid}
                        />
                    </div>
                    <div id='vehicle-driveability-selector'>
                        <VehicleDriveability
                            label={formatMessage({id: 'vehicleInfoPage.vehicleDriveabilityRadioLabel'})}
                            dataSource={vehicleDriveabilityOptions}
                            driveability={driveability}
                            setDriveability={setDriveability}
                            setIsValid={setDriveabilityIsValid} />
                    </div>
                    <div id='intention-to-sell-selector'>
                        <IntentionToSell
                            label={formatMessage({id: 'vehicleInfoPage.intentionToSellRadioLabel'})}
                            dataSource={intentionToSellOptions}
                            intention={intentionToSell}
                            setIntention={setIntentionToSell}
                            setIsValid={setIntentionToSellIsValid}
                            setIsMakingPaymentsValid={setIsMakingPaymentsValid}
                            setIsVehicleTradeInValid={setIsVehicleTradeInValid}
                        />
                    </div>
                    { offer.intentionToSell === answer.YES && vehiclePaymentsQuestionDivContainer }
                    { offer.intentionToSell === answer.YES && vehicleTradeInQuestionDivContainer }
                </div>
            </form>
        </div>
    );
}

VehicleInfo.propTypes = {
    vehicleInfo: PropTypes.object.isRequired,
    exteriorColorOptions: PropTypes.array.isRequired,
    intentionToSellOptions: PropTypes.array.isRequired,
    vehicleDriveabilityOptions: PropTypes.array.isRequired,
    exteriorColor: PropTypes.string.isRequired,
    setExteriorColor: PropTypes.func.isRequired,
    intentionToSell: PropTypes.string.isRequired,
    setIntentionToSell: PropTypes.func.isRequired,
    driveability: PropTypes.string.isRequired,
    setDriveability: PropTypes.func.isRequired,
    setIsValid: PropTypes.func.isRequired,
    isMakingPayments: PropTypes.string.isRequired,
    setIsMakingPayments: PropTypes.func.isRequired,
    isVehicleTradeIn: PropTypes.string.isRequired,
    setIsVehicleTradeIn: PropTypes.func.isRequired,
    onCompleteChange: PropTypes.any
};
