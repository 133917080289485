import React, { useEffect, useRef } from 'react';
import IconCheck from '@kmx/legos-icons-material-system/assets/svg/check.svg';
import styles from './CompletePage.module.scss';
import useInitVisuals from '/src/hooks/useInitVisuals';
import pciCommonStyles from '/src/flows/PreCheckIn/v2/common/pciCommon.module.scss';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from '/src/utils/mediaQueries/useMediaQueries';
import trackEvent from '/src/utils/adobe/trackEvent';
import Questions from '/src/flows/PreCheckIn/v2/common/Questions/Questions';
import { pciSteps } from '/src/flows/PreCheckIn/pciSteps';
import { fsEvents } from '/src/utils/fullstory';
import {
    useAdobePci,
    useFullstoryPci,
    useLocalStoragePci,
} from '/src/flows/PreCheckIn/v2/common/hooks';

const Complete = ({ isPostCheckIn }) => {
    useInitVisuals();
    const { isDesktop } = useMediaQuery();
    const textAlignment = isDesktop ? 'center' : 'left';

    useAdobePci(isPostCheckIn ? pciSteps.v2.postCheckIn.name : pciSteps.v2.complete.name);
    useFullstoryPci(isPostCheckIn ? fsEvents.pciPostCheckIn : fsEvents.pciComplete);

    const { getHasCompletedCheckIn, setHasCompletedCheckIn } = useLocalStoragePci();
    useEffect(() => {
        if (!getHasCompletedCheckIn() && !isPostCheckIn) {
            setHasCompletedCheckIn(true);
        }
    });

    return (
        <div className={pciCommonStyles.pciPageLayout}>
            <hzn-card
                border-radius="medium"
                elevation={isDesktop ? 'none' : 'medium'}
                padding="medium"
            >
                <hzn-stack space="large" align-x="center">
                    <IconCheck className={styles.iconCheck} />
                    <hzn-heading as="h1" size="large" align="center">
                        <FormattedMessage id="pci.complete.heading" />
                    </hzn-heading>
                    <hzn-heading as="h2" size="medium" align={textAlignment}>
                        <FormattedMessage id="pci.complete.info" />
                    </hzn-heading>
                    {isDesktop && <div style={{ height: 40 }} />}
                    <Questions
                        eventTrackers={{
                            faq: () => trackEvent.preCheckin.complete.click2(isPostCheckIn),
                            phone: () => trackEvent.preCheckin.complete.click3(isPostCheckIn),
                            docs: () => trackEvent.preCheckin.complete.click4(isPostCheckIn),
                        }}
                    />
                </hzn-stack>
            </hzn-card>
        </div>
    );
};

export default Complete;
