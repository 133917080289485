export enum StoreType {
    Traditional = 'Traditional',
    XF = 'CrossFunctional'
}

export enum StoreStatus {
    Open = 'Open',
    Closed = 'Closed',
    ClosingSoon = 'ClosingSoon',
}

export interface StoreHours {
    startHour: string;
    endHour: string;
}

export interface StoreData {
    addressLine1: string;
    addressLine2: string;
    city: string;
    hoursToday: StoreHours;
    id: string;
    name: string;
    phoneLocal: string;
    stateAbbreviation: string;
    status: StoreStatus;
    zipCode: string;
    storeFormat: StoreType;
}

// the following data types correspond to the api call getting data for all stores
interface StoreGenericPhone {
    "type": string;
    "number": string;
}
interface StoreGenericHours {
    "startDayOfWeek": number;
    "endDayOfWeek": number;
    "startHour": string; // eg "11:00:00",
    "endHour": string;
}
export interface StoreGeneric {
    id: string;
    name: string;
    "addressLine1":string;
    "addressLine2": string;
    "city": string;
    "stateAbbreviation": string;
    "zipCode": string;
    "phoneNumbers": StoreGenericPhone[];
    storeFormat: StoreType;
}
