import React from 'react';
import PropTypes from 'prop-types';
import {formatPhoneNumber} from '../../utils/index';

export default function StoreHoursAddress(props) {
    const { storeHours } = props;

    return (
        <div style={{textAlign: 'left'}} className={"kmx-typography--body-1"} test-id='store-hours-address'>
            <div className={"kmx-typography--display-1"} style={{fontSize: '16px', marginBottom: '10px'}}>
                <strong>{storeHours?.name}</strong>
            </div>
            <div>
                {storeHours?.addressLine1?.trim()}
            </div>
            {storeHours.addressLine2 !== undefined ? <div>
                {storeHours?.addressLine2?.trim()}
            </div> : null}
            <div>{formatPhoneNumber(storeHours?.phoneLocal?.trim())}</div>
        </div>
    );
}

StoreHoursAddress.propTypes = {
    storeHours: PropTypes.object.isRequired
};
